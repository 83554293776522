export function getCarrierCarTrailerDisplayName(
  carrierCar?: CarrierCar | null,
  carrierTrailer?: CarrierTrailer | null,
  addEndOfLineForEachMinusSign = false
): string {
  let displayValue = "-";
  if (!carrierCar && !carrierTrailer) {
    return displayValue;
  }

  if (carrierCar && !carrierTrailer) {
    displayValue = carrierCar?.vehicleRegistrationPlate?.toUpperCase() || "";

    if (addEndOfLineForEachMinusSign) {
      displayValue = displayValue.split("-").join("-\n");
    }

    return displayValue;
  }

  if (!carrierCar && carrierTrailer) {
    displayValue =
      carrierTrailer?.vehicleRegistrationPlate?.toUpperCase() || "";

    if (addEndOfLineForEachMinusSign) {
      displayValue = displayValue.split("-").join("-\n");
    }

    return displayValue;
  }

  if (carrierCar && carrierTrailer) {
    displayValue = carrierCar?.vehicleRegistrationPlate?.toUpperCase() || "";
    displayValue = `${displayValue}-${
      carrierTrailer?.vehicleRegistrationPlate?.toUpperCase() || ""
    }`;

    if (addEndOfLineForEachMinusSign) {
      displayValue = displayValue.split("-").join("-\n");
    }

    return displayValue;
  }

  return displayValue;
}
