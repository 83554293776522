import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import AddCargoTypeDialog from "./cargoType/AddCargoTypeDialog";
import { cargoTypeRequest } from "../../api/CargoTypeRequest";
import { shipperBranchRequest } from "../../api/ShipperBranchRequest";
import { tripRequest } from "../../api/TripRequest";
import Typography from "@mui/material/Typography";
import blockInvalidCharNumberInput from "../../functions/blockInvalidCharNumberInput";
import { Add } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { userRequest } from "../../api/UserRequest";
import { countryRequest } from "../../api/CountryRequest";
import { carrierRequest } from "../../api/CarrierRequest";
import { carrierCarRequest } from "../../api/CarrierCarRequest";
import { carrierTrailerRequest } from "../../api/CarrierTrailerRequest";
import { carrierDriverRequest } from "../../api/CarrierDriverRequest";
import { shipperRequest } from "../../api/ShipperRequest";
import AddCarrierDialog from "./carrier/AddCarrierDialog";
import AddCarrierCarDialog from "./carrier/AddCarrierCarDialog";
import AddCarrierTrailerDialog from "./carrier/AddCarrierTrailerDialog";
import AddCarrierDriverDialog from "./carrier/AddCarrierDriverDialog";
import AddShipperDialog from "./shipper/AddShipperDialog";
import AddShipperBranchDialog from "./shipper/AddShipperBranchDialog";
import AddLoadingCityDialog from "./country/AddLoadingCityDialog";
import AddUnloadingCityDialog from "./country/AddUnloadingCityDialog";
import { useSelector } from "react-redux";
import {
  isAdminOrManagerSelector,
  isDispatcherSelector,
  vatSelector,
} from "../../redux/slice/selectors";
import AddLoadingCountryDialog from "./country/AddLoadingCountryDialog";
import AddUnloadingCountryDialog from "./country/AddUnloadingCountryDialog";
import { useSkipFirstRun } from "../../helpers/hooks";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import TripDocumentsDialog from "./TripDocumentsDialog";
import { TripStatus } from "../dataGrids/TripsDataGrid";
import ConstructionIcon from "@mui/icons-material/Construction";
import { useNavigate } from "react-router-dom";
import PATH from "../../paths";
import { TitleContext } from "../../contexts/TitleProviderContext";
import routeTitleMapObject from "../../helpers/routeTitleMapObject";
import EditCarrierCarDialog from "./carrier/EditCarrierCarDialog";
import EditCarrierTrailerDialog from "./carrier/EditCarrierTrailerDialog";
import { getErrorForCarrierCarOrTrailer } from "../../functions/getErrorForEntity";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  trip: Trip;
  onTripConfirmed: AnyFunction;
}

interface InvoiceRow {
  id: number;
  distanceKm: number | string;
  value: number | string;
  errorValue: boolean;
  errorDistanceKm: boolean;
  touchedValue: boolean;
  touchedDistanceKm: boolean;
}

interface FormValues {
  date: Date | null;
  cargoTypeId: number | null;
  dispatcherId: number | null;
  loadingDate: Date | null;
  loadingAddress: string | null;
  loadingCityId: number | null;
  intermediaryCitiesDto: { cityId: number; address: string }[];
  unloadingDate: Date | null;
  unloadingAddress: string | null;
  unloadingCityId: number | null;
  carrierId: number | null;
  carrierCarId: number | null;
  carrierTrailerId: number | null;
  carrierDriverId: number | null;
  carrierInvoiceRows: any[];
  carrierPaymentTermInDays: number | string | null;
  shipperId: number | null;
  shipperBranchId: number | null;
  shipperReferenceId: string | null;
  shipperInvoiceRows: any[];
  shipperPaymentTermInDays: number | string | null;
  observations: string | null;
  comments: string | null;
  internalNotes: string | null;
}

interface ConfirmTripRequestData {
  id: number;
  date: Date | null;
  invoiceData: string | null;
  errorInvoiceData: boolean;
  touched: boolean;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  ".MuiAccordionSummary-root": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  ".MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root": {
    color: theme.palette.primary.contrastText,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 35,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const AddressTextField = styled(TextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    height: 14px !important;
  }
`;

const RedTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const GreenTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export default function ConfirmTripDialog(props: Props) {
  const isAdminOrManager = useSelector(isAdminOrManagerSelector);
  const isDispatcher = useSelector(isDispatcherSelector);
  const { open, onClose, trip, onTripConfirmed } = props;
  const vat = useSelector(vatSelector);
  const [formValues, setFormValues] = useState<FormValues>({
    date: null,
    cargoTypeId: null,
    dispatcherId: null,
    loadingDate: null,
    loadingAddress: "",
    loadingCityId: null,
    intermediaryCitiesDto: [],
    unloadingDate: null,
    unloadingAddress: "",
    unloadingCityId: null,
    carrierId: null,
    carrierCarId: null,
    carrierTrailerId: null,
    carrierDriverId: null,
    carrierInvoiceRows: [],
    carrierPaymentTermInDays: null,
    shipperId: null,
    shipperBranchId: null,
    shipperReferenceId: "",
    shipperInvoiceRows: [],
    shipperPaymentTermInDays: null,
    observations: "",
    comments: "",
    internalNotes: "",
  });
  const [confirmTripRequestData, setConfirmTripRequestData] =
    useState<ConfirmTripRequestData>({
      id: trip.id,
      date: new Date(),
      invoiceData: "",
      errorInvoiceData: true,
      touched: true,
    });
  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const isRomaniaDefaultValue = countryOptions?.find(
    (country: Country) => country.name?.toUpperCase() === "ROMANIA"
  );

  const generateIntermediaryCityRow = (): IntermediaryCity => {
    return {
      id: Math.random(),
      intermediaryCountry: isRomaniaDefaultValue ? isRomaniaDefaultValue : null,
      intermediaryCity: null,
      intermediaryAddress: null,
    };
  };

  const firstRun = useRef(false);

  useEffect(() => {
    if (
      !trip ||
      trip.intermediaryCities?.length ||
      (!countryOptions.length && firstRun.current)
    ) {
      return;
    }

    firstRun.current = true;

    setIntermediaryCityRowValues([generateIntermediaryCityRow()]);
  }, [countryOptions, trip]);

  const [intermediaryCityRowValues, setIntermediaryCityRowValues] = useState<
    IntermediaryCity[]
  >([generateIntermediaryCityRow()]);

  const addIntermediaryCityRow = () => {
    setIntermediaryCityRowValues((prevState) => [
      ...prevState,
      generateIntermediaryCityRow(),
    ]);
  };

  const deleteIntermediaryCityRow = (id: number) => {
    setIntermediaryCityRowValues((prevState) =>
      prevState.filter((intermediaryCityRow) => {
        return intermediaryCityRow.id !== id;
      })
    );
  };

  const editIntermediaryCityRows = (id: number, values: any) => {
    setIntermediaryCityRowValues((prevState) =>
      prevState.map((intermediaryCityRow) => {
        if (intermediaryCityRow.id !== id) {
          return intermediaryCityRow;
        }
        return {
          ...intermediaryCityRow,
          ...values,
        };
      })
    );
  };

  useEffect(() => {
    setFormValues((prev: any) => ({
      ...prev,
      intermediaryCitiesDto: intermediaryCityRowValues
        .filter(
          (intermediaryCityRow) => intermediaryCityRow.intermediaryCity?.id
        )
        .map((intermediaryCityRow) => ({
          cityId: intermediaryCityRow.intermediaryCity?.id,
          address: intermediaryCityRow.intermediaryAddress?.trim() || undefined,
        })),
    }));
  }, [intermediaryCityRowValues]);

  const generateInvoiceRow = (): InvoiceRow => {
    return {
      id: Math.random(),
      distanceKm: "",
      value: "",
      errorValue: true,
      errorDistanceKm: true,
      touchedValue: false,
      touchedDistanceKm: false,
    };
  };

  const [carrierInvoiceRowValues, setCarrierInvoiceRowValues] = useState<
    InvoiceRow[]
  >([]);

  const addCarrierInvoiceRow = () => {
    setCarrierInvoiceRowValues((prevState) => [
      ...prevState,
      generateInvoiceRow(),
    ]);
  };

  const deleteCarrierInvoiceRow = (id: number) => {
    setCarrierInvoiceRowValues((prevState) =>
      prevState.filter((invoiceRow) => {
        return invoiceRow.id !== id;
      })
    );
  };

  const editCarrierInvoiceRow = (id: number, values: any) => {
    setCarrierInvoiceRowValues((prevState) =>
      prevState.map((invoiceRow) => {
        if (invoiceRow.id !== id) {
          return invoiceRow;
        }
        return {
          ...invoiceRow,
          ...values,
          errorValue: Boolean(values.value !== 0 && !values.value),
          errorDistanceKm: Boolean(
            values.distanceKm !== 0 && !values.distanceKm
          ),
        };
      })
    );
  };

  const [shipperInvoiceRowValues, setShipperInvoiceRowValues] = useState<
    InvoiceRow[]
  >([]);

  const addShipperInvoiceRow = () => {
    setShipperInvoiceRowValues((prevState) => [
      ...prevState,
      generateInvoiceRow(),
    ]);
  };

  const deleteShipperInvoiceRow = (id: number) => {
    setShipperInvoiceRowValues((prevState) =>
      prevState.filter((invoiceRow) => {
        return invoiceRow.id !== id;
      })
    );
  };

  const editShipperInvoiceRow = (id: number, values: any) => {
    setShipperInvoiceRowValues((prevState) =>
      prevState.map((invoiceRow) => {
        if (invoiceRow.id !== id) {
          return invoiceRow;
        }
        return {
          ...invoiceRow,
          ...values,
          errorValue: Boolean(values.value !== 0 && !values.value),
          errorDistanceKm: Boolean(
            values.distanceKm !== 0 && !values.distanceKm
          ),
        };
      })
    );
  };

  const [profitNoVat, setProfitNoVat] = useState<Number>(0);
  const [shipperTotal, setShipperTotal] = useState<number>(0);
  const [carrierTotal, setCarrierTotal] = useState<number>(0);

  useEffect(() => {
    setFormValues((prev: any) => ({
      ...prev,
      carrierInvoiceRows: carrierInvoiceRowValues.map((invoiceRow) => ({
        distanceKm: invoiceRow.distanceKm,
        value: invoiceRow.value,
      })),
      shipperInvoiceRows: shipperInvoiceRowValues.map((invoiceRow) => ({
        distanceKm: invoiceRow.distanceKm,
        value: invoiceRow.value,
      })),
    }));
    let shipperInvoiceRowsTotal: number = 0;
    let carrierInvoiceRowsTotal: number = 0;
    shipperInvoiceRowValues.map((invoiceRow) => {
      shipperInvoiceRowsTotal +=
        Number(invoiceRow.value) * Number(invoiceRow.distanceKm);
    });
    carrierInvoiceRowValues.map((invoiceRow) => {
      carrierInvoiceRowsTotal +=
        Number(invoiceRow.value) * Number(invoiceRow.distanceKm);
    });
    setProfitNoVat(shipperInvoiceRowsTotal - carrierInvoiceRowsTotal);
    setShipperTotal(shipperInvoiceRowsTotal);
    setCarrierTotal(carrierInvoiceRowsTotal);
  }, [carrierInvoiceRowValues, shipperInvoiceRowValues]);

  const [cargoTypeValue, setCargoTypeValue] = useState<CargoType | null>(null);
  const [dispatcherValue, setDispatcherValue] = useState<User | null>(null);
  const [carrierValue, setCarrierValue] = useState<Carrier | null>(null);
  const [carrierCarValue, setCarrierCarValue] = useState<CarrierCar | null>(
    null
  );
  const [carrierTrailerValue, setCarrierTrailerValue] =
    useState<CarrierTrailer | null>(null);
  const [carrierDriverValue, setCarrierDriverValue] =
    useState<CarrierDriver | null>(null);
  const [shipperValue, setShipperValue] = useState<Shipper | null>(null);
  const [shipperBranchValue, setShipperBranchValue] =
    useState<ShipperBranch | null>(null);

  const [loadingCityValue, setLoadingCityValue] = useState<City | null>(null);
  const [unloadingCityValue, setUnloadingCityValue] = useState<City | null>(
    null
  );

  const [error, setError] = useState({
    date: false,
    dispatcherId: false,
    loadingDate: false,
    loadingCountryId: false,
    loadingCityId: false,
    unloadingDate: false,
    unloadingCountryId: false,
    unloadingCityId: false,
    carrierId: false,
    carrierCarId: false,
    carrierTrailerId: false,
    carrierDriverId: false,
    carrierPaymentTermInDays: false,
    shipperId: false,
    shipperPaymentTermInDays: false,
  });
  const carrierCarCmrInsuranceErrorObj =
    getErrorForCarrierCarOrTrailer(carrierCarValue);
  const carrierTrailerCmrInsuranceErrorObj =
    getErrorForCarrierCarOrTrailer(carrierTrailerValue);

  const [selectedLoadingCountryId, setSelectedLoadingCountryId] =
    useState<any>(null);
  const [selectedUnloadingCountryId, setSelectedUnloadingCountryId] =
    useState<any>(null);
  const [selectedCarrierId, setSelectedCarrierId] = useState<any>(null);
  const [selectedShipperId, setSelectedShipperId] = useState<any>(null);

  const [cargoTypeOptions, setCargoTypeOptions] = useState<CargoType[]>([]);
  const [dispatcherOptions, setDispatcherOptions] = useState<User[]>([]);
  const [loadingCountryOptions, setLoadingCountryOptions] = useState<Country[]>(
    []
  );
  const [unloadingCountryOptions, setUnloadingCountryOptions] = useState<
    Country[]
  >([]);
  const [intermediaryCityOptions, setIntermediaryCityOptions] = useState<
    City[]
  >([]);
  const [loadingCityOptions, setLoadingCityOptions] = useState<City[]>([]);
  const [unloadingCityOptions, setUnloadingCityOptions] = useState<City[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<Carrier[]>([]);
  const [carrierCarOptions, setCarrierCarOptions] = useState<CarrierCar[]>([]);
  const [carrierTrailerOptions, setCarrierTrailerOptions] = useState<
    CarrierTrailer[]
  >([]);
  const [carrierDriverOptions, setCarrierDriverOptions] = useState<
    CarrierDriver[]
  >([]);
  const [shipperOptions, setShipperOptions] = useState<Shipper[]>([]);
  const [shipperBranchOptions, setShipperBranchOptions] = useState<
    ShipperBranch[]
  >([]);

  const [toggleTripHasReceptionProblem, setToggleTripHasReceptionProblem] =
    useState(trip.hasReceptionProblem);

  const handleErrors = () => {
    if (!formValues.date) {
      setError((prev: any) => ({
        ...prev,
        date: true,
      }));
    } else if (formValues.date) {
      setError((prev: any) => ({
        ...prev,
        date: false,
      }));
    }
    if (!formValues.dispatcherId) {
      setError((prev: any) => ({
        ...prev,
        dispatcherId: true,
      }));
    } else if (formValues.dispatcherId) {
      setError((prev: any) => ({
        ...prev,
        dispatcherId: false,
      }));
    }
    if (!formValues.loadingDate) {
      setError((prev: any) => ({
        ...prev,
        loadingDate: true,
      }));
    } else if (formValues.loadingDate) {
      setError((prev: any) => ({
        ...prev,
        loadingDate: false,
      }));
    }
    if (!loadingCountryValue) {
      setError((prev: any) => ({
        ...prev,
        loadingCountryId: true,
      }));
    } else if (loadingCountryValue) {
      setError((prev: any) => ({
        ...prev,
        loadingCountryId: false,
      }));
    }
    if (!formValues.loadingCityId) {
      setError((prev: any) => ({
        ...prev,
        loadingCityId: true,
      }));
    } else if (formValues.loadingCityId) {
      setError((prev: any) => ({
        ...prev,
        loadingCityId: false,
      }));
    }
    if (!formValues.unloadingDate) {
      setError((prev: any) => ({
        ...prev,
        unloadingDate: true,
      }));
    } else if (formValues.unloadingDate) {
      setError((prev: any) => ({
        ...prev,
        unloadingDate: false,
      }));
    }
    if (!unloadingCountryValue) {
      setError((prev: any) => ({
        ...prev,
        unloadingCountryId: true,
      }));
    } else if (unloadingCountryValue) {
      setError((prev: any) => ({
        ...prev,
        unloadingCountryId: false,
      }));
    }
    if (!formValues.unloadingCityId) {
      setError((prev: any) => ({
        ...prev,
        unloadingCityId: true,
      }));
    } else if (formValues.unloadingCityId) {
      setError((prev: any) => ({
        ...prev,
        unloadingCityId: false,
      }));
    }
    if (!formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: true,
      }));
    } else if (formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: false,
      }));
    }
    if (!formValues.carrierCarId && !formValues.carrierTrailerId) {
      setError((prev: any) => ({
        ...prev,
        carrierCarId: true,
        carrierTrailerId: true,
      }));
    } else {
      setError((prev: any) => ({
        ...prev,
        carrierCarId: false,
        carrierTrailerId: false,
      }));
    }
    if (!formValues.carrierDriverId) {
      setError((prev: any) => ({
        ...prev,
        carrierDriverId: true,
      }));
    } else if (formValues.carrierDriverId) {
      setError((prev: any) => ({
        ...prev,
        carrierDriverId: false,
      }));
    }
    if (
      !formValues.carrierPaymentTermInDays &&
      formValues.carrierPaymentTermInDays !== 0
    ) {
      setError((prev: any) => ({
        ...prev,
        carrierPaymentTermInDays: true,
      }));
    } else if (formValues.carrierPaymentTermInDays) {
      setError((prev: any) => ({
        ...prev,
        carrierPaymentTermInDays: false,
      }));
    }
    if (!formValues.shipperId) {
      setError((prev: any) => ({
        ...prev,
        shipperId: true,
      }));
    } else if (formValues.shipperId) {
      setError((prev: any) => ({
        ...prev,
        shipperId: false,
      }));
    }
    if (
      !formValues.shipperPaymentTermInDays &&
      formValues.shipperPaymentTermInDays !== 0
    ) {
      setError((prev: any) => ({
        ...prev,
        shipperPaymentTermInDays: true,
      }));
    } else if (formValues.shipperPaymentTermInDays) {
      setError((prev: any) => ({
        ...prev,
        shipperPaymentTermInDays: false,
      }));
    }
    setCarrierInvoiceRowValues((prevState) =>
      prevState.map((invoiceRow) => ({
        ...invoiceRow,
        touchedValue: true,
        touchedDistanceKm: true,
      }))
    );
    setShipperInvoiceRowValues((prevState) =>
      prevState.map((invoiceRow) => ({
        ...invoiceRow,
        touchedValue: true,
        touchedDistanceKm: true,
      }))
    );
  };

  const isSafeToSubmit = (): boolean => {
    if (!(trip && trip.id)) {
      return false;
    }
    handleErrors();
    const formIsValid =
      confirmTripRequestData.date &&
      confirmTripRequestData.invoiceData &&
      formValues.date &&
      formValues.dispatcherId &&
      formValues.loadingDate &&
      formValues.loadingCityId &&
      formValues.unloadingDate &&
      formValues.unloadingCityId &&
      formValues.carrierId &&
      (formValues.carrierCarId || formValues.carrierTrailerId) &&
      formValues.carrierDriverId &&
      formValues.carrierInvoiceRows.every((invoiceRow: InvoiceRow) => {
        return (
          (invoiceRow.value || invoiceRow.value === 0) &&
          (invoiceRow.distanceKm || invoiceRow.distanceKm === 0)
        );
      }) &&
      (formValues.carrierPaymentTermInDays ||
        formValues.carrierPaymentTermInDays === 0) &&
      formValues.shipperId &&
      formValues.shipperInvoiceRows.every((invoiceRow: InvoiceRow) => {
        return (
          (invoiceRow.value || invoiceRow.value === 0) &&
          (invoiceRow.distanceKm || invoiceRow.distanceKm === 0)
        );
      }) &&
      (formValues.shipperPaymentTermInDays ||
        formValues.shipperPaymentTermInDays === 0);
    // &&
    // !(
    //   (carrierCarValue &&
    //     carrierTrailerValue &&
    //     carrierCarCmrInsuranceErrorObj.error &&
    //     carrierTrailerCmrInsuranceErrorObj.error) ||
    //   (carrierCarValue &&
    //     !carrierTrailerValue &&
    //     carrierCarCmrInsuranceErrorObj.error) ||
    //   (carrierTrailerValue &&
    //     !carrierCarValue &&
    //     carrierTrailerCmrInsuranceErrorObj.error)
    // );

    // if (
    //   (carrierCarValue &&
    //     carrierTrailerValue &&
    //     carrierCarCmrInsuranceErrorObj.error &&
    //     carrierTrailerCmrInsuranceErrorObj.error) ||
    //   (carrierCarValue &&
    //     !carrierTrailerValue &&
    //     carrierCarCmrInsuranceErrorObj.error) ||
    //   (carrierTrailerValue &&
    //     !carrierCarValue &&
    //     carrierTrailerCmrInsuranceErrorObj.error)
    // ) {
    //   alert("Mașina SAU remorca trebuie să aibă asigurare CMR valabilă!");
    // }

    return Boolean(formIsValid);
  };

  const editTrip = () => {
    const updateTripPromise = editFieldsAreDisabled
      ? tripRequest.updateMisc.bind(tripRequest, trip.id, {
          comments: formValues.comments || "",
          internalNotes: formValues.internalNotes || "",
        })
      : tripRequest.patch.bind(tripRequest, trip.id, formValues);

    return updateTripPromise();
  };

  const confirmTrip = () => {
    return tripRequest.confirmTrip(confirmTripRequestData.id, {
      date: confirmTripRequestData.date,
      invoiceData: confirmTripRequestData.invoiceData,
    });
  };

  const blockTrip = () => {
    return tripRequest.blockTrip(confirmTripRequestData.id);
  };

  const handleConfirm = () => {
    if (!isSafeToSubmit()) {
      return;
    }
    return editTrip()
      .then(confirmTrip)
      .then(onTripConfirmed)
      .catch((e) => {
        alert("S-a produs o eroare!");
        throw e;
      });
  };

  const navigate = useNavigate();
  const { setTitle } = useContext(TitleContext);

  const handleConfirmAndBill = () => {
    if (!isSafeToSubmit()) {
      return;
    }
    return editTrip()
      .then(confirmTrip)
      .then(() => {
        setTitle(routeTitleMapObject[PATH.SHIPPER_INVOICE]);
        navigate(PATH.SHIPPER_INVOICE + `?id=${trip.id}`);
      })
      .catch((e) => {
        alert("S-a produs o eroare!");
        throw e;
      });
  };

  const handleConfirmAndBlock = () => {
    if (!isSafeToSubmit()) {
      return;
    }
    return editTrip()
      .then(confirmTrip)
      .then(blockTrip)
      .then(onTripConfirmed)
      .catch((e) => {
        alert("S-a produs o eroare!");
        throw e;
      });
  };

  const editFieldsAreDisabled = trip.status === TripStatus.Billed;

  const handleCloseConfirmTripDialog = () => {
    onClose();
    setError(() => ({
      date: false,
      dispatcherId: false,
      loadingDate: false,
      loadingCountryId: false,
      loadingCityId: false,
      unloadingDate: false,
      unloadingCountryId: false,
      unloadingCityId: false,
      carrierId: false,
      carrierCarId: false,
      carrierTrailerId: false,
      carrierDriverId: false,
      carrierPaymentTermInDays: false,
      shipperId: false,
      shipperPaymentTermInDays: false,
    }));
  };

  const fetchCargoType = () => {
    cargoTypeRequest.findAll().then((data) => {
      setCargoTypeOptions(
        data.results.map((cargoType: CargoType) => ({
          id: cargoType.id,
          name: cargoType.name,
        }))
      );
    });
  };

  const fetchDispatchers = () => {
    userRequest.findAll().then((data) => {
      setDispatcherOptions(
        data.results
          .map((user: User) => ({
            id: user.id,
            username: user.username,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            birthdate: user.birthdate,
            phone: user.phone,
            role: user.role,
          }))
          .filter((user: User) => user.role?.name === "dispatcher")
      );
    });
  };

  const fetchCountries = () => {
    countryRequest
      .getCountries()
      .then((countries) => setCountryOptions(countries));
  };

  const fetchLoadingCountries = () => {
    countryRequest
      .getCountries()
      .then((countries) => setLoadingCountryOptions(countries));
  };

  const fetchUnloadingCountries = () => {
    countryRequest
      .getCountries()
      .then((countries) => setUnloadingCountryOptions(countries));
  };

  const fetchIntermediaryCities = (countryId: any) => {
    if (!countryId) return;
    countryRequest
      .searchCity(countryId, "*")
      .then((cities) => setIntermediaryCityOptions(cities));
  };

  const fetchLoadingCities = () => {
    if (selectedLoadingCountryId) {
      countryRequest
        .searchCity(selectedLoadingCountryId, "*")
        .then((cities) => setLoadingCityOptions(cities));
    }
  };

  const fetchUnloadingCities = () => {
    if (selectedUnloadingCountryId) {
      countryRequest
        .searchCity(selectedUnloadingCountryId, "*")
        .then((cities) => setUnloadingCityOptions(cities));
    }
  };

  const fetchCarriers = () => {
    carrierRequest.findAll(10, 0).then((data) => {
      setCarrierOptions(
        data.results.map((carrier: Carrier) => ({
          id: carrier.id,
          name: carrier.name,
          tin: carrier.tin,
          city: carrier.city,
          address: carrier.address,
          phone: carrier.phone,
          email: carrier.email,
          contactPerson: carrier.contactPerson,
          isActive: carrier.isActive,
        }))
      );
    });
  };

  const fetchCarrierCars = () => {
    if (selectedCarrierId) {
      carrierCarRequest.findAll(10, 0).then((data) => {
        setCarrierCarOptions(
          data.results.map((carrierCar: CarrierCar) => ({
            id: carrierCar.id,
            vehicleRegistrationPlate: carrierCar.vehicleRegistrationPlate,
            carrier: carrierCar.carrier,
            cmrInsuranceIdentifier: carrierCar.cmrInsuranceIdentifier,
            cmrInsuranceStartDate: carrierCar.cmrInsuranceStartDate,
            cmrInsuranceEndDate: carrierCar.cmrInsuranceEndDate,
            cmrInsuranceDocumentId: carrierCar.cmrInsuranceDocumentId,
            cmrInsuranceDocument: carrierCar.cmrInsuranceDocument,
            cmrInsuranceNeedsCheck: carrierCar.cmrInsuranceNeedsCheck,
          }))
        );
      });
    }
  };

  const fetchCarrierTrailers = () => {
    if (selectedCarrierId) {
      carrierTrailerRequest.findAll(10, 0).then((data) => {
        setCarrierTrailerOptions(
          data.results.map((carrierTrailer: CarrierTrailer) => ({
            id: carrierTrailer.id,
            vehicleRegistrationPlate: carrierTrailer.vehicleRegistrationPlate,
            carrier: carrierTrailer.carrier,
            cmrInsuranceIdentifier: carrierTrailer.cmrInsuranceIdentifier,
            cmrInsuranceStartDate: carrierTrailer.cmrInsuranceStartDate,
            cmrInsuranceEndDate: carrierTrailer.cmrInsuranceEndDate,
            cmrInsuranceDocumentId: carrierTrailer.cmrInsuranceDocumentId,
            cmrInsuranceDocument: carrierTrailer.cmrInsuranceDocument,
            cmrInsuranceNeedsCheck: carrierTrailer.cmrInsuranceNeedsCheck,
          }))
        );
      });
    }
  };

  const fetchCarrierDrivers = () => {
    if (selectedCarrierId) {
      carrierDriverRequest.findAll().then((data) => {
        setCarrierDriverOptions(
          data.results
            .map((carrierDriver: CarrierDriver) => ({
              id: carrierDriver.id,
              name: carrierDriver.name,
              phone: carrierDriver.phone,
              carrier: carrierDriver.carrier,
            }))
            .filter(
              (carrierDriver: CarrierDriver) =>
                carrierDriver.carrier?.id === selectedCarrierId
            )
        );
      });
    }
  };

  const fetchShippers = () => {
    shipperRequest.findAll(10, 0).then((data) => {
      setShipperOptions(
        data.results.map((shipper: Shipper) => ({
          id: shipper.id,
          name: shipper.name,
          tin: shipper.tin,
          businessRegistrationNumber: shipper.businessRegistrationNumber,
          bank: shipper.bank,
          iban: shipper.iban,
          city: shipper.city,
          address: shipper.address,
          phone: shipper.phone,
          email: shipper.email,
          contactPerson: shipper.contactPerson,
          isActive: shipper.isActive,
          paymentTermInDays: shipper.paymentTermInDays,
        }))
      );
    });
  };

  const fetchShipperBranches = () => {
    if (selectedShipperId) {
      shipperBranchRequest.findAll().then((data) => {
        setShipperBranchOptions(
          data.results
            .map((shipperBranch: ShipperBranch) => ({
              id: shipperBranch.id,
              name: shipperBranch.name,
              shipper: shipperBranch.shipper,
              city: shipperBranch.city,
              address: shipperBranch.address,
              phone: shipperBranch.phone,
              email: shipperBranch.email,
            }))
            .filter(
              (shipperBranch: ShipperBranch) =>
                shipperBranch.shipper?.id === selectedShipperId
            )
        );
      });
    }
  };

  const [onCountryCreatedIndex, updateOnCountryCreatedIndex] = useReducer(
    (x) => {
      return x + 1;
    },
    0
  );

  useEffect(() => {
    fetchCargoType();
  }, []);

  useEffect(() => {
    fetchDispatchers();
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [onCountryCreatedIndex]);

  useEffect(() => {
    fetchLoadingCountries();
  }, [onCountryCreatedIndex]);

  useEffect(() => {
    fetchLoadingCities();
  }, [selectedLoadingCountryId]);

  useEffect(() => {
    fetchUnloadingCountries();
  }, [onCountryCreatedIndex]);

  useEffect(() => {
    fetchUnloadingCities();
  }, [selectedUnloadingCountryId]);

  useEffect(() => {
    fetchCarriers();
  }, []);

  useEffect(() => {
    fetchCarrierCars();
  }, [selectedCarrierId]);

  useEffect(() => {
    fetchCarrierTrailers();
  }, [selectedCarrierId]);

  useEffect(() => {
    fetchCarrierDrivers();
  }, [selectedCarrierId]);

  useEffect(() => {
    fetchShippers();
  }, []);

  useEffect(() => {
    fetchShipperBranches();
  }, [selectedShipperId]);

  const [onCarrierCreatedIndex, updateOnCarrierCreatedIndex] = useReducer(
    (x) => {
      return x + 1;
    },
    0
  );

  const [onShipperCreatedIndex, updateOnShipperCreatedIndex] = useReducer(
    (x) => {
      return x + 1;
    },
    0
  );

  const sortedCargoTypeOptions = cargoTypeOptions.sort(function (a, b) {
    return b.id - a.id;
  });

  const sortedDispatchersOptions = dispatcherOptions.sort(function (a, b) {
    if (a.lastName! < b.lastName!) {
      return -1;
    }
    if (a.lastName! > b.lastName!) {
      return 1;
    }
    return 0;
  });

  const [loadingCountryValue, setLoadingCountryValue] =
    useState<Country | null>(null);

  const [unloadingCountryValue, setUnloadingCountryValue] =
    useState<Country | null>(null);

  const sortedCountryOptions = countryOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedLoadingCountryOptions = loadingCountryOptions?.sort(function (
    a,
    b
  ) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedUnloadingCountryOptions = unloadingCountryOptions?.sort(function (
    a,
    b
  ) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierOptions = carrierOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierCarOptions = carrierCarOptions.sort(function (a, b) {
    if (a.vehicleRegistrationPlate! < b.vehicleRegistrationPlate!) {
      return -1;
    }
    if (a.vehicleRegistrationPlate! > b.vehicleRegistrationPlate!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierTrailerOptions = carrierTrailerOptions.sort(function (
    a,
    b
  ) {
    if (a.vehicleRegistrationPlate! < b.vehicleRegistrationPlate!) {
      return -1;
    }
    if (a.vehicleRegistrationPlate! > b.vehicleRegistrationPlate!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierDriverOptions = carrierDriverOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedShipperOptions = shipperOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedShipperBranchOptions = shipperBranchOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (trip) {
      setFormValues({
        date: trip.date,
        cargoTypeId: trip.cargoType?.id || null,
        dispatcherId: trip.dispatcher?.id || null,
        loadingDate: trip.loadingDate,
        loadingAddress: trip.loadingAddress,
        loadingCityId: trip.loadingCity?.id || null,
        intermediaryCitiesDto:
          trip.intermediaryCities?.map((intermediaryCity) => ({
            cityId: intermediaryCity.id,
            address: intermediaryCity.address,
          })) || [],
        unloadingDate: trip.unloadingDate,
        unloadingAddress: trip.unloadingAddress,
        unloadingCityId: trip.unloadingCity?.id || null,
        carrierId: trip.carrier?.id || null,
        carrierCarId: trip.carrierCar?.id || null,
        carrierTrailerId: trip.carrierTrailer?.id || null,
        carrierDriverId: trip.carrierDriver?.id || null,
        carrierInvoiceRows: [],
        carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
        shipperId: trip.shipper?.id || null,
        shipperBranchId: trip.shipperBranch?.id || null,
        shipperReferenceId: trip.shipperReferenceId,
        shipperInvoiceRows: [],
        shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
        observations: trip.observations,
        comments: trip.comments,
        internalNotes: trip.internalNotes,
      });
      setIntermediaryCityRowValues(
        trip.intermediaryCities?.length
          ? trip.intermediaryCities?.map((intermediaryCityRow) => {
              return {
                id: intermediaryCityRow.id,
                intermediaryCountry: intermediaryCityRow.county.country,
                intermediaryCity: {
                  id: intermediaryCityRow.id,
                  name: intermediaryCityRow.name,
                  county: intermediaryCityRow.county,
                },
                intermediaryAddress: intermediaryCityRow.address,
              };
            })
          : [generateIntermediaryCityRow()]
      );
      setExpandedAccordionIntermediaryCityRows(
        Boolean(trip.intermediaryCities?.length)
      );
      setCarrierInvoiceRowValues(
        trip.carrierInvoiceRows
          ?.map((invoiceRow: InvoiceRow) => ({
            id: invoiceRow.id || Math.random(),
            distanceKm: invoiceRow.distanceKm || 0,
            value: invoiceRow.value || 0,
            errorValue: false,
            errorDistanceKm: false,
            touchedValue: true,
            touchedDistanceKm: true,
          }))
          .sort(function (a, b) {
            return a.id - b.id;
          }) || []
      );
      setShipperInvoiceRowValues(
        trip.shipperInvoiceRows
          ?.map((invoiceRow: InvoiceRow) => ({
            id: invoiceRow.id || Math.random(),
            distanceKm: invoiceRow.distanceKm || 0,
            value: invoiceRow.value || 0,
            errorValue: false,
            errorDistanceKm: false,
            touchedValue: true,
            touchedDistanceKm: true,
          }))
          .sort(function (a, b) {
            return a.id - b.id;
          }) || []
      );
      setCargoTypeValue(trip.cargoType || null);
      setDispatcherValue(trip.dispatcher || null);
      setCarrierValue(trip.carrier || null);
      setCarrierCarValue(trip.carrierCar || null);
      setCarrierTrailerValue(trip.carrierTrailer || null);
      setCarrierDriverValue(trip.carrierDriver || null);
      setShipperValue(trip.shipper || null);
      setShipperBranchValue(trip.shipperBranch || null);
      setLoadingCountryValue(trip.loadingCity?.county?.country || null);
      setLoadingCityValue(trip.loadingCity || null);
      setUnloadingCountryValue(trip.unloadingCity?.county?.country || null);
      setUnloadingCityValue(trip.unloadingCity || null);
      setSelectedLoadingCountryId(trip.loadingCity?.county?.country?.id);
      setSelectedUnloadingCountryId(trip.unloadingCity?.county?.country?.id);
      setSelectedCarrierId(trip.carrier?.id);
      setSelectedShipperId(trip.shipper?.id);
    }
  }, [trip]);

  const [openAddCargoTypeDialog, setOpenAddCargoTypeDialog] =
    React.useState(false);
  const handleOpenAddCargoTypeDialog = () => {
    setOpenAddCargoTypeDialog(true);
  };

  const [openAddLoadingCountryDialog, setOpenAddLoadingCountryDialog] =
    React.useState(false);
  const handleOpenAddLoadingCountryDialog = () => {
    setOpenAddLoadingCountryDialog(true);
  };

  const [openAddUnloadingCountryDialog, setOpenAddUnloadingCountryDialog] =
    React.useState(false);
  const handleOpenAddUnloadingCountryDialog = () => {
    setOpenAddUnloadingCountryDialog(true);
  };

  const [openAddLoadingCityDialog, setOpenAddLoadingCityDialog] =
    React.useState(false);
  const handleOpenAddLoadingCityDialog = () => {
    setOpenAddLoadingCityDialog(true);
  };

  const [openAddUnloadingCityDialog, setOpenAddUnloadingCityDialog] =
    React.useState(false);
  const handleOpenAddUnloadingCityDialog = () => {
    setOpenAddUnloadingCityDialog(true);
  };

  const [openAddShipperDialog, setOpenAddShipperDialog] = React.useState(false);

  const handleOpenAddShipperDialog = () => {
    setOpenAddShipperDialog(true);
  };

  const [openAddShipperBranchDialog, setOpenAddShipperBranchDialog] =
    React.useState(false);

  const handleOpenAddShipperBranchDialog = () => {
    setOpenAddShipperBranchDialog(true);
  };

  const [openAddCarrierDialog, setOpenAddCarrierDialog] = React.useState(false);

  const handleOpenAddCarrierDialog = () => {
    setOpenAddCarrierDialog(true);
  };

  const [openAddCarrierCarDialog, setOpenAddCarrierCarDialog] =
    React.useState(false);
  const [openAddCarrierTrailerDialog, setOpenAddCarrierTrailerDialog] =
    React.useState(false);

  const [openEditCarrierCarDialog, setOpenEditCarrierCarDialog] =
    React.useState(false);
  const [openEditCarrierTrailerDialog, setOpenEditCarrierTrailerDialog] =
    React.useState(false);

  const handleOpenAddCarrierCarDialog = () => {
    setOpenAddCarrierCarDialog(true);
  };

  const handleOpenAddCarrierTrailerDialog = () => {
    setOpenAddCarrierTrailerDialog(true);
  };

  const handleOpenEditCarrierCarDialog = () => {
    setOpenEditCarrierCarDialog(true);
  };

  const handleOpenEditCarrierTrailerDialog = () => {
    setOpenEditCarrierTrailerDialog(true);
  };

  const [openAddCarrierDriverDialog, setOpenAddCarrierDriverDialog] =
    React.useState(false);

  const handleOpenAddCarrierDriverDialog = () => {
    setOpenAddCarrierDriverDialog(true);
  };

  const [expandedAccordionTripDetails, setExpandedAccordionTripDetails] =
    useState(true);
  const handleExpandAccordionTripDetails = () => {
    if (expandedAccordionTripDetails) {
      setExpandedAccordionTripDetails(false);
    } else if (!expandedAccordionTripDetails) {
      setExpandedAccordionTripDetails(true);
    }
  };

  const [
    expandedAccordionLoadingUnloadingDetails,
    setExpandedAccordionLoadingUnloadingDetails,
  ] = useState(true);
  const handleExpandAccordionLoadingUnloadingDetails = () => {
    if (expandedAccordionLoadingUnloadingDetails) {
      setExpandedAccordionLoadingUnloadingDetails(false);
    } else if (!expandedAccordionLoadingUnloadingDetails) {
      setExpandedAccordionLoadingUnloadingDetails(true);
    }
  };

  const [
    expandedAccordionIntermediaryCityRows,
    setExpandedAccordionIntermediaryCityRows,
  ] = useState(false);
  const handleExpandAccordionIntermediaryCityRows = () => {
    if (expandedAccordionIntermediaryCityRows) {
      setExpandedAccordionIntermediaryCityRows(false);
    } else if (!expandedAccordionIntermediaryCityRows) {
      setExpandedAccordionIntermediaryCityRows(true);
    }
  };

  const [
    expandedAccordionShipperCarrierDetails,
    setExpandedAccordionShipperCarrierDetails,
  ] = useState(true);
  const handleExpandAccordionShipperCarrierDetails = () => {
    if (expandedAccordionShipperCarrierDetails) {
      setExpandedAccordionShipperCarrierDetails(false);
    } else if (!expandedAccordionShipperCarrierDetails) {
      setExpandedAccordionShipperCarrierDetails(true);
    }
  };

  const [expandedAccordionInvoiceRows, setExpandedAccordionInvoiceRows] =
    useState(true);
  const handleExpandAccordionInvoiceRows = () => {
    if (expandedAccordionInvoiceRows) {
      setExpandedAccordionInvoiceRows(false);
    } else if (!expandedAccordionInvoiceRows) {
      setExpandedAccordionInvoiceRows(true);
    }
  };

  const [
    expandedAccordionTripAdditionalDetails,
    setExpandedAccordionTripAdditionalDetails,
  ] = useState(true);
  const handleExpandAccordionTripAdditionalDetails = () => {
    if (expandedAccordionTripAdditionalDetails) {
      setExpandedAccordionTripAdditionalDetails(false);
    } else if (!expandedAccordionTripAdditionalDetails) {
      setExpandedAccordionTripAdditionalDetails(true);
    }
  };

  const [openTripDocumentsDialog, setOpenTripDocumentsDialog] =
    useState<boolean>(false);

  const [searchCarrierTextInput, setSearchCarrierTextInput] = useState("");
  const [searchCarrierCarTextInput, setSearchCarrierCarTextInput] =
    useState("");
  const [searchCarrierTrailerTextInput, setSearchCarrierTrailerTextInput] =
    useState("");
  const [searchShipperTextInput, setSearchShipperTextInput] = useState("");
  const [searchLoadingCityTextInput, setSearchLoadingCityTextInput] =
    useState("");
  const [searchUnloadingCityTextInput, setSearchUnloadingCityTextInput] =
    useState("");
  const [searchIntermediaryCityTextInput, setSearchIntermediaryCityTextInput] =
    useState("");

  useSkipFirstRun(() => {
    if (!searchCarrierTextInput) {
      fetchCarriers();
      return;
    }

    const timeoutId = setTimeout(() => {
      carrierRequest
        .searchByName(searchCarrierTextInput)
        .then((results) => setCarrierOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchCarrierTextInput]);

  useSkipFirstRun(() => {
    if (!searchCarrierCarTextInput) {
      fetchCarrierCars();
      return;
    }

    const timeoutId = setTimeout(() => {
      carrierCarRequest
        .searchByName(searchCarrierCarTextInput)
        .then((results) => setCarrierCarOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchCarrierCarTextInput]);

  useSkipFirstRun(() => {
    if (!searchCarrierTrailerTextInput) {
      fetchCarrierTrailers();
      return;
    }

    const timeoutId = setTimeout(() => {
      carrierTrailerRequest
        .searchByName(searchCarrierTrailerTextInput)
        .then((results) => setCarrierTrailerOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchCarrierTrailerTextInput]);

  useSkipFirstRun(() => {
    if (!searchShipperTextInput) {
      fetchShippers();
      return;
    }

    const timeoutId = setTimeout(() => {
      shipperRequest
        .searchByName(searchShipperTextInput)
        .then((results) => setShipperOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchShipperTextInput]);

  useSkipFirstRun(() => {
    if (!searchLoadingCityTextInput) {
      fetchLoadingCities();
      return;
    }

    const timeoutId = setTimeout(() => {
      countryRequest
        .searchCity(selectedLoadingCountryId, searchLoadingCityTextInput)
        .then((results) => setLoadingCityOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchLoadingCityTextInput]);

  useSkipFirstRun(() => {
    if (!searchUnloadingCityTextInput) {
      fetchUnloadingCities();
      return;
    }

    const timeoutId = setTimeout(() => {
      countryRequest
        .searchCity(selectedUnloadingCountryId, searchUnloadingCityTextInput)
        .then((results) => setUnloadingCityOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchUnloadingCityTextInput]);

  const [selectedIntermediaryCountryId, setSelectedIntermediaryCountryId] =
    useState<any>(null);

  useSkipFirstRun(() => {
    if (!searchIntermediaryCityTextInput) {
      fetchIntermediaryCities(selectedIntermediaryCountryId);
      return;
    }

    const timeoutId = setTimeout(() => {
      countryRequest
        .searchCity(
          selectedIntermediaryCountryId,
          searchIntermediaryCityTextInput
        )
        .then((results) => setIntermediaryCityOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchIntermediaryCityTextInput, selectedIntermediaryCountryId]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            maxHeight: "100%",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 0.5, pt: 0.5 }}
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0, pt: 0 }}>{`Operare comandă #${trip?.id} ${
              trip?.shipperInvoice?.invoiceSeries?.name ? "-" : ""
            } ${trip?.shipperInvoice?.invoiceSeries?.name || ""}${
              trip?.shipperInvoice?.number || ""
            }${
              trip?.hasPublicUploadedFiles
                ? " - Cursa are documente încărcate de șofer"
                : ""
            }`}</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 0 }}>
            <Button
              sx={{ mr: 2 }}
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                tripRequest
                  .toggleReceptionProblemTrip(trip.id)
                  .then(() =>
                    setToggleTripHasReceptionProblem((prev) => !prev)
                  );
              }}
            >
              <ConstructionIcon fontSize="small" sx={{ mr: 1 }} />
              {toggleTripHasReceptionProblem
                ? "Set Probleme recepție OFF"
                : "Set Probleme recepție ON"}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="warning"
              onClick={() => {
                setOpenTripDocumentsDialog(true);
              }}
            >
              <InsertDriveFile fontSize="small" sx={{ mr: 1 }} />
              Documente
            </Button>
            <IconButton onClick={handleCloseConfirmTripDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent sx={{ pb: 1, pt: 0 }}>
          <Grid item>
            <DialogContentText>Factură</DialogContentText>
            <Divider sx={{ mb: 2 }} />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Dată"
                    value={confirmTripRequestData?.date || new Date()}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setConfirmTripRequestData((prev: any) => ({
                        ...prev,
                        date: newValue,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "zz/ll/aaaa",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  label="Număr factură"
                  error={
                    confirmTripRequestData.errorInvoiceData &&
                    confirmTripRequestData.touched
                  }
                  helperText={
                    confirmTripRequestData.errorInvoiceData &&
                    confirmTripRequestData.touched &&
                    "Câmp obligatoriu!"
                  }
                  onChange={(event) => {
                    setConfirmTripRequestData((prev: any) => ({
                      ...prev,
                      invoiceData: event.target.value.toUpperCase(),
                    }));
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Accordion
                expanded={expandedAccordionTripDetails}
                onChange={handleExpandAccordionTripDetails}
              >
                <AccordionSummary tabIndex={-1}>
                  <Typography>Detalii</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Dată"
                          value={
                            formValues?.date ? new Date(formValues?.date) : null
                          }
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                            setFormValues((prev: any) => ({
                              ...prev,
                              date: newValue,
                            }));
                          }}
                          disabled={editFieldsAreDisabled}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              size="small"
                              fullWidth
                              error={error.date}
                              helperText={error.date && "Câmp obligatoriu!"}
                              sx={{
                                svg: {
                                  width: "17px",
                                  height: "17px",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  lineHeight: "17px",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  height: 32,
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                },
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "zz/ll/aaaa",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Box sx={{ display: "flex" }}>
                        <Autocomplete
                          disabled={editFieldsAreDisabled}
                          value={cargoTypeValue ?? null}
                          onChange={(event, newValue) => {
                            setCargoTypeValue(newValue);
                            setFormValues((prev: any) => ({
                              ...prev,
                              cargoTypeId: newValue?.id,
                            }));
                          }}
                          options={sortedCargoTypeOptions}
                          filterSelectedOptions
                          getOptionLabel={(option) => `${option.name}`}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          noOptionsText="Fără opțiuni"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              size="small"
                              label="Tip marfă"
                              InputLabelProps={{
                                style: {
                                  lineHeight: "17px",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  height: 32,
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                },
                              }}
                            />
                          )}
                          sx={{ flexGrow: 1, mr: 1 }}
                        />
                        <Button
                          disabled={editFieldsAreDisabled}
                          color="primary"
                          variant="contained"
                          sx={{
                            maxWidth: "32px",
                            minWidth: "32px",
                            minHeight: "32px",
                            maxHeight: "32px",
                          }}
                          onClick={handleOpenAddCargoTypeDialog}
                          tabIndex={-1}
                        >
                          <AddIcon />
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Autocomplete
                        disabled={editFieldsAreDisabled || isDispatcher}
                        value={dispatcherValue ?? null}
                        onChange={(event, newValue) => {
                          setDispatcherValue(newValue);
                          setFormValues((prev: any) => ({
                            ...prev,
                            dispatcherId: newValue?.id,
                          }));
                        }}
                        options={sortedDispatchersOptions}
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          `${option.lastName} ${option.firstName}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        noOptionsText="Fără opțiuni"
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            fullWidth
                            size="small"
                            label="Dispecer"
                            error={error.dispatcherId}
                            helperText={
                              error.dispatcherId && "Câmp obligatoriu!"
                            }
                            InputLabelProps={{
                              style: {
                                lineHeight: "17px",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                height: 32,
                                lineHeight: "17px",
                                paddingBottom: 0,
                                paddingTop: 0,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedAccordionLoadingUnloadingDetails}
                onChange={handleExpandAccordionLoadingUnloadingDetails}
              >
                <AccordionSummary tabIndex={-1}>
                  <Typography>Încărcare/Descărcare</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      lg={6}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Încărcare</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disabled={editFieldsAreDisabled}
                            label="Dată"
                            value={
                              formValues?.loadingDate
                                ? new Date(formValues?.loadingDate)
                                : null
                            }
                            inputFormat="dd/MM/yyyy"
                            onChange={(newValue) => {
                              setFormValues((prev: any) => ({
                                ...prev,
                                loadingDate: newValue,
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                size="small"
                                fullWidth
                                error={error.loadingDate}
                                helperText={
                                  error.loadingDate && "Câmp obligatoriu!"
                                }
                                sx={{
                                  svg: {
                                    width: "17px",
                                    height: "17px",
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    height: 32,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  },
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "zz/ll/aaaa",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <AddressTextField
                          disabled={editFieldsAreDisabled}
                          size="small"
                          fullWidth
                          label="Adresă încărcare"
                          value={formValues?.loadingAddress ?? ""}
                          onChange={(event) =>
                            setFormValues((prev: any) => ({
                              ...prev,
                              loadingAddress: event.target.value.toUpperCase(),
                            }))
                          }
                          inputProps={{
                            style: { textTransform: "uppercase" },
                            autoComplete: "street-address",
                          }}
                          InputLabelProps={{
                            style: {
                              lineHeight: "17px",
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 32,
                            },
                          }}
                        ></AddressTextField>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            disabled={editFieldsAreDisabled}
                            value={loadingCountryValue}
                            onChange={(event, newValue, reason) => {
                              setLoadingCityValue(null);
                              if (reason === "clear") {
                                setLoadingCountryValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  loadingCityId: null,
                                }));
                                setSelectedLoadingCountryId(null);
                              } else {
                                setLoadingCountryValue(newValue);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  loadingCityId: null,
                                }));
                                setSelectedLoadingCountryId(newValue?.id);
                              }
                            }}
                            options={sortedLoadingCountryOptions}
                            filterSelectedOptions
                            getOptionLabel={(option) => `${option.name}`}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={(params) => (
                              <form
                                noValidate
                                onSubmit={(event) => event.preventDefault()}
                              >
                                <TextField
                                  required
                                  {...params}
                                  size="small"
                                  fullWidth
                                  label="Țară"
                                  error={error.loadingCountryId}
                                  helperText={
                                    error.loadingCountryId &&
                                    "Câmp obligatoriu!"
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: {
                                      height: 32,
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                    },
                                  }}
                                />
                              </form>
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            disabled={editFieldsAreDisabled}
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            onClick={handleOpenAddLoadingCountryDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            value={loadingCityValue}
                            onChange={(event, newValue, reason) => {
                              if (reason === "clear") {
                                setLoadingCityValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  loadingCityId: null,
                                }));
                                fetchLoadingCities();
                              } else {
                                setLoadingCityValue(newValue);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  loadingCityId: newValue?.id,
                                }));
                              }
                            }}
                            options={loadingCityOptions}
                            filterSelectedOptions
                            disabled={
                              !selectedLoadingCountryId || editFieldsAreDisabled
                            }
                            getOptionLabel={(option) =>
                              `${option.name?.trim()}, ${
                                option.county?.shortName === "n/a"
                                  ? option.county?.name
                                  : option.county?.shortName
                              }`
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={({
                              inputProps: { onChange, ...restInputProps },
                              ...params
                            }) => (
                              <form
                                noValidate
                                onSubmit={(event) => event.preventDefault()}
                              >
                                <TextField
                                  required
                                  {...params}
                                  inputProps={{
                                    ...restInputProps,
                                    onChange: (...args) => {
                                      setSearchLoadingCityTextInput(
                                        // @ts-ignore
                                        args[0].target.value
                                      );
                                      // @ts-ignore
                                      onChange(...args);
                                    },
                                  }}
                                  size="small"
                                  fullWidth
                                  label="Localitate încărcare"
                                  error={error.loadingCityId}
                                  helperText={
                                    error.loadingCityId && "Câmp obligatoriu!"
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: {
                                      height: 32,
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                    },
                                  }}
                                />
                              </form>
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            disabled={
                              !selectedLoadingCountryId || editFieldsAreDisabled
                            }
                            onClick={handleOpenAddLoadingCityDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      lg={6}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            Descărcare
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disabled={editFieldsAreDisabled}
                            label="Dată"
                            value={
                              formValues?.unloadingDate
                                ? new Date(formValues?.unloadingDate)
                                : null
                            }
                            inputFormat="dd/MM/yyyy"
                            onChange={(newValue) => {
                              setFormValues((prev: any) => ({
                                ...prev,
                                unloadingDate: newValue,
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                size="small"
                                fullWidth
                                error={error.unloadingDate}
                                helperText={
                                  error.unloadingDate && "Câmp obligatoriu!"
                                }
                                sx={{
                                  svg: {
                                    width: "17px",
                                    height: "17px",
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    height: 32,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  },
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "zz/ll/aaaa",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <AddressTextField
                          disabled={editFieldsAreDisabled}
                          size="small"
                          fullWidth
                          label="Adresă descărcare"
                          value={formValues?.unloadingAddress ?? ""}
                          onChange={(event) =>
                            setFormValues((prev: any) => ({
                              ...prev,
                              unloadingAddress:
                                event.target.value.toUpperCase(),
                            }))
                          }
                          inputProps={{
                            style: { textTransform: "uppercase" },
                            autoComplete: "street-address",
                          }}
                          InputLabelProps={{
                            style: {
                              lineHeight: "17px",
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 32,
                            },
                          }}
                        ></AddressTextField>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            disabled={editFieldsAreDisabled}
                            value={unloadingCountryValue}
                            onChange={(event, newValue, reason) => {
                              setUnloadingCityValue(null);
                              if (reason === "clear") {
                                setUnloadingCountryValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  unloadingCityId: null,
                                }));
                                setSelectedUnloadingCountryId(null);
                              } else {
                                setUnloadingCountryValue(newValue);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  unloadingCityId: null,
                                }));
                                setSelectedUnloadingCountryId(newValue?.id);
                              }
                            }}
                            options={sortedUnloadingCountryOptions}
                            filterSelectedOptions
                            getOptionLabel={(option) => `${option.name}`}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={(params) => (
                              <form
                                noValidate
                                onSubmit={(event) => event.preventDefault()}
                              >
                                <TextField
                                  required
                                  {...params}
                                  size="small"
                                  fullWidth
                                  label="Țară"
                                  error={error.unloadingCountryId}
                                  helperText={
                                    error.unloadingCountryId &&
                                    "Câmp obligatoriu!"
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: {
                                      height: 32,
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                    },
                                  }}
                                />
                              </form>
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            disabled={editFieldsAreDisabled}
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            onClick={handleOpenAddUnloadingCountryDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            value={unloadingCityValue}
                            onChange={(event, newValue, reason) => {
                              if (reason === "clear") {
                                setUnloadingCityValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  unloadingCityId: null,
                                }));
                                fetchUnloadingCities();
                              } else {
                                setUnloadingCityValue(newValue);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  unloadingCityId: newValue?.id,
                                }));
                              }
                            }}
                            options={unloadingCityOptions}
                            filterSelectedOptions
                            disabled={
                              !selectedUnloadingCountryId ||
                              editFieldsAreDisabled
                            }
                            getOptionLabel={(option) =>
                              `${option.name?.trim()}, ${
                                option.county?.shortName === "n/a"
                                  ? option.county?.name
                                  : option.county?.shortName
                              }`
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={({
                              inputProps: { onChange, ...restInputProps },
                              ...params
                            }) => (
                              <form
                                noValidate
                                onSubmit={(event) => event.preventDefault()}
                              >
                                <TextField
                                  required
                                  {...params}
                                  inputProps={{
                                    ...restInputProps,
                                    onChange: (...args) => {
                                      setSearchUnloadingCityTextInput(
                                        // @ts-ignore
                                        args[0].target.value
                                      );
                                      // @ts-ignore
                                      onChange(...args);
                                    },
                                  }}
                                  size="small"
                                  fullWidth
                                  label="Localitate descărcare"
                                  error={error.unloadingCityId}
                                  helperText={
                                    error.unloadingCityId && "Câmp obligatoriu!"
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: {
                                      height: 32,
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                    },
                                  }}
                                />
                              </form>
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            disabled={
                              !selectedUnloadingCountryId ||
                              editFieldsAreDisabled
                            }
                            onClick={handleOpenAddUnloadingCityDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedAccordionIntermediaryCityRows}
                onChange={handleExpandAccordionIntermediaryCityRows}
              >
                <AccordionSummary tabIndex={-1}>
                  <Typography>Puncte intermediare</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    {intermediaryCityRowValues?.map(
                      (intermediaryCityRow, index) => {
                        return (
                          <Grid item xs={12} key={intermediaryCityRow.id}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                fontSize="medium"
                                sx={{
                                  flexGrow: 1,
                                  mr: 1,
                                  mt: 1,
                                }}
                              >
                                {`${index + 1}.`}
                              </Typography>
                              <Autocomplete
                                disabled={editFieldsAreDisabled}
                                value={intermediaryCityRow.intermediaryCountry}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    editIntermediaryCityRows(
                                      intermediaryCityRow.id,
                                      {
                                        intermediaryCountry: null,
                                        intermediaryCity: null,
                                      }
                                    );
                                  } else {
                                    editIntermediaryCityRows(
                                      intermediaryCityRow.id,
                                      {
                                        intermediaryCountry: newValue,
                                        intermediaryCity: null,
                                      }
                                    );
                                  }
                                }}
                                options={sortedCountryOptions}
                                filterSelectedOptions
                                getOptionLabel={(option) => `${option.name}`}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                fullWidth
                                noOptionsText="Fără opțiuni"
                                renderInput={(params) => (
                                  <form
                                    noValidate
                                    onSubmit={(event) => event.preventDefault()}
                                  >
                                    <TextField
                                      {...params}
                                      size="small"
                                      label="Țară"
                                      InputLabelProps={{
                                        style: {
                                          lineHeight: "17px",
                                        },
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: {
                                          height: 32,
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        },
                                      }}
                                    />
                                  </form>
                                )}
                                sx={{ flexGrow: 1, mr: 1 }}
                              />
                              <Autocomplete
                                value={intermediaryCityRow.intermediaryCity}
                                onChange={(event, newValue) => {
                                  editIntermediaryCityRows(
                                    intermediaryCityRow.id,
                                    {
                                      intermediaryCity: newValue,
                                    }
                                  );
                                }}
                                onOpen={() => {
                                  fetchIntermediaryCities(
                                    intermediaryCityRowValues[index]
                                      .intermediaryCountry?.id
                                  );
                                }}
                                options={intermediaryCityOptions}
                                filterSelectedOptions
                                disabled={
                                  !intermediaryCityRowValues[index]
                                    .intermediaryCountry ||
                                  editFieldsAreDisabled
                                }
                                getOptionLabel={(option) =>
                                  `${option.name?.trim()}, ${
                                    option.county?.shortName === "n/a"
                                      ? option.county?.name
                                      : option.county?.shortName
                                  }`
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                fullWidth
                                noOptionsText="Fără opțiuni"
                                renderOption={(props, option) => (
                                  <li {...props} key={option.id}>
                                    {`${option.name?.trim()}, ${
                                      option.county?.shortName === "n/a"
                                        ? option.county?.name
                                        : option.county?.shortName
                                    }`}
                                  </li>
                                )}
                                renderInput={({
                                  inputProps: { onChange, ...restInputProps },
                                  ...params
                                }) => (
                                  <form
                                    noValidate
                                    onSubmit={(event) => event.preventDefault()}
                                  >
                                    <TextField
                                      {...params}
                                      inputProps={{
                                        ...restInputProps,
                                        onChange: (...args) => {
                                          setSelectedIntermediaryCountryId(
                                            intermediaryCityRowValues[index]
                                              .intermediaryCountry?.id
                                          );
                                          setSearchIntermediaryCityTextInput(
                                            // @ts-ignore
                                            args[0].target.value
                                          );
                                          // @ts-ignore
                                          onChange(...args);
                                        },
                                      }}
                                      size="small"
                                      label="Localitate"
                                      InputLabelProps={{
                                        style: {
                                          lineHeight: "17px",
                                        },
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: {
                                          height: 32,
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        },
                                      }}
                                    />
                                  </form>
                                )}
                                sx={{ flexGrow: 1, mr: 1 }}
                              />
                              <TextField
                                value={intermediaryCityRow.intermediaryAddress}
                                size="small"
                                fullWidth
                                name="intermediary-city-address"
                                label="Adresă"
                                disabled={editFieldsAreDisabled}
                                onChange={(event) => {
                                  editIntermediaryCityRows(
                                    intermediaryCityRow.id,
                                    {
                                      intermediaryAddress:
                                        event.target.value.toUpperCase(),
                                    }
                                  );
                                }}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                  autoComplete: "on",
                                }}
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                InputProps={{
                                  style: {
                                    height: 32,
                                  },
                                }}
                                sx={{ flexGrow: 1, mr: 1 }}
                              ></TextField>
                              {index === 0 ? (
                                <Button
                                  disabled={editFieldsAreDisabled}
                                  color="primary"
                                  variant="contained"
                                  fullWidth
                                  onClick={addIntermediaryCityRow}
                                  tabIndex={-1}
                                  sx={{
                                    maxWidth: "32px",
                                    minWidth: "32px",
                                    minHeight: "32px",
                                    maxHeight: "32px",
                                  }}
                                >
                                  <Add />
                                </Button>
                              ) : (
                                <Button
                                  disabled={editFieldsAreDisabled}
                                  color="error"
                                  variant="contained"
                                  fullWidth
                                  sx={{
                                    maxWidth: "32px",
                                    minWidth: "32px",
                                    minHeight: "32px",
                                    maxHeight: "32px",
                                  }}
                                  onClick={() => {
                                    deleteIntermediaryCityRow(
                                      intermediaryCityRow.id
                                    );
                                  }}
                                  tabIndex={-1}
                                >
                                  <RemoveIcon />
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedAccordionShipperCarrierDetails}
                onChange={handleExpandAccordionShipperCarrierDetails}
              >
                <AccordionSummary tabIndex={-1}>
                  <Typography>Beneficiar/Transportator</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      lg={6}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            Beneficiar
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            disabled={editFieldsAreDisabled}
                            value={shipperValue ?? null}
                            onChange={(event, newValue, reason) => {
                              if (reason === "clear") {
                                setShipperValue(null);
                                setShipperBranchValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  shipperId: null,
                                  shipperBranchId: null,
                                  shipperPaymentTermInDays: null,
                                }));
                                setSelectedShipperId(null);
                                fetchShippers();
                              } else {
                                setShipperValue(newValue);
                                setSelectedShipperId(newValue?.id);
                                setShipperBranchValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  shipperId: newValue?.id,
                                  shipperBranchId: null,
                                  shipperPaymentTermInDays:
                                    newValue?.paymentTermInDays,
                                }));
                              }
                            }}
                            options={sortedShipperOptions}
                            filterSelectedOptions
                            getOptionLabel={(option) => `${option.name}`}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={({
                              inputProps: { onChange, ...restInputProps },
                              ...params
                            }) => (
                              <TextField
                                required
                                {...params}
                                inputProps={{
                                  ...restInputProps,
                                  onChange: (...args) => {
                                    setSearchShipperTextInput(
                                      // @ts-ignore
                                      args[0].target.value
                                    );
                                    // @ts-ignore
                                    onChange(...args);
                                  },
                                }}
                                fullWidth
                                size="small"
                                label="Nume"
                                error={error.shipperId}
                                helperText={
                                  error.shipperId && "Câmp obligatoriu!"
                                }
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    height: 32,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  },
                                }}
                              />
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            disabled={editFieldsAreDisabled}
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            onClick={handleOpenAddShipperDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          required
                          size="small"
                          fullWidth
                          label="Termen de plată"
                          type="number"
                          value={
                            formValues?.shipperPaymentTermInDays === null
                              ? ""
                              : formValues?.shipperPaymentTermInDays
                          }
                          error={error.shipperPaymentTermInDays}
                          helperText={
                            error.shipperPaymentTermInDays &&
                            "Câmp obligatoriu!"
                          }
                          disabled
                          onKeyDown={blockInvalidCharNumberInput}
                          inputProps={{ min: 0 }}
                          // onChange={(event) =>
                          //   setFormValues((prev: any) => ({
                          //     ...prev,
                          //     shipperPaymentTermInDays: event.target.value,
                          //   }))
                          // }
                          InputLabelProps={{
                            style: {
                              lineHeight: "17px",
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 32,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                zile
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            value={shipperBranchValue ?? null}
                            onChange={(event, newValue) => {
                              setShipperBranchValue(newValue);
                              setFormValues((prev: any) => ({
                                ...prev,
                                shipperBranchId: newValue?.id,
                              }));
                            }}
                            options={sortedShipperBranchOptions}
                            filterSelectedOptions
                            disabled={
                              !selectedShipperId || editFieldsAreDisabled
                            }
                            getOptionLabel={(option) => `${option.name}`}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                label="Punct de lucru"
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    height: 32,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  },
                                }}
                              />
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            disabled={
                              !selectedShipperId || editFieldsAreDisabled
                            }
                            onClick={handleOpenAddShipperBranchDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          disabled={editFieldsAreDisabled}
                          size="small"
                          fullWidth
                          label="Referință"
                          value={formValues?.shipperReferenceId ?? null}
                          onChange={(event) =>
                            setFormValues((prev: any) => ({
                              ...prev,
                              shipperReferenceId:
                                event.target.value.toUpperCase(),
                            }))
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputLabelProps={{
                            style: {
                              lineHeight: "17px",
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 32,
                            },
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      lg={6}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            Transportator
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            disabled={editFieldsAreDisabled}
                            value={carrierValue ?? null}
                            onChange={(event, newValue, reason) => {
                              if (reason === "clear") {
                                setCarrierValue(null);
                                setCarrierCarValue(null);
                                setCarrierTrailerValue(null);
                                setCarrierDriverValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  carrierId: null,
                                  carrierCarId: null,
                                  carrierTrailerId: null,
                                  carrierDriverId: null,
                                }));
                                setSelectedCarrierId(null);
                                fetchCarriers();
                              } else {
                                setCarrierValue(newValue);
                                setSelectedCarrierId(newValue?.id);
                                setCarrierCarValue(null);
                                setCarrierTrailerValue(null);
                                setCarrierDriverValue(null);
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  carrierId: newValue?.id,
                                  carrierCarId: null,
                                  carrierTrailerId: null,
                                  carrierDriverId: null,
                                }));
                              }
                            }}
                            options={sortedCarrierOptions}
                            filterSelectedOptions
                            getOptionLabel={(option) => `${option.name}`}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={({
                              inputProps: { onChange, ...restInputProps },
                              ...params
                            }) => {
                              return (
                                <TextField
                                  required
                                  {...params}
                                  inputProps={{
                                    ...restInputProps,
                                    onChange: (...args) => {
                                      setSearchCarrierTextInput(
                                        // @ts-ignore
                                        args[0].target.value
                                      );
                                      // @ts-ignore
                                      onChange(...args);
                                    },
                                  }}
                                  fullWidth
                                  size="small"
                                  label="Nume"
                                  error={error.carrierId}
                                  helperText={
                                    error.carrierId && "Câmp obligatoriu!"
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: {
                                      height: 32,
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                    },
                                  }}
                                />
                              );
                            }}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            disabled={editFieldsAreDisabled}
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            onClick={handleOpenAddCarrierDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            value={carrierDriverValue ?? null}
                            onChange={(event, newValue) => {
                              setCarrierDriverValue(newValue);
                              setFormValues((prev: any) => ({
                                ...prev,
                                carrierDriverId: newValue?.id,
                              }));
                            }}
                            options={sortedCarrierDriverOptions}
                            filterSelectedOptions
                            disabled={
                              !selectedCarrierId || editFieldsAreDisabled
                            }
                            getOptionLabel={(option) => `${option.name}`}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                fullWidth
                                size="small"
                                label="Șofer"
                                error={error.carrierDriverId}
                                helperText={
                                  error.carrierDriverId && "Câmp obligatoriu!"
                                }
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    height: 32,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  },
                                }}
                              />
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            disabled={
                              !selectedCarrierId || editFieldsAreDisabled
                            }
                            onClick={handleOpenAddCarrierDriverDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <TextField
                          disabled={editFieldsAreDisabled}
                          required
                          size="small"
                          fullWidth
                          label="Plată"
                          type="number"
                          value={formValues?.carrierPaymentTermInDays}
                          error={error.carrierPaymentTermInDays}
                          helperText={
                            error.carrierPaymentTermInDays &&
                            "Câmp obligatoriu!"
                          }
                          onKeyDown={blockInvalidCharNumberInput}
                          inputProps={{ min: 0 }}
                          onChange={(event) => {
                            setFormValues((prev: any) => ({
                              ...prev,
                              carrierPaymentTermInDays: event.target.value,
                            }));
                          }}
                          InputLabelProps={{
                            style: {
                              lineHeight: "17px",
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 32,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                zile
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            value={carrierCarValue ?? null}
                            onChange={(event, newValue) => {
                              setCarrierCarValue(newValue);
                              setFormValues((prev: any) => ({
                                ...prev,
                                carrierCarId: newValue?.id || null,
                              }));
                            }}
                            options={sortedCarrierCarOptions}
                            filterSelectedOptions
                            disabled={
                              !selectedCarrierId || editFieldsAreDisabled
                            }
                            getOptionLabel={(option) =>
                              `${option.vehicleRegistrationPlate}`
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={({
                              inputProps: { onChange, ...restInputProps },
                              ...params
                            }) => (
                              <TextField
                                required
                                {...params}
                                fullWidth
                                size="small"
                                label="Mașină"
                                error={
                                  carrierCarCmrInsuranceErrorObj.error ||
                                  error.carrierCarId
                                }
                                helperText={
                                  carrierCarCmrInsuranceErrorObj.message ||
                                  (error.carrierCarId &&
                                    "Câmp obligatoriu dacă nu există remorcă!")
                                }
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                inputProps={{
                                  ...restInputProps,
                                  onChange: (...args) => {
                                    setSearchCarrierCarTextInput(
                                      // @ts-ignore
                                      args[0].target.value
                                    );
                                    // @ts-ignore
                                    onChange(...args);
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    height: 32,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  },
                                }}
                              />
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            color="warning"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                              mr: 1,
                            }}
                            disabled={
                              !formValues.carrierCarId ||
                              (isDispatcher &&
                                !getErrorForCarrierCarOrTrailer(carrierCarValue)
                                  .error) ||
                              (editFieldsAreDisabled &&
                                !getErrorForCarrierCarOrTrailer(carrierCarValue)
                                  .error)
                            }
                            onClick={handleOpenEditCarrierCarDialog}
                            tabIndex={-1}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            disabled={
                              !selectedCarrierId || editFieldsAreDisabled
                            }
                            onClick={handleOpenAddCarrierCarDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box sx={{ display: "flex" }}>
                          <Autocomplete
                            value={carrierTrailerValue ?? null}
                            onChange={(event, newValue) => {
                              setCarrierTrailerValue(newValue);
                              setFormValues((prev: any) => ({
                                ...prev,
                                carrierTrailerId: newValue?.id || null,
                              }));
                            }}
                            options={sortedCarrierTrailerOptions}
                            filterSelectedOptions
                            disabled={
                              !selectedCarrierId || editFieldsAreDisabled
                            }
                            getOptionLabel={(option) =>
                              `${option.vehicleRegistrationPlate}`
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            noOptionsText="Fără opțiuni"
                            renderInput={({
                              inputProps: { onChange, ...restInputProps },
                              ...params
                            }) => (
                              <TextField
                                required
                                {...params}
                                fullWidth
                                size="small"
                                label="Remorcă"
                                error={
                                  carrierTrailerCmrInsuranceErrorObj.error ||
                                  error.carrierTrailerId
                                }
                                helperText={
                                  carrierTrailerCmrInsuranceErrorObj.message ||
                                  (error.carrierTrailerId &&
                                    "Câmp obligatoriu dacă nu există mașină!")
                                }
                                InputLabelProps={{
                                  style: {
                                    lineHeight: "17px",
                                  },
                                }}
                                inputProps={{
                                  ...restInputProps,
                                  onChange: (...args) => {
                                    setSearchCarrierTrailerTextInput(
                                      // @ts-ignore
                                      args[0].target.value
                                    );
                                    // @ts-ignore
                                    onChange(...args);
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    height: 32,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                  },
                                }}
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    handleConfirm()?.then(
                                      handleCloseConfirmTripDialog
                                    );
                                  }
                                }}
                              />
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                          />
                          <Button
                            color="warning"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                              mr: 1,
                            }}
                            disabled={
                              !formValues.carrierTrailerId ||
                              (isDispatcher &&
                                !getErrorForCarrierCarOrTrailer(
                                  carrierTrailerValue
                                ).error) ||
                              (editFieldsAreDisabled &&
                                !getErrorForCarrierCarOrTrailer(
                                  carrierTrailerValue
                                ).error)
                            }
                            onClick={handleOpenEditCarrierTrailerDialog}
                            tabIndex={-1}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              maxWidth: "32px",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                            disabled={
                              !selectedCarrierId || editFieldsAreDisabled
                            }
                            onClick={handleOpenAddCarrierTrailerDialog}
                            tabIndex={-1}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedAccordionInvoiceRows}
                onChange={handleExpandAccordionInvoiceRows}
              >
                <AccordionSummary tabIndex={-1}>
                  <Typography>Informații factură</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      lg={6}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            Beneficiar
                          </Typography>
                        </Grid>
                      </Grid>
                      {shipperInvoiceRowValues?.map(
                        (shipperInvoiceRow, index) => {
                          return (
                            <Grid item xs={12} key={shipperInvoiceRow.id}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                }}
                              >
                                <TextField
                                  disabled={editFieldsAreDisabled}
                                  required
                                  size="small"
                                  fullWidth
                                  label="Distanță"
                                  type="number"
                                  value={shipperInvoiceRow?.distanceKm ?? ""}
                                  onKeyDown={blockInvalidCharNumberInput}
                                  inputProps={{ min: 0 }}
                                  error={
                                    shipperInvoiceRow.errorDistanceKm &&
                                    shipperInvoiceRow.touchedDistanceKm
                                  }
                                  helperText={
                                    shipperInvoiceRow.errorDistanceKm &&
                                    shipperInvoiceRow.touchedDistanceKm &&
                                    "Câmp obligatoriu!"
                                  }
                                  sx={{ flexGrow: 1, mr: 1 }}
                                  onChange={(event) =>
                                    editShipperInvoiceRow(
                                      shipperInvoiceRow.id,
                                      {
                                        distanceKm: event.target.value,
                                        value: shipperInvoiceRow.value,
                                      }
                                    )
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        km
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                                <CloseOutlinedIcon
                                  fontSize="medium"
                                  sx={{ flexGrow: 1, mr: 1, mt: 1 }}
                                />
                                <TextField
                                  disabled={editFieldsAreDisabled}
                                  required
                                  size="small"
                                  fullWidth
                                  label="Valoare"
                                  type="number"
                                  value={shipperInvoiceRow?.value ?? ""}
                                  onKeyDown={blockInvalidCharNumberInput}
                                  inputProps={{ min: 0 }}
                                  error={
                                    shipperInvoiceRow.errorValue &&
                                    shipperInvoiceRow.touchedValue
                                  }
                                  helperText={
                                    shipperInvoiceRow.errorValue &&
                                    shipperInvoiceRow.touchedValue &&
                                    "Câmp obligatoriu!"
                                  }
                                  sx={{ flexGrow: 1, mr: 1 }}
                                  onChange={(event) =>
                                    editShipperInvoiceRow(
                                      shipperInvoiceRow.id,
                                      {
                                        value: event.target.value,
                                        distanceKm:
                                          shipperInvoiceRow.distanceKm,
                                      }
                                    )
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        RON
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                                <TextField
                                  size="small"
                                  fullWidth
                                  label="TVA"
                                  type="number"
                                  onKeyDown={blockInvalidCharNumberInput}
                                  inputProps={{ min: 0 }}
                                  value={
                                    (Number(shipperInvoiceRow.distanceKm) *
                                      Number(shipperInvoiceRow.value) *
                                      vat) /
                                    100
                                  }
                                  sx={{ flexGrow: 1, mr: 1 }}
                                  disabled
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        RON
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                                {index === 0 ? (
                                  <Button
                                    disabled={editFieldsAreDisabled}
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    onClick={addShipperInvoiceRow}
                                    tabIndex={-1}
                                    sx={{
                                      maxWidth: "32px",
                                      minWidth: "32px",
                                      minHeight: "32px",
                                      maxHeight: "32px",
                                    }}
                                  >
                                    <Add />
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={editFieldsAreDisabled}
                                    color="error"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                      maxWidth: "32px",
                                      minWidth: "32px",
                                      minHeight: "32px",
                                      maxHeight: "32px",
                                    }}
                                    onClick={() => {
                                      deleteShipperInvoiceRow(
                                        shipperInvoiceRow.id
                                      );
                                    }}
                                    tabIndex={-1}
                                  >
                                    <RemoveIcon />
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      lg={6}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            Transportator
                          </Typography>
                        </Grid>
                      </Grid>
                      {carrierInvoiceRowValues?.map(
                        (carrierInvoiceRow, index) => {
                          return (
                            <Grid item xs={12} key={carrierInvoiceRow.id}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                }}
                              >
                                <TextField
                                  disabled={editFieldsAreDisabled}
                                  required
                                  size="small"
                                  fullWidth
                                  label="Distanță"
                                  type="number"
                                  value={carrierInvoiceRow?.distanceKm ?? ""}
                                  onKeyDown={blockInvalidCharNumberInput}
                                  inputProps={{ min: 0 }}
                                  error={
                                    carrierInvoiceRow.errorDistanceKm &&
                                    carrierInvoiceRow.touchedDistanceKm
                                  }
                                  helperText={
                                    carrierInvoiceRow.errorDistanceKm &&
                                    carrierInvoiceRow.touchedDistanceKm &&
                                    "Câmp obligatoriu!"
                                  }
                                  sx={{ flexGrow: 1, mr: 1 }}
                                  onChange={(event) =>
                                    editCarrierInvoiceRow(
                                      carrierInvoiceRow.id,
                                      {
                                        distanceKm: event.target.value,
                                        value: carrierInvoiceRow.value,
                                      }
                                    )
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        km
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                                <CloseOutlinedIcon
                                  fontSize="medium"
                                  sx={{ flexGrow: 1, mr: 1, mt: 1 }}
                                />
                                <TextField
                                  disabled={editFieldsAreDisabled}
                                  required
                                  size="small"
                                  fullWidth
                                  label="Valoare"
                                  type="number"
                                  value={carrierInvoiceRow?.value ?? ""}
                                  onKeyDown={blockInvalidCharNumberInput}
                                  inputProps={{ min: 0 }}
                                  error={
                                    carrierInvoiceRow.errorValue &&
                                    carrierInvoiceRow.touchedValue
                                  }
                                  helperText={
                                    carrierInvoiceRow.errorValue &&
                                    carrierInvoiceRow.touchedValue &&
                                    "Câmp obligatoriu!"
                                  }
                                  sx={{ flexGrow: 1, mr: 1 }}
                                  onChange={(event) =>
                                    editCarrierInvoiceRow(
                                      carrierInvoiceRow.id,
                                      {
                                        value: event.target.value,
                                        distanceKm:
                                          carrierInvoiceRow.distanceKm,
                                      }
                                    )
                                  }
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        RON
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                                <TextField
                                  size="small"
                                  fullWidth
                                  label="TVA"
                                  type="number"
                                  onKeyDown={blockInvalidCharNumberInput}
                                  inputProps={{ min: 0 }}
                                  value={
                                    (Number(carrierInvoiceRow.distanceKm) *
                                      Number(carrierInvoiceRow.value) *
                                      vat) /
                                    100
                                  }
                                  sx={{ flexGrow: 1, mr: 1 }}
                                  disabled
                                  InputLabelProps={{
                                    style: {
                                      lineHeight: "17px",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      height: 32,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        RON
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                                {index === 0 ? (
                                  <Button
                                    disabled={editFieldsAreDisabled}
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    onClick={addCarrierInvoiceRow}
                                    tabIndex={-1}
                                    sx={{
                                      maxWidth: "32px",
                                      minWidth: "32px",
                                      minHeight: "32px",
                                      maxHeight: "32px",
                                    }}
                                  >
                                    <Add />
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={editFieldsAreDisabled}
                                    color="error"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                      maxWidth: "32px",
                                      minWidth: "32px",
                                      minHeight: "32px",
                                      maxHeight: "32px",
                                    }}
                                    onClick={() => {
                                      deleteCarrierInvoiceRow(
                                        carrierInvoiceRow.id
                                      );
                                    }}
                                    tabIndex={-1}
                                  >
                                    <RemoveIcon />
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant="subtitle1">
                          {`Venit: ${shipperTotal.toFixed(2)} RON`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {`Cheltuială: ${carrierTotal.toFixed(2)} RON`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {profitNoVat === 0 ? (
                          <Typography variant="subtitle1">
                            {`Profit fără TVA: ${profitNoVat.toFixed(2)} RON`}
                          </Typography>
                        ) : profitNoVat > 0 ? (
                          <GreenTypography variant="subtitle1">
                            {`Profit fără TVA: ${profitNoVat.toFixed(2)} RON`}
                          </GreenTypography>
                        ) : (
                          <RedTypography variant="subtitle1">
                            {`Profit fără TVA: ${profitNoVat.toFixed(2)} RON`}
                          </RedTypography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedAccordionTripAdditionalDetails}
                onChange={handleExpandAccordionTripAdditionalDetails}
              >
                <AccordionSummary tabIndex={-1}>
                  <Typography>Informații suplimentare</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12} lg={6}>
                      <TextField
                        disabled={editFieldsAreDisabled}
                        label="Observații"
                        multiline
                        fullWidth
                        size="small"
                        // minRows={3}
                        variant="outlined"
                        inputProps={{
                          // textarea: {
                          resize: "both",
                          // },
                          style: { textTransform: "uppercase" },
                        }}
                        InputLabelProps={{
                          style: {
                            lineHeight: "17px",
                          },
                        }}
                        InputProps={{
                          style: {
                            paddingBottom: 4.5,
                            paddingTop: 4.5,
                          },
                        }}
                        value={formValues?.observations ?? ""}
                        onChange={(event) =>
                          setFormValues((prev: any) => ({
                            ...prev,
                            observations: event.target.value.toUpperCase(),
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        label="Comentarii"
                        multiline
                        fullWidth
                        size="small"
                        // minRows={3}
                        variant="outlined"
                        inputProps={{
                          // textarea: {
                          resize: "both",
                          // },
                          style: { textTransform: "uppercase" },
                        }}
                        InputLabelProps={{
                          style: {
                            lineHeight: "17px",
                          },
                        }}
                        InputProps={{
                          style: {
                            paddingBottom: 4.5,
                            paddingTop: 4.5,
                          },
                        }}
                        value={formValues?.comments ?? ""}
                        onChange={(event) =>
                          setFormValues((prev: any) => ({
                            ...prev,
                            comments: event.target.value.toUpperCase(),
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField
                        label="Note interne"
                        multiline
                        fullWidth
                        size="small"
                        // minRows={3}
                        variant="outlined"
                        inputProps={{
                          // textarea: {
                          resize: "both",
                          // },
                          style: { textTransform: "uppercase" },
                        }}
                        InputLabelProps={{
                          style: {
                            lineHeight: "17px",
                          },
                        }}
                        InputProps={{
                          style: {
                            paddingBottom: 4.5,
                            paddingTop: 4.5,
                          },
                        }}
                        value={formValues?.internalNotes ?? ""}
                        onChange={(event) =>
                          setFormValues((prev: any) => ({
                            ...prev,
                            internalNotes: event.target.value.toUpperCase(),
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={isAdminOrManager ? 3 : 4}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleConfirm()?.then(handleCloseConfirmTripDialog);
                    }}
                  >
                    Confirmă
                  </Button>
                </Grid>
                <Grid item xs={12} md={isAdminOrManager ? 3 : 4}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleConfirmAndBill()?.then(
                        handleCloseConfirmTripDialog
                      );
                    }}
                    disabled={trip.status === TripStatus.Billed}
                  >
                    Confirmă și facturează
                  </Button>
                </Grid>
                {isAdminOrManager && (
                  <Grid item xs={12} md={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleConfirmAndBlock()?.then(
                          handleCloseConfirmTripDialog
                        );
                      }}
                    >
                      Salvează&nbsp;și&nbsp;reține
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} md={isAdminOrManager ? 3 : 4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleCloseConfirmTripDialog}
                  >
                    Renunță
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {openAddCargoTypeDialog && (
        <AddCargoTypeDialog
          open={openAddCargoTypeDialog}
          onCargoTypeCreated={(createdCargoType: CargoType) => {
            fetchCargoType();
            setCargoTypeValue(createdCargoType);
            setFormValues((prev: any) => ({
              ...prev,
              cargoTypeId: createdCargoType.id,
            }));
          }}
          onClose={() => {
            setOpenAddCargoTypeDialog(false);
          }}
        />
      )}
      {openAddLoadingCountryDialog && (
        <AddLoadingCountryDialog
          open={openAddLoadingCountryDialog}
          onLoadingCountryCreated={(createdLoadingCountry: Country) => {
            fetchLoadingCountries();
            updateOnCountryCreatedIndex();
            setLoadingCountryValue(createdLoadingCountry);
            setSelectedLoadingCountryId(createdLoadingCountry.id);
            setFormValues((prev: any) => ({
              ...prev,
              loadingCityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              loadingCountryId: false,
              loadingCityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddLoadingCountryDialog(false);
          }}
        />
      )}
      {openAddUnloadingCountryDialog && (
        <AddUnloadingCountryDialog
          open={openAddUnloadingCountryDialog}
          onUnloadingCountryCreated={(createdUnloadingCountry: Country) => {
            fetchUnloadingCountries();
            updateOnCountryCreatedIndex();
            setUnloadingCountryValue(createdUnloadingCountry);
            setSelectedUnloadingCountryId(createdUnloadingCountry.id);
            setFormValues((prev: any) => ({
              ...prev,
              unloadingCityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              unloadingCountryId: false,
              unloadingCityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddUnloadingCountryDialog(false);
          }}
        />
      )}
      {openAddLoadingCityDialog && (
        <AddLoadingCityDialog
          open={openAddLoadingCityDialog}
          onLoadingCityCreated={(createdLoadingCity: City) => {
            setLoadingCityValue(createdLoadingCity);
            fetchLoadingCities();
            setFormValues((prev: any) => ({
              ...prev,
              loadingCityId: createdLoadingCity.id,
            }));
            setError((prev: any) => ({
              ...prev,
              loadingCityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddLoadingCityDialog(false);
          }}
          selectedLoadingCountryId={selectedLoadingCountryId}
        />
      )}
      {openAddUnloadingCityDialog && (
        <AddUnloadingCityDialog
          open={openAddUnloadingCityDialog}
          onUnloadingCityCreated={(createdUnloadingCity: City) => {
            setUnloadingCityValue(createdUnloadingCity);
            fetchUnloadingCities();
            setFormValues((prev: any) => ({
              ...prev,
              unloadingCityId: createdUnloadingCity.id,
            }));
            setError((prev: any) => ({
              ...prev,
              unloadingCityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddUnloadingCityDialog(false);
          }}
          selectedUnloadingCountryId={selectedUnloadingCountryId}
        />
      )}
      {openAddCarrierDialog && (
        <AddCarrierDialog
          open={openAddCarrierDialog}
          onCarrierCreated={(createdCarrier: Carrier) => {
            fetchCarriers();
            updateOnCarrierCreatedIndex();
            setCarrierValue(createdCarrier);
            setSelectedCarrierId(createdCarrier.id);
            setFormValues((prev: any) => ({
              ...prev,
              carrierId: createdCarrier?.id,
              carrierCarId: null,
              carrierTrailerId: null,
              carrierDriverId: null,
            }));
            setCarrierCarValue(null);
            setCarrierTrailerValue(null);
            setCarrierDriverValue(null);
            setError((prev: any) => ({
              ...prev,
              carrierId: false,
              carrierCarId: false,
              carrierTrailerId: false,
              carrierDriverId: false,
            }));
          }}
          onClose={() => setOpenAddCarrierDialog(false)}
        />
      )}
      {openEditCarrierCarDialog && (
        <EditCarrierCarDialog
          open={openEditCarrierCarDialog}
          onClose={() => setOpenEditCarrierCarDialog(false)}
          carrierCar={carrierCarValue || undefined}
          onCarrierCarEdited={(editedCarrierCar: CarrierCar) => {
            fetchCarrierCars();
            setCarrierCarValue(editedCarrierCar);
            setFormValues((prev: any) => ({
              ...prev,
              carrierCarId: editedCarrierCar.id,
            }));
            setError((prev: any) => ({
              ...prev,
              carrierCarId: false,
            }));
          }}
          shouldDisableCarrierSelection={true}
        />
      )}
      {openAddCarrierCarDialog && (
        <AddCarrierCarDialog
          open={openAddCarrierCarDialog}
          onCarrierCarCreated={(createdCarrierCar: CarrierCar) => {
            fetchCarrierCars();
            setCarrierCarValue(createdCarrierCar);
            setFormValues((prev: any) => ({
              ...prev,
              carrierCarId: createdCarrierCar.id,
            }));
            setError((prev: any) => ({
              ...prev,
              carrierCarId: false,
            }));
          }}
          onCarrierCreatedIndex={onCarrierCreatedIndex}
          onClose={() => setOpenAddCarrierCarDialog(false)}
          carrier={carrierValue}
          shouldDisableCarrierSelection={true}
        />
      )}
      {openEditCarrierTrailerDialog && (
        <EditCarrierTrailerDialog
          open={openEditCarrierTrailerDialog}
          onClose={() => setOpenEditCarrierTrailerDialog(false)}
          carrierTrailer={carrierTrailerValue || undefined}
          onCarrierTrailerEdited={(editedCarrierTrailer: CarrierTrailer) => {
            fetchCarrierTrailers();
            setCarrierTrailerValue(editedCarrierTrailer);
            setFormValues((prev: any) => ({
              ...prev,
              carrierTrailerId: editedCarrierTrailer.id,
            }));
            setError((prev: any) => ({
              ...prev,
              carrierTrailerId: false,
            }));
          }}
          shouldDisableCarrierSelection={true}
        />
      )}
      {openAddCarrierTrailerDialog && (
        <AddCarrierTrailerDialog
          open={openAddCarrierTrailerDialog}
          onCarrierTrailerCreated={(createdCarrierTrailer: CarrierTrailer) => {
            fetchCarrierTrailers();
            setCarrierTrailerValue(createdCarrierTrailer);
            setFormValues((prev: any) => ({
              ...prev,
              carrierTrailerId: createdCarrierTrailer.id,
            }));
            setError((prev: any) => ({
              ...prev,
              carrierTrailerId: false,
            }));
          }}
          onCarrierCreatedIndex={onCarrierCreatedIndex}
          onClose={() => setOpenAddCarrierTrailerDialog(false)}
          carrier={carrierValue}
          shouldDisableCarrierSelection={true}
        />
      )}
      {openAddCarrierDriverDialog && (
        <AddCarrierDriverDialog
          open={openAddCarrierDriverDialog}
          onCarrierDriverCreated={(createdCarrierDriver: CarrierDriver) => {
            fetchCarrierDrivers();
            setCarrierDriverValue(createdCarrierDriver);
            setFormValues((prev: any) => ({
              ...prev,
              carrierDriverId: createdCarrierDriver.id,
            }));
            setError((prev: any) => ({
              ...prev,
              carrierDriverId: false,
            }));
          }}
          onCarrierCreatedIndex={onCarrierCreatedIndex}
          onClose={() => setOpenAddCarrierDriverDialog(false)}
          carrier={carrierValue}
          shouldDisableCarrierSelection={true}
        />
      )}
      {openAddShipperDialog && (
        <AddShipperDialog
          open={openAddShipperDialog}
          onShipperCreated={(createdShipper: Shipper) => {
            fetchShippers();
            updateOnShipperCreatedIndex();
            setShipperValue(createdShipper);
            setSelectedShipperId(createdShipper.id);
            setFormValues((prev: any) => ({
              ...prev,
              shipperId: createdShipper?.id,
              shipperBranchId: null,
              shipperPaymentTermInDays: createdShipper?.paymentTermInDays,
            }));
            setShipperBranchValue(null);
            setError((prev: any) => ({
              ...prev,
              shipperId: false,
              shipperBranchId: false,
              shipperPaymentTermInDays: false,
            }));
          }}
          onClose={() => setOpenAddShipperDialog(false)}
        />
      )}
      {openAddShipperBranchDialog && (
        <AddShipperBranchDialog
          open={openAddShipperBranchDialog}
          onShipperBranchCreated={(createdShipperBranch: ShipperBranch) => {
            fetchShipperBranches();
            setShipperBranchValue(createdShipperBranch);
            setFormValues((prev: any) => ({
              ...prev,
              shipperBranchId: createdShipperBranch.id,
            }));
            setError((prev: any) => ({
              ...prev,
              shipperBranchId: false,
            }));
          }}
          onShipperCreatedIndex={onShipperCreatedIndex}
          onClose={() => setOpenAddShipperBranchDialog(false)}
          shipper={shipperValue}
          shouldDisableShipperSelection={true}
        />
      )}

      <TripDocumentsDialog
        open={openTripDocumentsDialog}
        onClose={() => setOpenTripDocumentsDialog(false)}
        trip={trip}
      />
    </>
  );
}
