import RootRequest, { QueryParam } from "./RootRequest";
import adjustForTimezone from "../functions/adjustForTimezone";

export default class ReportRequest extends RootRequest {
  get apiResource() {
    return "api/report";
  }

  get apiRoutes() {
    return {
      // @ts-ignore
      ...super.apiRoutes,
      TRIPS_PER_BENEFICIARY: `${this.apiRoute}/trips-per-beneficiary`,
      TRIP_COUNT_PER_DISPATCHER_PER_SHIPPER: `${this.apiRoute}/trip-count-per-dispatcher-per-shipper`,
      LOSER_TRIPS: `${this.apiRoute}/loser-trips`,
      PROFIT_LESS_THAN_ONE_HUNDRED: `${this.apiRoute}/profit-less-than-100-trips`,
      TOP_CLIENTS: `${this.apiRoute}/top-clients`,
    };
  }

  async getTripsPerBeneficiary(
    startDate: Date | undefined,
    endDate: Date | undefined,
    shipperTripType: string | undefined,
    carrierTripType: string | undefined,
    shipperId: number | undefined,
    carrierId: number | undefined,
    carrierCarId: number | undefined,
    carrierTrailerId: number | undefined,
    carrierDriverId: number | undefined,
    loadingCountryId: number | undefined,
    loadingCountyId: number | undefined,
    loadingCityId: number | undefined,
    unloadingCountryId: number | undefined,
    unloadingCountyId: number | undefined,
    unloadingCityId: number | undefined
  ) {
    const queryParams: QueryParam[] = [];

    if (startDate) {
      queryParams.push({
        key: "startDate",
        value: adjustForTimezone(startDate).toISOString(),
      });
    }
    if (endDate) {
      queryParams.push({
        key: "endDate",
        value: adjustForTimezone(endDate).toISOString(),
      });
    }
    if (shipperTripType) {
      queryParams.push({
        key: "shipperTripType",
        value: shipperTripType,
      });
    }
    if (carrierTripType) {
      queryParams.push({
        key: "carrierTripType",
        value: carrierTripType,
      });
    }
    if (shipperId) {
      queryParams.push({
        key: "shipperId",
        value: shipperId.toString(),
      });
    }
    if (carrierId) {
      queryParams.push({
        key: "carrierId",
        value: carrierId.toString(),
      });
    }
    if (carrierCarId) {
      queryParams.push({
        key: "carrierCarId",
        value: carrierCarId.toString(),
      });
    }
    if (carrierTrailerId) {
      queryParams.push({
        key: "carrierTrailerId",
        value: carrierTrailerId.toString(),
      });
    }
    if (carrierDriverId) {
      queryParams.push({
        key: "carrierDriverId",
        value: carrierDriverId.toString(),
      });
    }
    if (loadingCountryId) {
      queryParams.push({
        key: "loadingCountryId",
        value: loadingCountryId.toString(),
      });
    }
    if (loadingCountyId) {
      queryParams.push({
        key: "loadingCountyId",
        value: loadingCountyId.toString(),
      });
    }
    if (loadingCityId) {
      queryParams.push({
        key: "loadingCityId",
        value: loadingCityId.toString(),
      });
    }
    if (unloadingCountryId) {
      queryParams.push({
        key: "unloadingCountryId",
        value: unloadingCountryId.toString(),
      });
    }
    if (unloadingCountyId) {
      queryParams.push({
        key: "unloadingCountyId",
        value: unloadingCountyId.toString(),
      });
    }
    if (unloadingCityId) {
      queryParams.push({
        key: "unloadingCityId",
        value: unloadingCityId.toString(),
      });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.TRIPS_PER_BENEFICIARY + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async getTripCountPerDispatcherPerShipper(
    startDate: Date | undefined,
    endDate: Date | undefined
  ) {
    const queryParams: QueryParam[] = [];

    if (startDate) {
      queryParams.push({
        key: "startDate",
        value: adjustForTimezone(startDate).toISOString(),
      });
    }
    if (endDate) {
      queryParams.push({
        key: "endDate",
        value: adjustForTimezone(endDate).toISOString(),
      });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.TRIP_COUNT_PER_DISPATCHER_PER_SHIPPER +
        this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async getLoserTrips(startDate: Date | undefined, endDate: Date | undefined) {
    const queryParams: QueryParam[] = [];

    if (startDate) {
      queryParams.push({
        key: "startDate",
        value: adjustForTimezone(startDate).toISOString(),
      });
    }
    if (endDate) {
      queryParams.push({
        key: "endDate",
        value: adjustForTimezone(endDate).toISOString(),
      });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.LOSER_TRIPS + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async getProfitLessThanOneHundredTrips(
    startDate: Date | undefined,
    endDate: Date | undefined,
    carrierPaymentTermInDays: number | undefined
  ) {
    const queryParams: QueryParam[] = [];

    if (startDate) {
      queryParams.push({
        key: "startDate",
        value: adjustForTimezone(startDate).toISOString(),
      });
    }
    if (endDate) {
      queryParams.push({
        key: "endDate",
        value: adjustForTimezone(endDate).toISOString(),
      });
    }
    if (carrierPaymentTermInDays) {
      queryParams.push({
        key: "carrierPaymentTermInDays",
        value: carrierPaymentTermInDays.toString(),
      });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.PROFIT_LESS_THAN_ONE_HUNDRED +
        this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async getTopClients(
    startDate: Date | undefined,
    endDate: Date | undefined,
    billed: boolean | undefined,
    trips: boolean | undefined,
    profit: boolean | undefined
  ) {
    const queryParams: QueryParam[] = [];

    if (startDate) {
      queryParams.push({
        key: "startDate",
        value: adjustForTimezone(startDate).toISOString(),
      });
    }
    if (endDate) {
      queryParams.push({
        key: "endDate",
        value: adjustForTimezone(endDate).toISOString(),
      });
    }
    if (billed) {
      queryParams.push({
        key: "billed",
        value: billed.toString(),
      });
    }
    if (trips) {
      queryParams.push({
        key: "trips",
        value: trips.toString(),
      });
    }
    if (profit) {
      queryParams.push({
        key: "profit",
        value: profit.toString(),
      });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.TOP_CLIENTS + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }
}

export const reportRequest = new ReportRequest();
