import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import efeImpexLogo from "../assets/efe_logo_transparent.png";
import etmsPdfSignature from "../assets/etmsPdfSignature.jpg";
import * as React from "react";
import { useEffect, useState } from "react";
import { userRequest } from "../api/UserRequest";
import dateFormat from "dateformat";
import { getCarrierCarTrailerDisplayName } from "../functions/getCarrierCarTrailerDisplayName";

Font.registerHyphenationCallback((word) => {
  return [word];
});

const styles = StyleSheet.create({
  viewer: {
    width: "1200px",
    height: window.innerHeight,
  },
  page: {
    backgroundColor: "white",
    color: "black",
    padding: "20px 50px 25px 50px",
  },
  section: { marginBottom: 10 },
  regular9: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Times-Roman",
  },
  regular7: {
    fontSize: 7,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  regularItalic9: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "Times-Italic",
  },
  boldItalic16: {
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "Times-BoldItalic",
  },
  boldItalic16Red: {
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "Times-BoldItalic",
    color: "#ff1744",
  },
  boldItalic9: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "Times-BoldItalic",
  },
  bold10Red: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Times-Bold",
    color: "#ff1744",
  },
  bold10: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Times-Bold",
  },
  bold9: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Times-Bold",
  },
  bold9Red: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Times-Bold",
    color: "#ff1744",
  },
  bold7: {
    fontSize: 7,
    textAlign: "left",
    fontFamily: "Times-Bold",
  },
  container: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    borderColor: "#848884",
    borderWidth: 1,
    alignItems: "center",
    textAlign: "center",
    flexGrow: 1,
  },
  kmHeader: {
    width: "50%",
    backgroundColor: "#E5E4E2",
    borderRightColor: "#848884",
    borderRightWidth: 1,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  priceNoVatHeader: {
    width: "50%",
    backgroundColor: "#E5E4E2",
    borderRightColor: "#848884",
    borderRightWidth: 1,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  km: {
    width: "50%",
    borderRightColor: "#848884",
    borderRightWidth: 1,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
  priceNoVat: {
    width: "50%",
    borderRightColor: "#848884",
    borderRightWidth: 1,
    fontSize: 11,
    fontFamily: "Times-Bold",
  },
});

interface Props {
  trip?: Trip;
}

export default function OrderPDFOld(props: Props) {
  const { trip } = props;
  const [dispatchers, setDispatchers] = useState<User[]>([]);
  let totalPrice = 0;

  const fetchDispatchers = () => {
    userRequest.findAll().then((data) => {
      setDispatchers(
        data.results
          .map((user: User) => ({
            id: user.id,
            username: user.username,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            birthdate: user.birthdate,
            phone: user.phone,
            role: user.role,
          }))
          .filter((user: User) => user.role?.name === "dispatcher")
      );
    });
  };

  useEffect(() => {
    fetchDispatchers();
  }, []);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <div>
            {/*<div style={{ maxWidth: "450px" }}>*/}
            <View style={styles.section}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  alignContent: "center",
                }}
              >
                <Image
                  src={efeImpexLogo}
                  style={{
                    minWidth: 118,
                    maxWidth: 118,
                    minHeight: 68,
                    maxHeight: 68,
                    marginRight: 10,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    alignContent: "center",
                    width: "70%",
                  }}
                >
                  <Text style={styles.bold10}>EFE IMPEX 72 SRL</Text>
                  <Text style={styles.bold9Red}>
                    Adresa Corespondenta: Str. Chiciurei nr 19-21, sector 3,
                    Bucuresti
                  </Text>
                  <Text style={styles.bold9Red}>
                    Plati: Virginia 0732 320 325 / virginia@efe.ro
                  </Text>
                  <Text style={styles.regular9}>
                    Bucuresti, str. Intrarea Parului nr. 32, bl. 59, sc.2, et.9,
                    ap. 82, sector 3
                  </Text>
                  <Text style={styles.regular9}>
                    C.I.F. RO 5720754 J1994007234407 Cont:
                    RO75INGB0000999901646039
                  </Text>
                  <Text style={styles.regular9}>
                    Banca: ING Bank N.V. Amsterdam Dristor, Tel. 021 / 324.25.04
                  </Text>
                  <Text style={styles.regular9}>
                    <Text style={styles.bold9}>Dispeceri: </Text>
                    {dispatchers.map((dispatcher, index, dispatchers) => {
                      if (index + 1 === dispatchers.length) {
                        return (
                          <>
                            <Text style={styles.regular9}>
                              {dispatcher.firstName} {dispatcher.phone};{" "}
                            </Text>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <Text style={styles.regular9}>
                              {dispatcher.firstName} {dispatcher.phone},{" "}
                            </Text>
                          </>
                        );
                      }
                    })}
                    <Text style={styles.bold9}>
                      Plati:{" "}
                      <Text style={styles.regular9}>
                        Virginia 0732 320 325;{" "}
                      </Text>
                    </Text>
                    <Text style={styles.bold9}>
                      E-mail: <Text style={styles.regular9}>office@efe.ro</Text>
                    </Text>
                  </Text>
                </div>
              </div>
            </View>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "center",
              }}
            >
              <div style={{ marginRight: "50px" }}>
                <View style={styles.section}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      alignContent: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Text style={styles.bold9}>
                        Catre: {trip?.carrier ? `${trip.carrier.name}` : "-"}{" "}
                        (CUI: {trip?.carrier ? `${trip.carrier.tin}` : "-"})
                      </Text>
                    </div>
                    <Text style={styles.bold9}>
                      In atentia: {trip?.carrier?.contactPerson || "-"}
                    </Text>
                  </div>
                </View>
              </div>
              <View style={styles.section}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ marginBottom: "10px" }}>
                    <Text style={styles.bold9}>
                      Telefon: {trip?.carrier ? `${trip.carrier.phone}` : "-"}
                    </Text>
                  </div>
                  <Text style={styles.bold9}>
                    E-mail: {trip?.carrier ? `${trip.carrier.email}` : "-"}
                  </Text>
                </div>
              </View>
            </div>
            <View style={styles.section}>
              <Text style={styles.bold9}>
                Referitor la un transport intern pe ruta:{" "}
                {trip?.loadingCity
                  ? `${trip.loadingCity.name?.toUpperCase()} (${
                      trip.loadingCity.county?.shortName?.toUpperCase() ||
                      trip.loadingCity.county?.name?.toUpperCase()
                    }), ${trip.loadingCity.county?.country?.name?.toUpperCase()}`
                  : "-"}{" "}
                -{" "}
                {trip?.unloadingCity
                  ? `${trip.unloadingCity.name?.toUpperCase()} (${
                      trip.unloadingCity.county?.shortName?.toUpperCase() ||
                      trip.unloadingCity.county?.name?.toUpperCase()
                    }), ${trip.unloadingCity.county?.country?.name?.toUpperCase()}`
                  : "-"}
              </Text>
            </View>
            <View style={styles.section}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Text style={styles.boldItalic16}>
                  COMANDA CONTRACT DE TRANSPORT{" "}
                  <Text style={styles.boldItalic16Red}>
                    NR. {trip?.id ? `${trip.id}` : "-"} DIN{" "}
                    {trip?.date
                      ? `${dateFormat(trip.date, "dd-mm-yyyy")}`
                      : "-"}
                  </Text>
                </Text>
                <Text style={styles.bold10Red}>
                  Va rugam sa treceti numarul acestei comenzi pe factura de
                  transport, aceasta este o conditie esentiala a platii.
                </Text>
              </div>
            </View>
            <View style={styles.section}>
              <Text style={styles.regular9}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Va
                rugam sa ne puneti la dispozitie ansamblul cu nr.{" "}
                <Text style={styles.bold9}>
                  {getCarrierCarTrailerDisplayName(
                    trip?.carrierCar,
                    trip?.carrierTrailer
                  )}
                </Text>{" "}
                cu conducator auto{" "}
                <Text style={styles.bold9}>
                  {trip?.carrierDriver
                    ? `${trip.carrierDriver.name?.toUpperCase()}`
                    : "-"}
                </Text>
                , telefon{" "}
                <Text style={styles.bold9}>
                  {trip?.carrierDriver ? `${trip.carrierDriver.phone}` : "-"}
                </Text>{" "}
                care sa efectueze transport intern de marfa, in urmatoarele
                conditii:
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold9}>
                Adresa de incarcare:{" "}
                {trip?.loadingCity
                  ? `${trip.loadingCity.name?.toUpperCase()} (${
                      trip.loadingCity.county?.shortName?.toUpperCase() ||
                      trip.loadingCity.county?.name?.toUpperCase()
                    }), ${trip.loadingCity.county?.country?.name?.toUpperCase()}`
                  : "-"}
                ,{" "}
                {trip?.loadingAddress
                  ? `${trip.loadingAddress.toUpperCase()}`
                  : "-"}
              </Text>
            </View>
            {trip?.intermediaryCities?.length && (
              <View style={styles.section}>
                <div
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  <div>
                    <Text style={styles.bold9}>Puncte intermediare: </Text>
                  </div>
                  <div>
                    {trip?.intermediaryCities?.length &&
                      trip.intermediaryCities.map((intermediaryCity, index) => {
                        return (
                          <>
                            <Text style={styles.bold9}>
                              {`${index + 1}. ${
                                intermediaryCity.address
                                  ? `${intermediaryCity.address.toUpperCase()}, `
                                  : ""
                              }${intermediaryCity.name?.toUpperCase()} (${
                                intermediaryCity.county?.shortName?.toUpperCase() ||
                                intermediaryCity.county?.name?.toUpperCase()
                              }), ${intermediaryCity.county?.country?.name?.toUpperCase()}`}
                            </Text>
                          </>
                        );
                      })}
                  </div>
                </div>
              </View>
            )}
            <View style={styles.section}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.bold9}>
                  Adresa de descarcare:{" "}
                  {trip?.unloadingCity
                    ? `${trip.unloadingCity.name?.toUpperCase()} (${
                        trip.unloadingCity.county?.shortName?.toUpperCase() ||
                        trip.unloadingCity.county?.name?.toUpperCase()
                      }), ${trip.unloadingCity.county?.country?.name?.toUpperCase()}`
                    : "-"}
                  ,{" "}
                  {trip?.unloadingAddress
                    ? `${trip.unloadingAddress.toUpperCase()}`
                    : "-"}
                </Text>
              </div>
            </View>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "center",
              }}
            >
              <div style={{ marginRight: "50px" }}>
                <View style={styles.section}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      alignContent: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Text style={styles.bold9}>
                        Data incarcarii:{" "}
                        {trip?.loadingDate
                          ? `${dateFormat(trip.loadingDate, "dd-mm-yyyy")}`
                          : "-"}
                      </Text>
                    </div>
                    <Text style={styles.bold9}>
                      Tip marfa:{" "}
                      {trip?.cargoType
                        ? `${trip.cargoType.name?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                </View>
              </div>
              <View style={styles.section}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ marginBottom: "10px" }}>
                    <Text style={styles.bold9}>
                      Data descarcarii:{" "}
                      {trip?.unloadingDate
                        ? `${dateFormat(trip.unloadingDate, "dd-mm-yyyy")}`
                        : "-"}
                    </Text>
                  </div>
                  <div
                    style={{
                      maxWidth: "70%",
                    }}
                  >
                    <Text style={styles.bold9}>
                      Conditii de plata: Transfer bancar in{" "}
                      {trip?.carrierPaymentTermInDays
                        ? `${trip?.carrierPaymentTermInDays}`
                        : trip?.carrierPaymentTermInDays === 0
                        ? "0"
                        : "-"}{" "}
                      zile calendaristice de la primirea documentelor.
                    </Text>
                  </div>
                </div>
              </View>
            </div>
            <View>
              <View style={styles.container} wrap={false}>
                <Text style={styles.kmHeader}>Km</Text>
                <Text style={styles.priceNoVatHeader}>
                  Pret unitar fara TVA
                </Text>
              </View>
              {trip?.carrierInvoiceRows &&
                trip?.carrierInvoiceRows.map((carrierInvoiceRow) => {
                  totalPrice =
                    totalPrice +
                    Number(carrierInvoiceRow.distanceKm) *
                      Number(carrierInvoiceRow.value);
                  return (
                    <div style={{ marginTop: "-1px" }}>
                      <View style={styles.container} wrap={false}>
                        <Text style={styles.km}>
                          {carrierInvoiceRow?.distanceKm ?? "-"}
                        </Text>
                        <Text style={styles.priceNoVat}>
                          {Number(carrierInvoiceRow?.value).toFixed(2) ?? "-"}
                        </Text>
                      </View>
                    </div>
                  );
                })}
              {trip?.carrierInvoiceRows && (
                <div style={{ marginTop: "-1px" }}>
                  <View style={styles.container}>
                    <Text
                      style={{
                        width: "50%",
                        borderRightColor: "#848884",
                        borderRightWidth: 1,
                        fontSize: 11,
                        fontFamily: "Times-Bold",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      Total
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        fontSize: 11,
                        fontFamily: "Times-Bold",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      {Number(totalPrice).toFixed(2)}
                    </Text>
                  </View>
                </div>
              )}
            </View>
            <View style={styles.section}>
              <Text style={styles.bold9Red}>
                Conditie obligatorie:{" "}
                {trip?.observations
                  ? `${trip.observations.toUpperCase()}`
                  : "-"}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold7}>Conditii generale:</Text>
              <Text style={styles.regular7}>
                1. Autocamioanele trebuie sa Cunda legilor privind circulatia pe
                drumurile publice si sa posede licentele de transport intern de
                marfuri;
              </Text>
              <Text style={styles.regular7}>
                2. Asigurarea marfii intra exclusiv in sarcina
                transportatorului. Acesta trebuie sa detina: A - o asigurare de
                marfa suplimentara cu o valoare de 50.000 - 100.000 euro, precum
                si B - asigurare CMR obligatorie valabila.
              </Text>
              <Text style={styles.regular7}>
                3. In caz de dauna (distrugere partiala/totala/lipsuri a marfii,
                indiferent de existenta sau inexistenta culpei
                transportatorului), transportatorul va achita catre Efe Impex 72
                SRL atat contravaloarea marfii deteriorate/distruse/lipsa, cat
                si o despagubire in cuantum de 1.000 euro, pentru prejudiciile
                cauzate. Aceste sume (contravaloare marfa deteriorata sau
                lipsa+despagubire) vor fi facturate direct catre transportator,
                cu plata in termen de 5 zile lucratoare de la transmiterea
                facturii prin orice modalitate de comunicare. In caz de neplata,
                se vor percepe penalitati de intarziere in cuantum de 0,6% / zi
                de intarziere, de la scadenta si pana la plata efectiva a
                debitului. Administratorul societatii transportator raspunde in
                solidar cu societatea pentru orice debite restante izvorate din
                prezentul contract.
              </Text>
              <Text style={styles.regular7}>
                4. Transportatorul se obliga sa nu ia legatura direct cu
                clientii pentru care se executa transportul, nici in cazul
                acestui transport si nici in viitor. In caz contrar este pasibil
                de plata unor despagubiri in cuantum de 10.000 de $;
              </Text>
              <Text style={styles.regular7}>
                5. Conducatorul auto este obligat sa asiste la incarcarea si
                descarcarea marfii, sa solicite angajarea pe axe a acesteia si
                sa ancoreze corespunzator produsele, el fiind raspunzator de
                integritatea acestora de la incarcare pana la descarcare;
              </Text>
              <Text style={styles.regular7}>
                6. Comanda este considerata acceptata de transportator chiar
                daca in decurs de 1 ora de la primirea acesteia nu este
                confirmata de firma transportatoare;
              </Text>
              <Text style={styles.regular7}>
                7. Liber la incarcare/descarcare 24/24 ore; accidentul de munca
                intra in sarcina angajatorului;
              </Text>
              <Text style={styles.regular7}>
                8. In cazul in care la descarcare se constata marfa lipsa sau
                deteriorata, factura pentru serviciile prestate de transportator
                va fi achitata dupa solutionarea si clarificarea situatiei, si
                numai dupa indeplinirea din partea transportatorului a
                obligatiilor conform art. 3 de mai sus.
              </Text>
              <Text style={styles.regular7}>
                9. Orice disfunctionalitate in transport (intarzieri la
                incarcare / descarcare, modificare tonaj, schimbarea
                destinatarului) se comunica in scris sau telefonic casei de
                expeditie, coordonatorului de transport spre solutionare;
              </Text>
              <Text style={styles.regular7}>
                10. Toate penalitatiele ce decurg din neindeplinirea sau
                indeplinirea necorespunzatoare a contractului de transport sau
                orice alte costuri si prejudicii suplimentare ce se impun din
                efectuarea transportului vor fi imputate transportatorului si
                prin retinerea sumelor de penalizare din platile datorate de
                beneficiarul Efe Impex 72 SRL pentru serviciile prestate.
                Transportatorul declara ca accepta orice compensare privind
                sumele datorate, recunoscand fara echivoc caracterul cert si
                exigibil al acestor creante si faptul ca toate sumele datorate
                de el devin scadente de la data incalcarii dispozitiilor
                contractuale;
              </Text>
              <Text style={styles.regular7}>
                11. Plata trasportului se va efectua la termenul stabilit in
                prezentul contract, dupa primirea documentelor in original
                semnate si stampilate: CMR, aviz de insotire, bon de paleti sau
                dovada predare paleti, p.v. predare-primire; In lipsa acestor
                documente, factura va primi scadenta de la data primirii actului
                netrimis;
              </Text>
              <Text style={styles.regular7}>
                12. Orice lipsuri din marfa transportata, constatate la
                descarcare, se imputa de drept, automat, transportatorului,
                conform dispozitiilor alin. 2 de mai sus, iar SC EFE IMPEX 72
                SRL este absolvita de orice fel de raspundere. Transportatorul
                isi asuma integral raspunderea pentru transportarea in mod
                corect, complet, la timp si fara deteriorari a marfii, la
                destinatia specificata.
              </Text>
              <Text style={styles.regular7}>
                13. Documentele trebuie trimise in original in termen de maxim
                10 zile de la data descarcarii marfii, astfel termenul de plata
                se va prelungi la 60 de zile de la data sosirii documentelor;
              </Text>
              <Text style={styles.regular7}>
                14. Validitatea prezentei comenzi de transport nu este
                conditionata de existenta semnaturii olografe a reprezentantului
                transportatorului. Comanda de transport este considerata ca
                fiind valabil si expres acceptata prin desfasurarea oricarei
                activitati directe sau asociate serviciilor de transport
                convenite in prezenta comanda, in acest sens fiind considerata
                acceptare inclusiv orice comunicare purtata pe e-mail sau sms,
                cu privire la serviciile convenite.
              </Text>
              <Text style={styles.regular7}>
                15. Toate litigiile ce vor intervenii intre parti si care nu au
                putut fi solutionate pe cale amiabila se vor solutiona de catre
                instanta competenta de la sediul societatii Efe Impex 72 SRL.
              </Text>
            </View>
            <View>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    alignContent: "center",
                    top: "-20px",
                  }}
                >
                  <Text style={styles.bold10}>Beneficiar,</Text>
                  <Text style={styles.bold10}>SC EFE IMPEX 72 SRL</Text>
                </div>
                <Image
                  src={etmsPdfSignature}
                  style={{ width: "100px", height: "73px" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    alignContent: "center",
                    top: "-20px",
                    left: "20px",
                  }}
                >
                  <Text style={styles.bold10}>Transportator,</Text>
                  <Text style={styles.bold10}>
                    {trip?.carrier ? `${trip.carrier.name}` : "-"} (CUI:{" "}
                    {trip?.carrier ? `${trip.carrier.tin}` : "-"})
                  </Text>
                </div>
              </div>
            </View>
          </div>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.boldItalic9}>
              INFORMARE PRIVIND PRELUCAREA DATELOR CU CARACTER PERSONAL
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.regularItalic9}>
              Datele dumneavoastra se afla in baza noastra de date, ca urmare a
              colaborarii pentru prestarea serviciului de transport marfa.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.regularItalic9}>
              Dupa cum bine stiti, din 25 mai 2018 se aplica Regulamentul
              2016/679/UE pentru protectia persoanelor fizice rezidente in UE,
              cu privire la prelucrarea datelor cu caracter personal si libera
              circulatie a acestor date - denumit generic GDPR.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>EFE IMPEX 72 SRL</Text> se
              aliniaza prevederilor GDPR si alegem acest moment sa va transmitem
              informatii privind colaborarea pe viitor, din perspectiva
              prelucrarii datelor.
            </Text>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>
                • Prelucrarea datelor cu caracter personal
              </Text>
              &nbsp; - prin pastrarea datelor dumneavoastra de contact putem
              desfasura in continuarea activitatea de transport marfa in bune
              conditii avand acordul dumneavoastra.
            </Text>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>• Temeiul prelucrarii</Text> -
              prestarea serviciului de transport marfa.
            </Text>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>• Scopul prelucrarii</Text> -
              trimiterea datelor necesare pentru identificarea persoanelor
              implicate in efectuarea curselor.
            </Text>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>• Datele prelucrate</Text> - in
              scopul executarii serviciului de transport marfa vor fi:
            </Text>
            <Text style={styles.regularItalic9}>
              &nbsp;&nbsp;1. datele persoanelor contractante (ale dispecerilor)
              cum ar fi nume, prenume, telefon, adresa de e-mail;
            </Text>
            <Text style={styles.regularItalic9}>
              &nbsp;&nbsp;2. datele soferilor care vor executa cursele cum ar fi
              nume, prenume, serie si numar de buletin;
            </Text>
            <Text style={styles.regularItalic9}>
              &nbsp;&nbsp;3. datele persoanelor auxiliare (sef depozit,
              receptioner, gestionar etc);
            </Text>
            <Text style={styles.regularItalic9}>
              &nbsp;&nbsp;4. datele persoanelor implicate in activitatea
              financiar-contabila cum ar fi nume, prenume si date de contact;
            </Text>
            <Text style={styles.regularItalic9}>
              &nbsp;&nbsp;5. datele persoanelor implicate in activitatea
              manageriala cum ar fi nume, prenume, numar de telefon, adresa de
              e-mail.
            </Text>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>• Perioada prelucrarii</Text> -
              datele vor fi pastrate in baza noastra de date pana la solicitarea
              dumneavoastra, in scris, de a le modifica sau de a le sterge.
              Avand in vedere caracterul sezonier al activitatii de transport
              marfa, datele vor fi pastrate si dupa finalizarea curselor pana la
              solicitarea dumneavoastra de a le elimina din baza de date.
            </Text>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>
                • Drepturile persoanei vizate
              </Text>
              - conform GDPR, in calitate de persoana vizata aveti urmatoarele
              drepturi: dreptul la acces, dreptul la portabilitatea datelor,
              dreptul la opozitie, dreptul la rectificare, dreptul la stergerea
              datelor, dreptul la restrictionarea prelucrarii. De asemenea, este
              recunoscut dreptul de a te adresa autoritatii competente privind
              protectia datelor cu caracter personal si instantelor de judecata
              daca te consideri lezat de prelucrare.
            </Text>
            <Text style={styles.regularItalic9}>
              <Text style={styles.boldItalic9}>• Masuri de securitate</Text> -
              in cadrul EFE IMPEX 72 SRL se aplica politici si standarde pentru
              a pastra siguranta datelor tale. Aceste masuri sunt actualizate
              periodic pentru a corespunde reglementarilor in vigoare. De
              asemenea, luam masuri tehnice si organizatorice adecvate privind
              politici si proceduri, securitatea informatica etc., pentru a
              asigura confidentialitatea si integritatea datelor tale cu
              caracter personal. O garantie suplimentara este data si de
              instruirea angajatilor, precum si obligatia acestora de a pastra
              confidentialitatea si de a nu divulga datele tale cu caracter
              personal in mod nelegal. Poti sa trimiti solicitari pentru a-ti
              exercita drepturile si sa primesti informatii legate de
              prelucrarea datelor cu caracter personal prin e-mail trimis la
              urmatoarea adresa: office@efe.ro.
            </Text>
            <Text style={styles.regularItalic9}></Text>
            <Text style={styles.regularItalic9}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.boldItalic9}>
              Iti multumim pentru increderea acordata,
            </Text>
            <Text style={styles.regularItalic9}>Echipa EFE IMPEX 72 SRL</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
