import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Card,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { carrierRequest } from "../../../api/CarrierRequest";
import { carrierTrailerRequest } from "../../../api/CarrierTrailerRequest";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { styled } from "@mui/material/styles";
import UploadIcon from "@mui/icons-material/Upload";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { fileRequest } from "../../../api/FileRequest";
import { checkCarrierCarTrailerCmrInsuranceDates } from "../../../functions/checkCarrierCarTrailerCmrInsuranceDates";
import { AxiosError } from "axios";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onCarrierTrailerCreated?: AnyFunction;
  onCarrierCreatedIndex?: number;
  carrier?: Carrier | null;
  shouldDisableCarrierSelection?: boolean;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddCarrierTrailerDialog(props: Props) {
  const {
    open,
    onClose,
    onCarrierTrailerCreated,
    onCarrierCreatedIndex,
    carrier,
    shouldDisableCarrierSelection,
  } = props;

  const [formValues, setFormValues] = useState<any>({
    vehicleRegistrationPlate: null,
    carrierId: carrier ? carrier.id : null,
    cmrInsuranceIdentifier: null,
    cmrInsuranceStartDate: null,
    cmrInsuranceEndDate: null,
    cmrInsuranceDocumentId: null,
  });

  const [carrierValue, setCarrierValue] = useState<Carrier | null>(
    carrier ? carrier : null
  );

  const [error, setError] = useState({
    vehicleRegistrationPlate: false,
    carrierId: false,
    cmrInsuranceIdentifier: false,
    cmrInsuranceStartDate: false,
    cmrInsuranceEndDate: false,
    cmrInsuranceDocumentId: false,
  });

  const handleErrors = () => {
    if (!formValues.vehicleRegistrationPlate) {
      setError((prev: any) => ({
        ...prev,
        vehicleRegistrationPlate: true,
      }));
    } else if (formValues.vehicleRegistrationPlate) {
      setError((prev: any) => ({
        ...prev,
        vehicleRegistrationPlate: false,
      }));
    }
    if (!formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: true,
      }));
    } else if (formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: false,
      }));
    }
    // if (!formValues.cmrInsuranceIdentifier) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceIdentifier: true,
    //   }));
    // } else if (formValues.cmrInsuranceIdentifier) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceIdentifier: false,
    //   }));
    // }
    // if (!formValues.cmrInsuranceStartDate) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceStartDate: true,
    //   }));
    // } else if (formValues.cmrInsuranceStartDate) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceStartDate: false,
    //   }));
    // }
    // if (!formValues.cmrInsuranceEndDate) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceEndDate: true,
    //   }));
    // } else if (formValues.cmrInsuranceEndDate) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceEndDate: false,
    //   }));
    // }
    // if (!inputFile) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceDocumentId: true,
    //   }));
    // } else if (inputFile) {
    //   setError((prev: any) => ({
    //     ...prev,
    //     cmrInsuranceDocumentId: false,
    //   }));
    // }
  };

  const handleSubmit = () => {
    handleErrors();
    const formIsValid =
      formValues.vehicleRegistrationPlate && formValues.carrierId;
    // formValues.cmrInsuranceIdentifier &&
    // formValues.cmrInsuranceStartDate &&
    // formValues.cmrInsuranceEndDate &&
    // inputFile;

    if (formIsValid) {
      const doRequest = async () => {
        let uploadedFile: any;
        if (inputFile) {
          uploadedFile = await fileRequest.upload(inputFile);
        }

        return carrierTrailerRequest
          .post({
            ...formValues,
            cmrInsuranceDocumentId: uploadedFile ? uploadedFile.id : null,
          })
          .then((createdCarrierTrailer) => {
            setCarrierValue(null);
            setInputFile(null);
            setFormValues(() => ({
              vehicleRegistrationPlate: null,
              carrierId: null,
              cmrInsuranceIdentifier: null,
              cmrInsuranceStartDate: null,
              cmrInsuranceEndDate: null,
              cmrInsuranceDocumentId: null,
            }));
            if (typeof onCarrierTrailerCreated === "function") {
              onCarrierTrailerCreated(createdCarrierTrailer);
            }
          })
          .catch((e) => {
            let message = "S-a produs o eroare!";
            if (e instanceof AxiosError && e.response?.data?.code === 1000) {
              message =
                "Număr de înmatriculare este deja existent pentru acest transportator!";
            }
            alert(message);
            throw e;
          });
      };

      return doRequest();
    }
  };

  const handleCloseAddCarrierTrailerDialog = () => {
    onClose();
    setFormValues(() => ({
      vehicleRegistrationPlate: null,
      carrierId: null,
      cmrInsuranceIdentifier: null,
      cmrInsuranceStartDate: null,
      cmrInsuranceEndDate: null,
      cmrInsuranceDocumentId: null,
    }));
    setCarrierValue(null);
    setInputFile(null);
    setError(() => ({
      vehicleRegistrationPlate: false,
      carrierId: false,
      cmrInsuranceIdentifier: false,
      cmrInsuranceStartDate: false,
      cmrInsuranceEndDate: false,
      cmrInsuranceDocumentId: false,
    }));
  };

  const [inputFile, setInputFile] = useState<File | null>(null);
  const [carrierOptions, setCarrierOptions] = useState<Carrier[]>([]);
  const fetchCarriers = () => {
    carrierRequest.findAll().then((data) => {
      setCarrierOptions(
        data.results.map((carrier: Carrier) => ({
          id: carrier.id,
          name: carrier.name,
          tin: carrier.tin,
          city: carrier.city,
          address: carrier.address,
          phone: carrier.phone,
          email: carrier.email,
          contactPerson: carrier.contactPerson,
          isActive: carrier.isActive,
        }))
      );
    });
  };

  // useEffect(() => {
  //   fetchCarriers();
  // }, []);

  useEffect(() => {
    // if (onCarrierCreatedIndex > 0) {
    fetchCarriers();
    // }
  }, [onCarrierCreatedIndex]);

  const sortedCarrierOptions = carrierOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Adăugare remorcă</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton
              onClick={handleCloseAddCarrierTrailerDialog}
              tabIndex={-1}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Număr înmatriculare"
                error={error.vehicleRegistrationPlate}
                helperText={
                  error.vehicleRegistrationPlate && "Câmp obligatoriu!"
                }
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    vehicleRegistrationPlate: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseAddCarrierTrailerDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={carrierValue}
                onChange={(event, newValue) => {
                  setCarrierValue(newValue);
                  setFormValues((prev: any) => ({
                    ...prev,
                    carrierId: newValue?.id,
                  }));
                }}
                disabled={Boolean(shouldDisableCarrierSelection)}
                options={sortedCarrierOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      required
                      {...params}
                      size="small"
                      fullWidth
                      label="Transportator"
                      error={error.carrierId}
                      helperText={error.carrierId && "Câmp obligatoriu!"}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit()?.then(
                            handleCloseAddCarrierTrailerDialog
                          );
                        }
                      }}
                    />
                  </form>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Serie asigurare CMR"
                error={error.cmrInsuranceIdentifier}
                helperText={error.cmrInsuranceIdentifier && "Câmp obligatoriu!"}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    cmrInsuranceIdentifier: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseAddCarrierTrailerDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data început asigurare CMR"
                  value={formValues.cmrInsuranceStartDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setFormValues((prev: any) => ({
                      ...prev,
                      cmrInsuranceStartDate: newValue,
                      ...(!checkCarrierCarTrailerCmrInsuranceDates(
                        newValue,
                        prev.cmrInsuranceEndDate
                      ).isValid
                        ? { cmrInsuranceEndDate: null }
                        : {}),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      error={error.cmrInsuranceStartDate}
                      helperText={
                        error.cmrInsuranceStartDate && "Câmp obligatoriu!"
                      }
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "zz/ll/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data sfârșit asigurare CMR"
                  value={formValues.cmrInsuranceEndDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setFormValues((prev: any) => ({
                      ...prev,
                      cmrInsuranceEndDate: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      error={error.cmrInsuranceEndDate}
                      helperText={
                        error.cmrInsuranceEndDate && "Câmp obligatoriu!"
                      }
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "zz/ll/aaaa",
                      }}
                    />
                  )}
                  disabled={!formValues.cmrInsuranceStartDate}
                  minDate={formValues.cmrInsuranceStartDate}
                  maxDate={
                    checkCarrierCarTrailerCmrInsuranceDates(
                      formValues.cmrInsuranceStartDate,
                      formValues.cmrInsuranceEndDate
                    ).maxEndDate
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<UploadIcon />}
                color="warning"
              >
                Încarcă document asigurare CMR
                <VisuallyHiddenInput
                  type="file"
                  onChange={(event) =>
                    setInputFile(event.target.files?.[0] || null)
                  }
                />
              </Button>
              {error.cmrInsuranceDocumentId && (
                <FormHelperText error style={{ marginLeft: 14 }}>
                  Câmp obligatoriu!
                </FormHelperText>
              )}
              {inputFile && (
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 1,
                    mb: 1,
                    p: 1,
                    border: "1px solid #ccc",
                    borderRadius: 1,
                    cursor: "pointer",
                    ":hover": { border: "1px solid #000" },
                  }}
                  onClick={() => setInputFile(null)}
                >
                  <InsertDriveFile />
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {inputFile.name}
                  </div>
                  <ClearIcon sx={{ ml: "auto" }} />
                </Card>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()?.then(handleCloseAddCarrierTrailerDialog);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseAddCarrierTrailerDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
