const ERROR = {
  CMR: {
    INSURANCE_EXPIRED: "Asigurare CMR expirată!",
    INSURANCE_NEEDS_CHECK: "Asigurare CMR neverificată de 90 de zile!",
  },
};

export function getErrorForCarrierCarOrTrailer(
  carrierCarOrTrailer?: CarrierCar | CarrierTrailer | null
): { error: boolean; message: string } {
  if (!carrierCarOrTrailer) {
    return {
      error: false,
      message: "",
    };
  }

  if (!carrierCarOrTrailer.cmrInsuranceEndDate) {
    return {
      error: true,
      message: ERROR.CMR.INSURANCE_EXPIRED,
    };
  }

  if (
    new Date(carrierCarOrTrailer.cmrInsuranceEndDate).getTime() <
    new Date(new Date().toISOString().split("T")[0]).getTime()
  ) {
    return {
      error: true,
      message: ERROR.CMR.INSURANCE_EXPIRED,
    };
  }

  if (carrierCarOrTrailer.cmrInsuranceNeedsCheck) {
    return {
      error: true,
      message: ERROR.CMR.INSURANCE_NEEDS_CHECK,
    };
  }

  return {
    error: false,
    message: "",
  };
}
