import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  darken,
  Grid,
  lighten,
  Stack,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { dataGridRoTextConstants } from "../../../constants/dataGridRoTextConstants";
import DeleteRowDialog from "../../dialogs/DeleteRowDialog";
import AddCarrierCarDialog from "../../dialogs/carrier/AddCarrierCarDialog";
import EditCarrierCarDialog from "../../dialogs/carrier/EditCarrierCarDialog";
import {
  getCarrier,
  getCmrInsuranceEndDate,
  getCmrInsuranceIdentifier,
  getCmrInsuranceStartDate,
  getVehicleRegistrationPlate,
} from "../../../functions/valueGetterFunctions/carrierCarGetterFunctions";
import { carrierCarRequest } from "../../../api/CarrierCarRequest";
import { fileRequest } from "../../../api/FileRequest";
import { Download } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { isDispatcherSelector } from "../../../redux/slice/selectors";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function CarrierCarsDataGrid() {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, flex: 1, hide: true },
    {
      field: "vehicleRegistrationPlate",
      headerName: "Număr înmatriculare",
      width: 150,
      flex: 1,
      valueGetter: getVehicleRegistrationPlate,
    },
    {
      field: "carrier",
      headerName: "Transportator",
      width: 150,
      flex: 1,
      valueGetter: getCarrier,
    },
    {
      field: "cmrInsuranceIdentifier",
      headerName: "Seria asigurare CMR",
      width: 150,
      flex: 1,
      valueGetter: getCmrInsuranceIdentifier,
    },
    {
      field: "cmrInsuranceStartDate",
      headerName: "Data început asigurare CMR",
      width: 150,
      flex: 1,
      valueGetter: getCmrInsuranceStartDate,
    },
    {
      field: "cmrInsuranceEndDate",
      headerName: "Data sfârșit asigurare CMR",
      width: 150,
      flex: 1,
      valueGetter: getCmrInsuranceEndDate,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 120,
      maxWidth: 120,
      filterable: false,
      renderCell: ({ row: carrierCar }) => {
        const isDispatcher = useSelector(isDispatcherSelector);

        const options = [
          <Tooltip title="Descarcă asigurare CMR" placement="bottom">
            <IconButton
              onClick={() => {
                fileRequest.download(
                  carrierCar.cmrInsuranceDocument?.id,
                  carrierCar.cmrInsuranceDocument?.shortName
                );
              }}
              color="primary"
              disabled={!carrierCar.cmrInsuranceDocument?.id}
            >
              <Download fontSize="small" />
            </IconButton>
          </Tooltip>,
        ];

        if (!isDispatcher) {
          options.push(
            ...[
              <IconButton>
                <EditIcon
                  fontSize="small"
                  color="warning"
                  onClick={() => {
                    handleOpenEditCarrierCarDialog(carrierCar);
                  }}
                />
              </IconButton>,
              <IconButton>
                <DeleteIcon
                  fontSize="small"
                  color="error"
                  onClick={() => handleOpenDeleteRowDialog(carrierCar.id)}
                />
              </IconButton>,
            ]
          );
        }

        return options;
      },
    },
  ];

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [rows, setRows] = useState<CarrierCar[]>([]);
  const isDispatcher = useSelector(isDispatcherSelector);

  const fetchCarrierCars = () => {
    carrierCarRequest.findAll().then((data) => {
      setRows(
        data.results.map((carrierCar: CarrierCar) => ({
          id: carrierCar.id,
          vehicleRegistrationPlate: carrierCar.vehicleRegistrationPlate,
          carrier: carrierCar.carrier,
          cmrInsuranceIdentifier: carrierCar.cmrInsuranceIdentifier,
          cmrInsuranceStartDate: carrierCar.cmrInsuranceStartDate,
          cmrInsuranceEndDate: carrierCar.cmrInsuranceEndDate,
          cmrInsuranceDocumentId: carrierCar.cmrInsuranceDocumentId,
          cmrInsuranceDocument: carrierCar.cmrInsuranceDocument,
        }))
      );
    });
  };

  useEffect(() => {
    fetchCarrierCars();
  }, []);

  const [selectedCarrierCarForEdit, setSelectedCarrierCarForEdit] = useState<
    CarrierCar | undefined
  >(undefined);

  const [openAddCarrierCarDialog, setOpenAddCarrierCarDialog] =
    React.useState(false);
  const handleOpenAddCarrierCarDialog = () => {
    setOpenAddCarrierCarDialog(true);
  };

  const [openEditCarrierCarDialog, setOpenEditCarrierCarDialog] =
    React.useState(false);
  const handleOpenEditCarrierCarDialog = (carrierCar: CarrierCar) => {
    setOpenEditCarrierCarDialog(true);
    setSelectedCarrierCarForEdit(carrierCar);
  };

  const [toBeDeleted, setToBeDeleted] = React.useState<number | null>(null);
  const handleOpenDeleteRowDialog = (id: any) => {
    setToBeDeleted(id);
  };
  const handleCloseDeleteRowDialog = () => {
    setToBeDeleted(null);
  };
  const openDeleteRowDialog = Boolean(toBeDeleted);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              {!isDispatcher && (
                <Grid item>
                  <Button size="small" onClick={handleOpenAddCarrierCarDialog}>
                    <Stack direction="row">
                      <AddIcon
                        fontSize="small"
                        sx={{
                          mr: 1,
                        }}
                      />
                      Adaugă mașină
                    </Stack>
                  </Button>
                </Grid>
              )}

              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarFilterButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, []);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .super-app-theme--Open": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Filled": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--PartiallyFilled": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Rejected": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode
              ),
          },
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={1}
      >
        <Grid item xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  rows={rows}
                  getRowId={(row) => row.id}
                  columns={columns}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  autoHeight
                  pagination
                  disableSelectionOnClick
                  checkboxSelection={false}
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={dataGridRoTextConstants}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: "Număr de înregistrări pe pagină",
                    },
                    panel: {
                      anchorEl: filterButtonEl,
                      placement: "bottom-end",
                    },
                  }}
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.status}`
                  }
                  sx={{
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                      {
                        outline: "none",
                      },

                    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                      {
                        outline: "none",
                      },
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {openAddCarrierCarDialog && (
        <AddCarrierCarDialog
          open={openAddCarrierCarDialog}
          onCarrierCarCreated={fetchCarrierCars}
          onClose={() => {
            setOpenAddCarrierCarDialog(false);
          }}
        />
      )}
      {openEditCarrierCarDialog && (
        <EditCarrierCarDialog
          open={openEditCarrierCarDialog}
          carrierCar={selectedCarrierCarForEdit}
          onCarrierCarEdited={fetchCarrierCars}
          onClose={() => {
            setOpenEditCarrierCarDialog(false);
            setSelectedCarrierCarForEdit(undefined);
          }}
        />
      )}
      {openDeleteRowDialog && (
        <DeleteRowDialog
          open={openDeleteRowDialog}
          onClose={handleCloseDeleteRowDialog}
          onDelete={() => {
            if (toBeDeleted) {
              carrierCarRequest
                .delete(toBeDeleted)
                .then(fetchCarrierCars)
                .catch(() =>
                  alert(
                    "S-a produs o eroare! Este posibil ca înregistrarea aleasă să fie asociată cu alte tipuri de înregistrări și nu este permisă ștergerea!"
                  )
                );
            }
          }}
        />
      )}
    </Box>
  );
}
