import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import React from "react";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ClearIcon from "@mui/icons-material/Clear";
import { dataGridRoTextConstants } from "../../constants/dataGridRoTextConstants";
import ConfirmPaymentDialog from "../dialogs/ConfirmPaymentDialog";
import {
  getCargoType,
  getCarrier,
  getCarrierCar,
  getCarrierDriver,
  getCarrierInvoice,
  getComments,
  getDate,
  getDispatcher,
  getLoadingAddress,
  getLoadingCity,
  getLoadingDate,
  getObservations,
  getShipperPaymentTermInDays,
  getCarrierPaymentTermInDays,
  getShipper,
  getShipperBranch,
  getShipperInvoice,
  getShipperReferenceId,
  getStatus,
  getUnloadingAddress,
  getUnloadingCity,
  getUnloadingDate,
} from "../../functions/valueGetterFunctions/tripValueGetterFunctions";
import { tripRequest } from "../../api/TripRequest";
import { useNavigate } from "react-router-dom";
import { TitleContext } from "../../contexts/TitleProviderContext";
import IconButton from "@mui/material/IconButton";
import PATH from "../../paths";
import VisibilityIcon from "@mui/icons-material/Visibility";
import blockInvalidCharNumberInput from "../../functions/blockInvalidCharNumberInput";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const NumberField = styled(TextField)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export default function ConfirmPaymentDataGrid() {
  const navigate = useNavigate();
  const { setTitle } = useContext(TitleContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [searchText, setSearchText] = React.useState("");
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [maxDaysUntilDueDate, setMaxDaysUntilDueDate] = React.useState<
    number | undefined
  >(undefined);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [rows, setRows] = useState<Trip[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [maxDaysUntilDueDateValue, setMaxDaysUntilDueDateValue] = useState("");
  const handleClearClick = () => {
    setMaxDaysUntilDueDateValue("");
    setMaxDaysUntilDueDate(undefined);
    setSelectionModel([]);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Număr", width: 90, flex: 1 },
    {
      field: "date",
      headerName: "Dată",
      width: 150,
      flex: 1,
      valueGetter: getDate,
    },
    {
      field: "loadingDate",
      headerName: "Dată încărcare",
      width: 160,
      flex: 1,
      valueGetter: getLoadingDate,
      hide: true,
    },
    {
      field: "unloadingDate",
      headerName: "Dată descărcare",
      width: 160,
      flex: 1,
      valueGetter: getUnloadingDate,
      hide: true,
    },
    {
      field: "loadingAddress",
      headerName: "Plecare",
      width: 160,
      flex: 1,
      valueGetter: getLoadingAddress,
    },
    {
      field: "unloadingAddress",
      headerName: "Sosire",
      width: 160,
      flex: 1,
      valueGetter: getUnloadingAddress,
    },
    {
      field: "loadingCity",
      headerName: "Localitate încărcare",
      width: 160,
      flex: 1,
      valueGetter: getLoadingCity,
      hide: true,
    },
    {
      field: "unloadingCity",
      headerName: "Localitate descărcare",
      width: 160,
      flex: 1,
      valueGetter: getUnloadingCity,
      hide: true,
    },
    {
      field: "shipper",
      headerName: "Beneficiar",
      width: 160,
      flex: 1,
      valueGetter: getShipper,
      hide: true,
    },
    {
      field: "shipperReferenceId",
      headerName: "Ref.",
      width: 160,
      flex: 1,
      valueGetter: getShipperReferenceId,
      hide: true,
    },
    {
      field: "shipperBranch",
      headerName: "Punct de lucru beneficiar",
      width: 160,
      flex: 1,
      valueGetter: getShipperBranch,
      hide: true,
    },
    {
      field: "shipperInvoice",
      headerName: "Fact. ben.",
      width: 160,
      flex: 1.5,
      valueGetter: getShipperInvoice,
      hide: true,
    },
    {
      field: "shipperPaymentTermInDays",
      headerName: "Termen beneficiar",
      width: 160,
      flex: 0.7,
      valueGetter: getShipperPaymentTermInDays,
      hide: true,
    },
    {
      field: "carrier",
      headerName: "Transportator",
      width: 160,
      flex: 1.1,
      valueGetter: getCarrier,
    },
    {
      field: "carrierCar",
      headerName: "Mașină",
      width: 160,
      flex: 1,
      valueGetter: getCarrierCar,
    },
    {
      field: "carrierDriver",
      headerName: "Șofer",
      width: 160,
      flex: 1,
      valueGetter: getCarrierDriver,
    },
    {
      field: "carrierInvoice",
      headerName: "Fact. trans.",
      width: 160,
      flex: 1.7,
      valueGetter: getCarrierInvoice,
    },
    {
      field: "carrierPaymentTermInDays",
      headerName: "T.t.",
      width: 160,
      flex: 1.7,
      valueGetter: getCarrierPaymentTermInDays,
    },
    {
      field: "computedStatus",
      headerName: "Status",
      width: 160,
      flex: 1,
      hide: true,
      valueGetter: getStatus,
    },
    {
      field: "cargoType",
      headerName: "Tip marfă",
      width: 160,
      flex: 1,
      valueGetter: getCargoType,
      hide: true,
    },
    {
      field: "dispatcher",
      headerName: "Dispecer",
      width: 160,
      flex: 1,
      valueGetter: getDispatcher,
      hide: true,
    },
    {
      field: "observations",
      headerName: "Observații",
      width: 160,
      flex: 1,
      valueGetter: getObservations,
      hide: true,
    },
    {
      field: "comments",
      headerName: "Comentarii",
      width: 160,
      flex: 1,
      valueGetter: getComments,
      hide: true,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 60,
      maxWidth: 60,
      filterable: false,
      renderCell: ({ row: trip }) => {
        return [
          <>
            <Tooltip title="Vizualizează" placement="bottom">
              <IconButton
                onClick={() => {
                  navigate(PATH.TRIP.VIEW.replace(":id", trip.id.toString()));
                  setTitle("Detalii comandă");
                }}
                color="primary"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>,
        ];
      },
    },
  ];

  const fetchTrips = () => {
    setPageIsLoading(true);
    tripRequest
      .searchConfirmPayable(
        pageSize,
        page * pageSize,
        searchText,
        maxDaysUntilDueDate
      )
      .then((data) => {
        setRows(
          data.results.map((trip: Trip) => ({
            id: trip.id,
            date: trip.date,
            cargoType: trip.cargoType,
            dispatcher: trip.dispatcher,
            loadingDate: trip.loadingDate,
            loadingAddress: trip.loadingAddress,
            loadingCity: trip.loadingCity,
            unloadingDate: trip.unloadingDate,
            unloadingAddress: trip.unloadingAddress,
            unloadingCity: trip.unloadingCity,
            carrier: trip.carrier,
            carrierCar: trip.carrierCar,
            carrierTrailer: trip.carrierTrailer,
            carrierDriver: trip.carrierDriver,
            carrierInvoice: trip.carrierInvoice,
            carrierInvoiceRows: trip.carrierInvoiceRows,
            carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
            shipper: trip.shipper,
            shipperBranch: trip.shipperBranch,
            shipperReferenceId: trip.shipperReferenceId,
            shipperInvoice: trip.shipperInvoice,
            shipperInvoiceRows: trip.shipperInvoiceRows,
            shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
            observations: trip.observations,
            comments: trip.comments,
            computedStatus: trip.computedStatus,
            status: trip.status,
          }))
        );
        setRowCount(data.total);
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTrips();
  }, [pageSize, page, searchText, maxDaysUntilDueDate]);

  const [
    selectedTripsForPaymentConfirmation,
    setSelectedTripsForPaymentConfirmation,
  ] = useState<Trip[]>([]);

  const [openConfirmPaymentDialog, setOpenConfirmPaymentDialog] =
    React.useState(false);
  const handleOpenConfirmPaymentDialog = () => {
    setOpenConfirmPaymentDialog(true);
  };

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              {/*<Grid item>*/}
              {/*  <Button*/}
              {/*    size="small"*/}
              {/*    disabled={!selectedRows.length}*/}
              {/*    onClick={handleOpenConfirmPaymentDialog}*/}
              {/*    variant="outlined"*/}
              {/*  >*/}
              {/*    <Stack direction="row">*/}
              {/*      <PriceCheckIcon*/}
              {/*        fontSize="small"*/}
              {/*        sx={{*/}
              {/*          mr: 1,*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      Confirmă plată*/}
              {/*    </Stack>*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, [selectedTripsForPaymentConfirmation]);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button
            size="small"
            disabled={!selectedTripsForPaymentConfirmation.length}
            onClick={handleOpenConfirmPaymentDialog}
            variant="contained"
          >
            <Stack
              direction="row"
              sx={{
                mt: 0.4,
              }}
            >
              <PriceCheckIcon
                fontSize="small"
                sx={{
                  mr: 0.5,
                }}
              />
              Confirmă plată
            </Stack>
          </Button>
        </Grid>
        <Grid item>
          <NumberField
            size="small"
            fullWidth
            value={maxDaysUntilDueDateValue}
            onChange={(newValue) => {
              setMaxDaysUntilDueDate(+newValue.target.value);
              setMaxDaysUntilDueDateValue(newValue.target.value);
              setSelectionModel([]);
            }}
            label="Scadență factură"
            type="number"
            onKeyDown={blockInvalidCharNumberInput}
            inputProps={{ min: 0 }}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    size="small"
                    sx={{
                      visibility:
                        maxDaysUntilDueDate !== undefined
                          ? "visible"
                          : "hidden",
                      marginLeft: 1,
                    }}
                    onClick={handleClearClick}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                  <InputAdornment position="end">zile</InputAdornment>
                </>
              ),
            }}
          ></NumberField>
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: 3 }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      filterMode="server"
                      paginationMode="server"
                      loading={pageIsLoading}
                      onFilterModelChange={(filterModel: GridFilterModel) => {
                        setSearchText(
                          filterModel.quickFilterValues?.join(" ") || ""
                        );
                      }}
                      onPageChange={(page: number) => {
                        setPage(page);
                      }}
                      rowCount={rowCount}
                      rows={rows}
                      getRowId={(row) => row.id}
                      columns={columns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "id", sort: "desc" }],
                        },
                      }}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      autoHeight
                      pagination
                      keepNonExistentRowsSelected
                      disableSelectionOnClick
                      checkboxSelection={true}
                      selectionModel={selectionModel}
                      onSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                        const selectedIDs = new Set(ids);
                        const currentPageRows = rows.filter((row) =>
                          selectedIDs.has(row.id)
                        );
                        setSelectedTripsForPaymentConfirmation((prev) => {
                          const prevWithNewRows = [...prev];
                          currentPageRows.forEach((currentPageRow) => {
                            if (
                              !prevWithNewRows.find(
                                (row) => row.id === currentPageRow.id
                              )
                            ) {
                              prevWithNewRows.push(currentPageRow);
                            }
                          });
                          return prevWithNewRows.filter((prevSelectedTrip) => {
                            return selectedIDs.has(prevSelectedTrip.id);
                          });
                        });
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                      localeText={dataGridRoTextConstants}
                      components={{ Toolbar: CustomToolbar }}
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: "Număr de înregistrări pe pagină",
                        },
                        panel: {
                          anchorEl: filterButtonEl,
                          placement: "bottom-end",
                        },
                      }}
                      sx={{
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openConfirmPaymentDialog && (
        <ConfirmPaymentDialog
          selectedTrips={selectedTripsForPaymentConfirmation}
          open={openConfirmPaymentDialog}
          onTripsPaymentConfirmed={fetchTrips}
          onClose={() => setOpenConfirmPaymentDialog(false)}
        />
      )}
    </Box>
  );
}
