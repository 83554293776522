import { GridValueGetterParams } from "@mui/x-data-grid";

export function getVehicleRegistrationPlate(params: GridValueGetterParams) {
  return `${params.row.vehicleRegistrationPlate || "-"}`;
}

export function getCarrier(params: GridValueGetterParams) {
  return `${params.row.carrier?.name || "-"}`;
}

export function getCmrInsuranceIdentifier(params: GridValueGetterParams) {
  return `${params.row.cmrInsuranceIdentifier || "-"}`;
}

export function getCmrInsuranceStartDate(params: GridValueGetterParams) {
  return `${params.row.cmrInsuranceStartDate || "-"}`;
}

export function getCmrInsuranceEndDate(params: GridValueGetterParams) {
  return `${params.row.cmrInsuranceEndDate || "-"}`;
}
