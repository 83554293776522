import { GridRenderCellParams } from "@mui/x-data-grid";
import dateFormat from "dateformat";

export function getDate(params: GridRenderCellParams) {
  return `${dateFormat(params.row.date, "dd/mm/yyyy") || "-"}`;
}

export function getLoadingDate(params: GridRenderCellParams) {
  return `${dateFormat(params.row.loadingDate, "dd/mm/yyyy") || "-"}`;
}

export function getUnloadingDate(params: GridRenderCellParams) {
  return `${dateFormat(params.row.unloadingDate, "dd/mm/yyyy") || "-"}`;
}

export function getLoadingAddress(params: GridRenderCellParams) {
  return `${params.row.loadingAddress || "-"}`;
}

export function getUnloadingAddress(params: GridRenderCellParams) {
  return `${params.row.unloadingAddress || "-"}`;
}

export function getShipper(params: GridRenderCellParams) {
  return `${params.row.shipper?.name || "-"}`;
}

export function getShipperReferenceId(params: GridRenderCellParams) {
  return `${params.row.shipperReferenceId || "-"}`;
}

export function getShipperBranch(params: GridRenderCellParams) {
  return `${params.row.shipperBranch?.name || "-"}`;
}

export function getShipperInvoice(
  params: GridRenderCellParams,
  isShort = true
) {
  const longVersion = `${params.row.shipperInvoice?.invoiceSeries?.name}${params.row.shipperInvoice?.number}`;
  const shortVersion =
    `${params.row.shipperInvoice?.invoiceSeries?.name}${params.row.shipperInvoice?.number}`
      .length > 6
      ? `${params.row.shipperInvoice?.invoiceSeries?.name}${params.row.shipperInvoice?.number}`
          .substring(0, 6)
          .concat("...")
      : `${params.row.shipperInvoice?.invoiceSeries?.name}${params.row.shipperInvoice?.number}`;

  return params.row.shipperInvoice
    ? isShort
      ? shortVersion
      : longVersion
    : "-";
}

export function getCarrier(params: GridRenderCellParams) {
  return `${params.row.carrier?.name || "-"}`;
}

export function getCarrierDriver(params: GridRenderCellParams) {
  return `${params.row.carrierDriver?.name || "-"}`;
}

export function getCarrierCar(params: GridRenderCellParams) {
  return `${params.row.carrierCar?.vehicleRegistrationPlate || "-"}`;
}

export function getCarrierTrailer(params: GridRenderCellParams) {
  return `${params.row.carrierTrailer?.vehicleRegistrationPlate || "-"}`;
}

export function getCarrierInvoice(
  params: GridRenderCellParams,
  isShort = true
) {
  const longVersion = `${params.row.carrierInvoice?.invoiceData}`;
  const shortVersion =
    params.row.carrierInvoice?.invoiceData.length > 6
      ? `${params.row.carrierInvoice?.invoiceData}`
          .substring(0, 6)
          .concat("...")
      : `${params.row.carrierInvoice?.invoiceData}`;

  return params.row.carrierInvoice
    ? isShort
      ? shortVersion
      : longVersion
    : "-";
}

export function getLoadingCity(params: GridRenderCellParams) {
  return params.row.loadingCity
    ? `${params.row.loadingCity?.name} (${params.row.loadingCity?.county?.name})`
    : "-";
}

export function getUnloadingCity(params: GridRenderCellParams) {
  return params.row.unloadingCity
    ? `${params.row.unloadingCity?.name} (${params.row.unloadingCity?.county?.name})`
    : "-";
}

export function getCargoType(params: GridRenderCellParams) {
  return `${params.row.cargoType?.name || "-"}`;
}

export function getCarrierPaymentTermInDays(params: GridRenderCellParams) {
  return params.row.carrierPaymentTermInDays === 0
    ? "0"
    : `${params.row.carrierPaymentTermInDays || "-"}`;
}

export function getShipperPaymentTermInDays(params: GridRenderCellParams) {
  return params.row.shipperPaymentTermInDays === 0
    ? "0"
    : `${params.row.shipperPaymentTermInDays || "-"}`;
}

export function getStatus(params: GridRenderCellParams) {
  if (params.row.computedStatus === "Inserted") {
    return "Introdusă";
  } else if (params.row.computedStatus === "Completed") {
    return "Finalizată";
  } else if (params.row.computedStatus === "Confirmed") {
    return "Operată";
  } else if (params.row.computedStatus === "Billed") {
    return "Facturată";
  } else if (params.row.computedStatus === "Paid") {
    return "Plătită";
  } else if (params.row.computedStatus === "Blocked") {
    return "Blocată";
  } else return "-";
}

export function getDispatcher(params: GridRenderCellParams) {
  return params.row.dispatcher
    ? `${params.row.dispatcher?.lastName} ${params.row.dispatcher?.firstName}`
    : "-";
}

export function getObservations(params: GridRenderCellParams) {
  return `${params.row.observations || "-"}`;
}

export function getComments(params: GridRenderCellParams) {
  return `${params.row.comments || "-"}`;
}
