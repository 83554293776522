import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TripsShippersReportPDFDialog from "../dialogs/PDF/TripsShippersReportPDFDialog";
import CircularProgress from "@mui/material/CircularProgress";

import { countryRequest } from "../../api/CountryRequest";
import { carrierRequest } from "../../api/CarrierRequest";
import { carrierCarRequest } from "../../api/CarrierCarRequest";
import { carrierTrailerRequest } from "../../api/CarrierTrailerRequest";
import { carrierDriverRequest } from "../../api/CarrierDriverRequest";
import { shipperRequest } from "../../api/ShipperRequest";
import { reportRequest } from "../../api/ReportRequest";
import { useSkipFirstRun } from "../../helpers/hooks";

interface CountryValueProps {
  country: Country | null;
  county: County | null;
  city: City | null;
}

export default function TripsShippersReportForm() {
  const [reportValues, setReportValues] = useState<any>({
    startDate: null,
    endDate: null,
    shipperId: null,
    carrierId: null,
    carrierCarId: null,
    carrierTrailerId: null,
    carrierDriverId: null,
    loadingCountryId: null,
    loadingCountyId: null,
    loadingCityId: null,
    unloadingCountryId: null,
    unloadingCountyId: null,
    unloadingCityId: null,
    shipperTripType: null,
    carrierTripType: null,
  });

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
    shipperTripType: false,
    carrierTripType: false,
  });

  const handleErrors = () => {
    if (!reportValues.startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: true,
      }));
    } else if (reportValues.startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: false,
      }));
    }
    if (!reportValues.endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: true,
      }));
    } else if (reportValues.endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: false,
      }));
    }
    if (!reportValues.shipperTripType) {
      setError((prev: any) => ({
        ...prev,
        shipperTripType: true,
      }));
    } else if (reportValues.shipperTripType) {
      setError((prev: any) => ({
        ...prev,
        shipperTripType: false,
      }));
    }
    if (!reportValues.carrierTripType) {
      setError((prev: any) => ({
        ...prev,
        carrierTripType: true,
      }));
    } else if (reportValues.carrierTripType) {
      setError((prev: any) => ({
        ...prev,
        carrierTripType: false,
      }));
    }
  };

  const [carrierValue, setCarrierValue] = useState<Carrier | null>(null);
  const [carrierCarValue, setCarrierCarValue] = useState<CarrierCar | null>(
    null
  );
  const [carrierTrailerValue, setCarrierTrailerValue] =
    useState<CarrierTrailer | null>(null);
  const [carrierDriverValue, setCarrierDriverValue] =
    useState<CarrierDriver | null>(null);
  const [shipperValue, setShipperValue] = useState<Shipper | null>(null);

  const [selectedLoadingCountryId, setSelectedLoadingCountryId] =
    useState<any>(null);
  const [selectedLoadingCountyId, setSelectedLoadingCountyId] =
    useState<any>(null);
  const [selectedUnloadingCountryId, setSelectedUnloadingCountryId] =
    useState<any>(null);
  const [selectedUnloadingCountyId, setSelectedUnloadingCountyId] =
    useState<any>(null);
  const [selectedCarrierId, setSelectedCarrierId] = useState<any>(null);

  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const [loadingCountyOptions, setLoadingCountyOptions] = useState<County[]>(
    []
  );
  const [loadingCityOptions, setLoadingCityOptions] = useState<City[]>([]);
  const [unloadingCountyOptions, setUnloadingCountyOptions] = useState<
    County[]
  >([]);
  const [unloadingCityOptions, setUnloadingCityOptions] = useState<City[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<Carrier[]>([]);
  const [carrierCarOptions, setCarrierCarOptions] = useState<CarrierCar[]>([]);
  const [carrierTrailerOptions, setCarrierTrailerOptions] = useState<
    CarrierTrailer[]
  >([]);
  const [carrierDriverOptions, setCarrierDriverOptions] = useState<
    CarrierDriver[]
  >([]);
  const [shipperOptions, setShipperOptions] = useState<Shipper[]>([]);

  const fetchCountries = () => {
    countryRequest
      .getCountries()
      .then((countries) => setCountryOptions(countries));
  };

  const fetchLoadingCounties = () => {
    if (selectedLoadingCountryId) {
      countryRequest
        .getCounties(selectedLoadingCountryId)
        .then((counties) => setLoadingCountyOptions(counties));
    }
  };

  const fetchLoadingCities = () => {
    if (selectedLoadingCountyId) {
      countryRequest
        .getCities(selectedLoadingCountyId)
        .then((cities) => setLoadingCityOptions(cities));
    }
  };

  const fetchUnloadingCounties = () => {
    if (selectedUnloadingCountryId) {
      countryRequest
        .getCounties(selectedUnloadingCountryId)
        .then((counties) => setUnloadingCountyOptions(counties));
    }
  };

  const fetchUnloadingCities = () => {
    if (selectedUnloadingCountyId) {
      countryRequest
        .getCities(selectedUnloadingCountyId)
        .then((cities) => setUnloadingCityOptions(cities));
    }
  };

  const fetchCarriers = () => {
    carrierRequest.findAll(10, 0).then((data) => {
      setCarrierOptions(
        data.results.map((carrier: Carrier) => ({
          id: carrier.id,
          name: carrier.name,
          tin: carrier.tin,
          city: carrier.city,
          address: carrier.address,
          phone: carrier.phone,
          email: carrier.email,
          contactPerson: carrier.contactPerson,
          isActive: carrier.isActive,
        }))
      );
    });
  };

  const fetchCarrierCars = () => {
    if (selectedCarrierId) {
      carrierCarRequest.findAll().then((data) => {
        setCarrierCarOptions(
          data.results
            .map((carrierCar: CarrierCar) => ({
              id: carrierCar.id,
              vehicleRegistrationPlate: carrierCar.vehicleRegistrationPlate,
              carrier: carrierCar.carrier,
            }))
            .filter(
              (carrierCar: CarrierCar) =>
                carrierCar.carrier?.id === selectedCarrierId
            )
        );
      });
    }
  };

  const fetchCarrierTrailers = () => {
    if (selectedCarrierId) {
      carrierTrailerRequest.findAll().then((data) => {
        setCarrierTrailerOptions(
          data.results
            .map((carrieTrailer: CarrierTrailer) => ({
              id: carrieTrailer.id,
              vehicleRegistrationPlate: carrieTrailer.vehicleRegistrationPlate,
              carrier: carrieTrailer.carrier,
            }))
            .filter(
              (carrieTrailer: CarrierTrailer) =>
                carrieTrailer.carrier?.id === selectedCarrierId
            )
        );
      });
    }
  };

  const fetchCarrierDrivers = () => {
    if (selectedCarrierId) {
      carrierDriverRequest.findAll().then((data) => {
        setCarrierDriverOptions(
          data.results
            .map((carrierDriver: CarrierDriver) => ({
              id: carrierDriver.id,
              name: carrierDriver.name,
              phone: carrierDriver.phone,
              carrier: carrierDriver.carrier,
            }))
            .filter(
              (carrierDriver: CarrierDriver) =>
                carrierDriver.carrier?.id === selectedCarrierId
            )
        );
      });
    }
  };

  const fetchShippers = () => {
    shipperRequest.findAll(10, 0).then((data) => {
      setShipperOptions(
        data.results.map((shipper: Shipper) => ({
          id: shipper.id,
          name: shipper.name,
          tin: shipper.tin,
          businessRegistrationNumber: shipper.businessRegistrationNumber,
          bank: shipper.bank,
          iban: shipper.iban,
          city: shipper.city,
          address: shipper.address,
          phone: shipper.phone,
          email: shipper.email,
          contactPerson: shipper.contactPerson,
          isActive: shipper.isActive,
        }))
      );
    });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    fetchLoadingCounties();
  }, [selectedLoadingCountryId]);

  useEffect(() => {
    fetchLoadingCities();
  }, [selectedLoadingCountyId]);

  useEffect(() => {
    fetchUnloadingCounties();
  }, [selectedUnloadingCountryId]);

  useEffect(() => {
    fetchUnloadingCities();
  }, [selectedUnloadingCountyId]);

  useEffect(() => {
    fetchCarriers();
  }, []);

  useEffect(() => {
    fetchCarrierCars();
  }, [selectedCarrierId]);

  useEffect(() => {
    fetchCarrierTrailers();
  }, [selectedCarrierId]);

  useEffect(() => {
    fetchCarrierDrivers();
  }, [selectedCarrierId]);

  useEffect(() => {
    fetchShippers();
  }, []);

  const sortedCountryOptions = countryOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedLoadingCountyOptions = loadingCountyOptions?.sort(function (
    a,
    b
  ) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedUnloadingCountyOptions = unloadingCountyOptions?.sort(function (
    a,
    b
  ) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedLoadingCityOptions = loadingCityOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedUnloadingCityOptions = unloadingCityOptions?.sort(function (
    a,
    b
  ) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierOptions = carrierOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierCarOptions = carrierCarOptions.sort(function (a, b) {
    if (a.vehicleRegistrationPlate! < b.vehicleRegistrationPlate!) {
      return -1;
    }
    if (a.vehicleRegistrationPlate! > b.vehicleRegistrationPlate!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierTrailerOptions = carrierTrailerOptions.sort(function (
    a,
    b
  ) {
    if (a.vehicleRegistrationPlate! < b.vehicleRegistrationPlate!) {
      return -1;
    }
    if (a.vehicleRegistrationPlate! > b.vehicleRegistrationPlate!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierDriverOptions = carrierDriverOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedShipperOptions = shipperOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const handleCheckShipperTripType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportValues((prev: any) => ({
      ...prev,
      shipperTripType: (event.target as HTMLInputElement).value,
    }));
  };

  const handleCheckCarrierTripType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportValues((prev: any) => ({
      ...prev,
      carrierTripType: (event.target as HTMLInputElement).value,
    }));
  };

  const [checkedTripStart, setCheckedTripStart] = React.useState(false);

  const handleCheckTripStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTripStart(event.target.checked);
    setLoadingCountryValues(() => ({
      country: null,
      county: null,
      city: null,
    }));
    setSelectedLoadingCountyId(null);
    setReportValues((prev: any) => ({
      ...prev,
      loadingCountryId: null,
      loadingCountyId: null,
      loadingCityId: null,
    }));
  };

  const [checkedTripFinish, setCheckedTripFinish] = React.useState(false);

  const handleCheckTripFinish = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedTripFinish(event.target.checked);
    setUnloadingCountryValues(() => ({
      country: null,
      county: null,
      city: null,
    }));
    setSelectedUnloadingCountyId(null);
    setReportValues((prev: any) => ({
      ...prev,
      unloadingCountryId: null,
      unloadingCountyId: null,
      unloadingCityId: null,
    }));
  };

  const [loadingCountryValues, setLoadingCountryValues] =
    useState<CountryValueProps>({
      country: null,
      county: null,
      city: null,
    });

  const [unloadingCountryValues, setUnloadingCountryValues] =
    useState<CountryValueProps>({
      country: null,
      county: null,
      city: null,
    });

  const isRomaniaDefaultValue = sortedCountryOptions?.find(
    (country: Country) => country.name?.toUpperCase() === "ROMANIA"
  );

  useEffect(() => {
    setLoadingCountryValues((prev: any) => ({
      ...prev,
      country:
        isRomaniaDefaultValue && checkedTripStart
          ? isRomaniaDefaultValue
          : loadingCountryValues.country,
    }));
    setSelectedLoadingCountryId(
      checkedTripStart
        ? isRomaniaDefaultValue?.id
        : loadingCountryValues.country?.id
    );
    setUnloadingCountryValues((prev: any) => ({
      ...prev,
      country:
        isRomaniaDefaultValue && checkedTripFinish
          ? isRomaniaDefaultValue
          : unloadingCountryValues.country,
    }));
    setSelectedUnloadingCountryId(
      checkedTripFinish
        ? isRomaniaDefaultValue?.id
        : unloadingCountryValues.country?.id
    );
    setReportValues((prev: any) => ({
      ...prev,
      loadingCountryId: checkedTripStart
        ? isRomaniaDefaultValue?.id
        : loadingCountryValues.country?.id,
      unloadingCountryId: checkedTripFinish
        ? isRomaniaDefaultValue?.id
        : unloadingCountryValues.country?.id,
    }));
  }, [isRomaniaDefaultValue, checkedTripStart, checkedTripFinish]);

  const isBucharestLoadingCountyChosen =
    loadingCountryValues?.county?.name?.toUpperCase() === "BUCURESTI";

  const isBucharestUnloadingCountyChosen =
    unloadingCountryValues?.county?.name?.toUpperCase() === "BUCURESTI";

  const isBucharestLoadingCityValue = loadingCityOptions?.find(
    (city: City) => city.name?.toUpperCase() === "BUCURESTI"
  );

  const isBucharestUnloadingCityValue = unloadingCityOptions?.find(
    (city: City) => city.name?.toUpperCase() === "BUCURESTI"
  );

  useEffect(() => {
    setLoadingCountryValues((prev: any) => ({
      ...prev,
      city:
        isBucharestLoadingCountyChosen &&
        isBucharestLoadingCityValue &&
        checkedTripStart
          ? isBucharestLoadingCityValue
          : loadingCountryValues.city,
    }));
    setReportValues((prev: any) => ({
      ...prev,
      loadingCityId:
        isBucharestLoadingCountyChosen &&
        isBucharestLoadingCityValue &&
        checkedTripStart
          ? isBucharestLoadingCityValue.id
          : loadingCountryValues.city?.id,
    }));

    setUnloadingCountryValues((prev: any) => ({
      ...prev,
      city:
        isBucharestUnloadingCountyChosen &&
        isBucharestUnloadingCityValue &&
        checkedTripFinish
          ? isBucharestUnloadingCityValue
          : unloadingCountryValues.city,
    }));
    setReportValues((prev: any) => ({
      ...prev,
      unloadingCityId:
        isBucharestUnloadingCountyChosen &&
        isBucharestUnloadingCityValue &&
        checkedTripFinish
          ? isBucharestUnloadingCityValue.id
          : unloadingCountryValues.city?.id,
    }));
  }, [
    isBucharestLoadingCountyChosen,
    isBucharestUnloadingCountyChosen,
    isBucharestLoadingCityValue,
    isBucharestUnloadingCityValue,
    checkedTripStart,
    checkedTripFinish,
  ]);

  const [checkedShowCarrier, setCheckedShowCarrier] = React.useState(false);

  const handleCheckShowCarrier = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedShowCarrier(event.target.checked);
  };

  const [tripsPerShipper, setTripsPerShipper] = useState<Trip[]>([]);
  const [requestIsLoading, setRequestIsLoading] = useState(false);

  const fetchTripsPerShipper = () => {
    handleErrors();
    const formIsValid =
      reportValues.startDate &&
      reportValues.endDate &&
      reportValues.shipperTripType &&
      reportValues.carrierTripType;
    if (formIsValid) {
      setRequestIsLoading(true);
      return reportRequest
        .getTripsPerBeneficiary(
          reportValues.startDate,
          reportValues.endDate,
          reportValues.shipperTripType,
          reportValues.carrierTripType,
          reportValues.shipperId,
          reportValues.carrierId,
          reportValues.carrierCarId,
          reportValues.carrieTrailerId,
          reportValues.carrierDriverId,
          reportValues.loadingCountryId,
          reportValues.loadingCountyId,
          reportValues.loadingCityId,
          reportValues.unloadingCountryId,
          reportValues.unloadingCountyId,
          reportValues.unloadingCityId
        )
        .then((data) => {
          setTripsPerShipper(
            data.results.map((trip: Trip) => ({
              id: trip.id,
              date: trip.date,
              cargoType: trip.cargoType,
              dispatcher: trip.dispatcher,
              loadingDate: trip.loadingDate,
              loadingAddress: trip.loadingAddress,
              loadingCity: trip.loadingCity,
              unloadingDate: trip.unloadingDate,
              unloadingAddress: trip.unloadingAddress,
              unloadingCity: trip.unloadingCity,
              carrier: trip.carrier,
              carrierCar: trip.carrierCar,
              carrierTrailer: trip.carrierTrailer,
              carrierDriver: trip.carrierDriver,
              carrierInvoice: trip.carrierInvoice,
              carrierInvoiceRows: trip.carrierInvoiceRows,
              carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
              shipper: trip.shipper,
              shipperBranch: trip.shipperBranch,
              shipperReferenceId: trip.shipperReferenceId,
              shipperInvoice: trip.shipperInvoice,
              shipperInvoiceRows: trip.shipperInvoiceRows,
              shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
              observations: trip.observations,
              comments: trip.comments,
              status: trip.status,
              computedStatus: trip.computedStatus,
              shipperInvoiceDueDate: trip.shipperInvoiceDueDate,
              carrierInvoiceDueDate: trip.carrierInvoiceDueDate,
            }))
          );

          return data;
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        })
        .finally(() => {
          setRequestIsLoading(false);
        });
    }
  };

  const [openPDFDialog, setOpenPDFDialog] = React.useState(false);
  const [downloadPdf, setDownloadPdf] = React.useState(false);
  const handleOpenPDFDialog = () => {
    setOpenPDFDialog(true);
  };
  const handleDownloadPdf = () => {
    setDownloadPdf(true);
  };

  const [searchCarrierTextInput, setSearchCarrierTextInput] = useState("");
  const [searchShipperTextInput, setSearchShipperTextInput] = useState("");

  useSkipFirstRun(() => {
    if (!searchCarrierTextInput) {
      fetchCarriers();
      return;
    }

    const timeoutId = setTimeout(() => {
      carrierRequest
        .searchByName(searchCarrierTextInput)
        .then((results) => setCarrierOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchCarrierTextInput]);

  useSkipFirstRun(() => {
    if (!searchShipperTextInput) {
      fetchShippers();
      return;
    }

    const timeoutId = setTimeout(() => {
      shipperRequest
        .searchByName(searchShipperTextInput)
        .then((results) => setShipperOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchShipperTextInput]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Început dată încărcare"
                value={reportValues.startDate}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setReportValues((prev: any) => ({
                    ...prev,
                    startDate: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    error={error.startDate}
                    helperText={error.startDate && "Câmp obligatoriu!"}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "zz/ll/aaaa",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={{ ml: 1.5 }}
            >
              <FormControl error={error.shipperTripType}>
                <RadioGroup
                  value={reportValues.shipperTripType}
                  onChange={handleCheckShipperTripType}
                >
                  <FormControlLabel
                    value="All"
                    control={<Radio />}
                    label="Toate comenzile"
                  />

                  <FormControlLabel
                    value="Billed"
                    control={<Radio />}
                    label="Facturate"
                  />

                  <FormControlLabel
                    value="NonBilled"
                    control={<Radio />}
                    label="Nefacturate"
                  />
                </RadioGroup>
                {error.shipperTripType && (
                  <FormHelperText>Câmp obligatoriu!</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={shipperValue}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setShipperValue(null);
                  setReportValues((prev: any) => ({
                    ...prev,
                    shipperId: null,
                  }));
                  fetchShippers();
                } else {
                  setShipperValue(newValue);
                  setReportValues((prev: any) => ({
                    ...prev,
                    shipperId: newValue?.id,
                  }));
                }
              }}
              options={sortedShipperOptions}
              filterSelectedOptions
              getOptionLabel={(option) => `${option.name}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Fără opțiuni"
              renderInput={({
                inputProps: { onChange, ...restInputProps },
                ...params
              }) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...restInputProps,
                    onChange: (...args) => {
                      setSearchShipperTextInput(
                        // @ts-ignore
                        args[0].target.value
                      );
                      // @ts-ignore
                      onChange(...args);
                    },
                  }}
                  fullWidth
                  size="small"
                  label="Beneficiar"
                />
              )}
              sx={{ flexGrow: 1, mr: 1 }}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Checkbox
                  size="medium"
                  checked={checkedTripStart}
                  onChange={handleCheckTripStart}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">Încărcare</Typography>
              </Grid>
            </Grid>
          </Grid>
          {checkedTripStart ? (
            <Grid item xs={12} lg={4}>
              <Autocomplete
                value={loadingCountryValues.country}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setLoadingCountryValues((prev: any) => ({
                      ...prev,
                      country: null,
                      county: null,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      loadingCountryId: null,
                      loadingCountyId: null,
                      loadingCityId: null,
                    }));
                    setSelectedLoadingCountryId(null);
                    setSelectedLoadingCountyId(null);
                  } else {
                    setLoadingCountryValues((prev: any) => ({
                      ...prev,
                      country: newValue,
                      county: null,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      loadingCountryId: newValue?.id,
                      loadingCountyId: null,
                      loadingCityId: null,
                    }));
                    setSelectedLoadingCountryId(newValue?.id);
                    setSelectedLoadingCountyId(null);
                  }
                }}
                options={sortedCountryOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Țară"
                    />
                  </form>
                )}
              />
            </Grid>
          ) : null}
          {checkedTripStart ? (
            <Grid item xs={12} lg={4}>
              <Autocomplete
                value={loadingCountryValues.county}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setLoadingCountryValues((prev: any) => ({
                      ...prev,
                      county: null,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      loadingCountyId: null,
                      loadingCityId: null,
                    }));
                    setSelectedLoadingCountyId(null);
                  } else {
                    setLoadingCountryValues((prev: any) => ({
                      ...prev,
                      county: newValue,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      loadingCountyId: newValue?.id,
                      loadingCityId: null,
                    }));
                    setSelectedLoadingCountyId(newValue?.id);
                  }
                }}
                disabled={!selectedLoadingCountryId}
                options={sortedLoadingCountyOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Județ"
                    />
                  </form>
                )}
              />
            </Grid>
          ) : null}
          {checkedTripStart ? (
            <Grid item xs={12} lg={4}>
              <Autocomplete
                value={loadingCountryValues.city}
                onChange={(event, newValue) => {
                  setLoadingCountryValues((prev: any) => ({
                    ...prev,
                    city: newValue,
                  }));
                  setReportValues((prev: any) => ({
                    ...prev,
                    loadingCityId: newValue?.id,
                  }));
                }}
                options={sortedLoadingCityOptions}
                filterSelectedOptions
                disabled={!selectedLoadingCountyId}
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Localitate"
                    />
                  </form>
                )}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Sfârșit dată încărcare"
                value={reportValues.endDate}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setReportValues((prev: any) => ({
                    ...prev,
                    endDate: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    error={error.endDate}
                    helperText={error.endDate && "Câmp obligatoriu!"}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "zz/ll/aaaa",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={{ ml: 1.5 }}
            >
              <FormControl error={error.carrierTripType}>
                <RadioGroup
                  value={reportValues.carrierTripType}
                  onChange={handleCheckCarrierTripType}
                >
                  <FormControlLabel
                    value="All"
                    control={<Radio />}
                    label="Toate comenzile"
                  />

                  <FormControlLabel
                    value="Confirmed"
                    control={<Radio />}
                    label="Operate"
                  />

                  <FormControlLabel
                    value="NonConfirmed"
                    control={<Radio />}
                    label="Neoperate"
                  />
                </RadioGroup>
                {error.carrierTripType && (
                  <FormHelperText>Câmp obligatoriu!</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={carrierValue}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setCarrierValue(null);
                  setCarrierCarValue(null);
                  setCarrierTrailerValue(null);
                  setCarrierDriverValue(null);
                  setReportValues((prev: any) => ({
                    ...prev,
                    carrierId: null,
                    carrierCarId: null,
                    carrierTrailerId: null,
                    carrierDriverId: null,
                  }));
                  setSelectedCarrierId(null);
                  fetchCarriers();
                } else {
                  setCarrierValue(newValue);
                  setSelectedCarrierId(newValue?.id);
                  setCarrierCarValue(null);
                  setCarrierTrailerValue(null);
                  setCarrierDriverValue(null);
                  setReportValues((prev: any) => ({
                    ...prev,
                    carrierId: newValue?.id,
                    carrierCarId: null,
                    carrierTrailerId: null,
                    carrierDriverId: null,
                  }));
                }
              }}
              options={sortedCarrierOptions}
              filterSelectedOptions
              getOptionLabel={(option) => `${option.name}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Fără opțiuni"
              renderInput={({
                inputProps: { onChange, ...restInputProps },
                ...params
              }) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...restInputProps,
                      onChange: (...args) => {
                        setSearchCarrierTextInput(
                          // @ts-ignore
                          args[0].target.value
                        );
                        // @ts-ignore
                        onChange(...args);
                      },
                    }}
                    fullWidth
                    size="small"
                    label="Transportator"
                  />
                );
              }}
              sx={{ flexGrow: 1, mr: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={carrierCarValue}
              onChange={(event, newValue) => {
                setCarrierCarValue(newValue);
                setReportValues((prev: any) => ({
                  ...prev,
                  carrierCarId: newValue?.id,
                }));
              }}
              options={sortedCarrierCarOptions}
              filterSelectedOptions
              disabled={!selectedCarrierId}
              getOptionLabel={(option) => `${option.vehicleRegistrationPlate}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Fără opțiuni"
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" label="Mașină" />
              )}
              sx={{ flexGrow: 1, mr: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={carrierTrailerValue}
              onChange={(event, newValue) => {
                setCarrierTrailerValue(newValue);
                setReportValues((prev: any) => ({
                  ...prev,
                  carrieTrailerId: newValue?.id,
                }));
              }}
              options={sortedCarrierTrailerOptions}
              filterSelectedOptions
              disabled={!selectedCarrierId}
              getOptionLabel={(option) => `${option.vehicleRegistrationPlate}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Fără opțiuni"
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" label="Remorcă" />
              )}
              sx={{ flexGrow: 1, mr: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={carrierDriverValue}
              onChange={(event, newValue) => {
                setCarrierDriverValue(newValue);
                setReportValues((prev: any) => ({
                  ...prev,
                  carrierDriverId: newValue?.id,
                }));
              }}
              options={sortedCarrierDriverOptions}
              filterSelectedOptions
              disabled={!selectedCarrierId}
              getOptionLabel={(option) => `${option.name}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Fără opțiuni"
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" label="Șofer" />
              )}
              sx={{ flexGrow: 1, mr: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Checkbox
                  size="medium"
                  checked={checkedTripFinish}
                  onChange={handleCheckTripFinish}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">Descărcare</Typography>
              </Grid>
            </Grid>
          </Grid>
          {checkedTripFinish ? (
            <Grid item xs={12} lg={4}>
              <Autocomplete
                value={unloadingCountryValues.country}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setUnloadingCountryValues((prev: any) => ({
                      ...prev,
                      country: null,
                      county: null,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      unloadingCountryId: null,
                      unloadingCountyId: null,
                      unloadingCityId: null,
                    }));
                    setSelectedUnloadingCountryId(null);
                    setSelectedUnloadingCountyId(null);
                  } else {
                    setUnloadingCountryValues((prev: any) => ({
                      ...prev,
                      country: newValue,
                      county: null,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      unloadingCountryId: newValue?.id,
                      unloadingCountyId: null,
                      unloadingCityId: null,
                    }));
                    setSelectedUnloadingCountryId(newValue?.id);
                    setSelectedUnloadingCountyId(null);
                  }
                }}
                options={sortedCountryOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Țară"
                    />
                  </form>
                )}
              />
            </Grid>
          ) : null}
          {checkedTripFinish ? (
            <Grid item xs={12} lg={4}>
              <Autocomplete
                value={unloadingCountryValues.county}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setUnloadingCountryValues((prev: any) => ({
                      ...prev,
                      county: null,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      unloadingCountyId: null,
                      unloadingCityId: null,
                    }));
                    setSelectedUnloadingCountyId(null);
                  } else {
                    setUnloadingCountryValues((prev: any) => ({
                      ...prev,
                      county: newValue,
                      city: null,
                    }));
                    setReportValues((prev: any) => ({
                      ...prev,
                      unloadingCountyId: newValue?.id,
                      unloadingCityId: null,
                    }));
                    setSelectedUnloadingCountyId(newValue?.id);
                  }
                }}
                disabled={!selectedUnloadingCountryId}
                options={sortedUnloadingCountyOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Județ"
                    />
                  </form>
                )}
              />
            </Grid>
          ) : null}
          {checkedTripFinish ? (
            <Grid item xs={12} lg={4}>
              <Autocomplete
                value={unloadingCountryValues.city}
                onChange={(event, newValue) => {
                  setUnloadingCountryValues((prev: any) => ({
                    ...prev,
                    city: newValue,
                  }));
                  setReportValues((prev: any) => ({
                    ...prev,
                    unloadingCityId: newValue?.id,
                  }));
                }}
                options={sortedUnloadingCityOptions}
                filterSelectedOptions
                disabled={!selectedUnloadingCountyId}
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      label="Localitate"
                    />
                  </form>
                )}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Checkbox
              size="medium"
              checked={checkedShowCarrier}
              onChange={handleCheckShowCarrier}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">Afișează transportator</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                fetchTripsPerShipper()?.then((data) => {
                  if (!data?.results?.length) {
                    return;
                  }
                  if (data.results.length <= 200) {
                    handleOpenPDFDialog();
                  } else {
                    handleDownloadPdf();
                  }
                });
              }}
              endIcon={
                requestIsLoading || downloadPdf ? (
                  <CircularProgress size={12} />
                ) : undefined
              }
              disabled={requestIsLoading || downloadPdf}
            >
              Generează raport
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {(openPDFDialog || downloadPdf) && (
        <TripsShippersReportPDFDialog
          open={openPDFDialog}
          onClose={() => setOpenPDFDialog(false)}
          showCarrier={checkedShowCarrier}
          tripsPerShipper={tripsPerShipper}
          startDate={reportValues.startDate}
          endDate={reportValues.endDate}
          downloadPdf={downloadPdf}
          onPdfDownloaded={() => setDownloadPdf(false)}
        />
      )}
    </Grid>
  );
}
