import RootRequest from "./RootRequest";

export default class CarrierTrailerRequest extends RootRequest {
  get apiResource() {
    return "api/carrier-trailer";
  }

  get apiRoutes(): Record<string, string> {
    return {
      // @ts-ignore
      ...super.apiRoutes,
      SEARCH_BY_NAME: `${this.apiRoute}/search-by-name/`,
    };
  }

  async searchByName(searchText: string) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH_BY_NAME + searchText
    );

    return response?.data;
  }
}

export const carrierTrailerRequest = new CarrierTrailerRequest();
