export function checkCarrierCarTrailerCmrInsuranceDates(
  cmrInsuranceStartDate?: Date | null,
  cmrInsuranceEndDate?: Date | null
): { isValid: boolean; maxEndDate?: Date } {
  let startDate, endDate, maxEndDate;

  if (cmrInsuranceStartDate) {
    startDate = new Date(cmrInsuranceStartDate);
    maxEndDate = new Date(
      new Date(startDate).setFullYear(startDate.getFullYear() + 1)
    );
  }
  if (cmrInsuranceEndDate) {
    endDate = new Date(cmrInsuranceEndDate);
  }

  if (!startDate || !endDate || !maxEndDate) {
    return { isValid: true, maxEndDate };
  }

  if (startDate >= endDate) {
    return { isValid: false, maxEndDate };
  }

  if (endDate > maxEndDate) {
    return { isValid: false, maxEndDate };
  }

  return { isValid: true, maxEndDate };
}
