import {
  Collapse,
  darken,
  Divider,
  lighten,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import PATH from "../../paths";
import {
  TableChart,
  Domain,
  NoCrash,
  ReceiptLong,
  Settings,
  Logout,
  CreditScore,
  Description,
  ExpandLess,
  ExpandMore,
  FormatBold,
  Inventory,
  LocalShipping,
  ManageAccounts,
  PeopleAlt,
  Route,
  TableRows,
  Title,
  Public,
  Task,
  RvHookup,
} from "@mui/icons-material";
import routeTitleMapObject from "../../helpers/routeTitleMapObject";
import { logout } from "../../redux/slice/auth";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.9);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.8);

export const menuItems = (
  openReports: any,
  openManagement: any,
  handleOpenReports: AnyFunction,
  handleOpenManagement: AnyFunction,
  setTitle: AnyFunction,
  navigate: (path: string) => void,
  dispatch: AnyFunction,
  isAdmin: any,
  isAdminOrManager: any,
  isShipperBeneficiary: boolean
) => {
  if (isShipperBeneficiary) {
    return (
      <>
        <ListItemButton
          onClick={() => {
            setTitle(routeTitleMapObject[PATH.TRIP.LIST]);
            navigate(PATH.TRIP.LIST);
          }}
        >
          <ListItemIcon>
            <Route />
          </ListItemIcon>
          <ListItemText primary="Comenzi" />
        </ListItemButton>
        <ListItemButton onClick={() => dispatch(logout())}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Deconectare" />
        </ListItemButton>
      </>
    );
  }

  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => {
          setTitle(routeTitleMapObject[PATH.TRIP.LIST]);
          navigate(PATH.TRIP.LIST);
        }}
      >
        <ListItemIcon>
          <Route />
        </ListItemIcon>
        <ListItemText primary="Comenzi" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          setTitle(routeTitleMapObject[PATH.CONFIRM_TRIP]);
          navigate(PATH.CONFIRM_TRIP);
        }}
      >
        <ListItemIcon>
          <NoCrash />
        </ListItemIcon>
        <ListItemText primary="Operare" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          setTitle(routeTitleMapObject[PATH.SHIPPER_INVOICE]);
          navigate(PATH.SHIPPER_INVOICE);
        }}
      >
        <ListItemIcon>
          <ReceiptLong />
        </ListItemIcon>
        <ListItemText primary="Facturare" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          setTitle(
            routeTitleMapObject[PATH.VALIDATE_TRIP_PUBLIC_UPLOADED_FILES]
          );
          navigate(PATH.VALIDATE_TRIP_PUBLIC_UPLOADED_FILES);
        }}
      >
        <ListItemIcon>
          <Task />
        </ListItemIcon>
        <ListItemText primary="Validare documente" />
      </ListItemButton>
      <ListItemButton
        disabled
        onClick={() => {
          setTitle(routeTitleMapObject[PATH.DUE_INVOICES]);
          navigate(PATH.DUE_INVOICES);
        }}
      >
        <ListItemIcon>
          <CreditScore />
        </ListItemIcon>
        <ListItemText primary="Facturi scadente" />
      </ListItemButton>
      <ListItemButton onClick={handleOpenReports}>
        <ListItemIcon>
          <TableRows />
        </ListItemIcon>
        <ListItemText primary="Raportare" />
        {openReports ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {openReports ? <Divider /> : <></>}
      <Collapse in={openReports} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.REPORTS.TRIPS_SHIPPERS]);
              navigate(PATH.REPORTS.TRIPS_SHIPPERS);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <TableChart />
            </ListItemIcon>
            <ListItemText primary="Comenzi/Beneficiari" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.REPORTS.TRIPS_DISPATCHERS]);
              navigate(PATH.REPORTS.TRIPS_DISPATCHERS);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <TableChart />
            </ListItemIcon>
            <ListItemText primary="Comenzi/Dispeceri" />
          </ListItemButton>
          {isAdmin && (
            <>
              <ListItemButton
                onClick={() => {
                  setTitle(routeTitleMapObject[PATH.REPORTS.LOSER_TRIPS]);
                  navigate(PATH.REPORTS.LOSER_TRIPS);
                }}
                sx={{
                  backgroundColor: (theme) =>
                    getBackgroundColor(
                      theme.palette.grey[800],
                      theme.palette.mode
                    ),
                  "&&:hover": {
                    backgroundColor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.grey[800],
                        theme.palette.mode
                      ),
                  },
                }}
              >
                <ListItemIcon>
                  <TableChart />
                </ListItemIcon>
                <ListItemText primary="Comenzi în pierdere" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setTitle(
                    routeTitleMapObject[
                      PATH.REPORTS.PROFIT_LESS_THAN_ONE_HUNDRED
                    ]
                  );
                  navigate(PATH.REPORTS.PROFIT_LESS_THAN_ONE_HUNDRED);
                }}
                sx={{
                  backgroundColor: (theme) =>
                    getBackgroundColor(
                      theme.palette.grey[800],
                      theme.palette.mode
                    ),
                  "&&:hover": {
                    backgroundColor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.grey[800],
                        theme.palette.mode
                      ),
                  },
                }}
              >
                <ListItemIcon>
                  <TableChart />
                </ListItemIcon>
                <ListItemText primary="Comenzi profit < 100" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setTitle(routeTitleMapObject[PATH.REPORTS.TOP_CLIENTS]);
                  navigate(PATH.REPORTS.TOP_CLIENTS);
                }}
                sx={{
                  backgroundColor: (theme) =>
                    getBackgroundColor(
                      theme.palette.grey[800],
                      theme.palette.mode
                    ),
                  "&&:hover": {
                    backgroundColor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.grey[800],
                        theme.palette.mode
                      ),
                  },
                }}
              >
                <ListItemIcon>
                  <TableChart />
                </ListItemIcon>
                <ListItemText primary="Top clienți" />
              </ListItemButton>
            </>
          )}
        </List>
      </Collapse>
      {openReports ? <Divider /> : <></>}
      {/*{open ? <Divider /> : <></>}*/}
      <ListItemButton onClick={handleOpenManagement}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Administrare" />
        {openManagement ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {openManagement ? <Divider /> : <></>}
      <Collapse in={openManagement} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {isAdminOrManager && (
            <>
              <ListItemButton
                onClick={() => {
                  setTitle(routeTitleMapObject[PATH.USERS]);
                  navigate(PATH.USERS);
                }}
                sx={{
                  backgroundColor: (theme) =>
                    getBackgroundColor(
                      theme.palette.grey[800],
                      theme.palette.mode
                    ),
                  "&&:hover": {
                    backgroundColor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.grey[800],
                        theme.palette.mode
                      ),
                  },
                }}
              >
                <ListItemIcon>
                  <ManageAccounts />
                </ListItemIcon>
                <ListItemText primary="Utilizatori" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setTitle(routeTitleMapObject[PATH.INVOICE_SERIES]);
                  navigate(PATH.INVOICE_SERIES);
                }}
                sx={{
                  backgroundColor: (theme) =>
                    getBackgroundColor(
                      theme.palette.grey[800],
                      theme.palette.mode
                    ),
                  "&&:hover": {
                    backgroundColor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.grey[800],
                        theme.palette.mode
                      ),
                  },
                }}
              >
                <ListItemIcon>
                  <Description />
                </ListItemIcon>
                <ListItemText primary="Serii facturare" />
              </ListItemButton>
            </>
          )}
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.CARGO_TYPES]);
              navigate(PATH.CARGO_TYPES);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <Inventory />
            </ListItemIcon>
            <ListItemText primary="Tipuri de marfă" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.SHIPPERS]);
              navigate(PATH.SHIPPERS);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <FormatBold />
            </ListItemIcon>
            <ListItemText primary="Beneficiari" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.SHIPPER_BRANCHES]);
              navigate(PATH.SHIPPER_BRANCHES);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <Domain />
            </ListItemIcon>
            <ListItemText primary="Puncte de lucru" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.CARRIERS]);
              navigate(PATH.CARRIERS);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <Title />
            </ListItemIcon>
            <ListItemText primary="Transportatori" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.CARRIER_CARS]);
              navigate(PATH.CARRIER_CARS);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <LocalShipping />
            </ListItemIcon>
            <ListItemText primary="Mașini" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.CARRIER_TRAILERS]);
              navigate(PATH.CARRIER_TRAILERS);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <RvHookup />
            </ListItemIcon>
            <ListItemText primary="Remorci" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.CARRIER_DRIVERS]);
              navigate(PATH.CARRIER_DRIVERS);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <PeopleAlt />
            </ListItemIcon>
            <ListItemText primary="Șoferi" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setTitle(routeTitleMapObject[PATH.COUNTRIES]);
              navigate(PATH.COUNTRIES);
            }}
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.grey[800], theme.palette.mode),
              "&&:hover": {
                backgroundColor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.grey[800],
                    theme.palette.mode
                  ),
              },
            }}
          >
            <ListItemIcon>
              <Public />
            </ListItemIcon>
            <ListItemText primary="Țări" />
          </ListItemButton>
        </List>
      </Collapse>
      {openManagement ? <Divider /> : <></>}
      <ListItemButton onClick={() => dispatch(logout())}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Deconectare" />
      </ListItemButton>
    </React.Fragment>
  );
};
