const PATH = {
  LOGIN: "/login",
  TRIP: {
    LIST: "/comenzi",
    VIEW: "/comenzi/:id",
    PUBLIC_UPLOAD_FILES: "/incarcare-documente-publice-comanda/:uuid",
  },
  CONFIRM_TRIP: "/confirmare",
  SHIPPER_INVOICE: "/facturare",
  VALIDATE_TRIP_PUBLIC_UPLOADED_FILES: "/validare-documente-publice-comanda",
  DUE_INVOICES: "/facturi-scadente",
  REPORTS: {
    TRIPS_SHIPPERS: "/raportare/comenzi-beneficiari",
    TRIPS_DISPATCHERS: "/raportare/comenzi-dispeceri",
    LOSER_TRIPS: "/raportare/comenzi-in-pierdere",
    PROFIT_LESS_THAN_ONE_HUNDRED: "/raportare/comenzi-cu-profit-sub-100",
    TOP_CLIENTS: "/raportare/top-clients",
  },
  USERS: "/management/utilizatori",
  INVOICE_SERIES: "/management/serii-facturare",
  SHIPPERS: "/management/beneficiari",
  SHIPPER_BRANCHES: "/management/puncte-de-lucru",
  CARRIERS: "/management/transportatori",
  CARGO_TYPES: "/management/tipuri-marfa",
  CARRIER_CARS: "/management/masini",
  CARRIER_TRAILERS: "/management/remorci",
  CARRIER_DRIVERS: "/management/soferi",
  COUNTRIES: "/management/tari",
  COUNTIES: "/management/tari/:id/judete",
  CITIES: "/management/tari/judete/:id/localitati",
};

export default PATH;
