import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Divider, Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { carrierDriverRequest } from "../../../api/CarrierDriverRequest";
import { carrierRequest } from "../../../api/CarrierRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  carrierDriver?: CarrierDriver;
  onCarrierDriverEdited: AnyFunction;
}

interface FormValues {
  name: string | null;
  phone: string | null;
  carrierId: number | null;
}

export default function EditCarrierDriverDialog(props: Props) {
  const { open, onClose, carrierDriver, onCarrierDriverEdited } = props;

  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    phone: "",
    carrierId: null,
  });

  const [carrierValue, setCarrierValue] = useState<Carrier | null>(null);

  const [error, setError] = useState({
    name: false,
    phone: false,
    phoneIsDuplicate: false,
    carrierId: false,
  });

  const handleErrors = () => {
    if (!formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: true,
      }));
    } else if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: false,
      }));
    }
    if (!formValues.phone) {
      setError((prev: any) => ({
        ...prev,
        phone: true,
      }));
    } else if (formValues.phone) {
      setError((prev: any) => ({
        ...prev,
        phone: false,
      }));
    }
    if (!formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: true,
      }));
    } else if (formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: false,
      }));
    }
  };

  const handleSubmit = () => {
    if (carrierDriver && carrierDriver.id) {
      handleErrors();
      const formIsValid = formValues.name && formValues.carrierId;
      if (formIsValid) {
        return carrierDriverRequest
          .patch(carrierDriver.id, formValues)
          .then(() => {
            setCarrierValue(null);
            if (typeof onCarrierDriverEdited === "function") {
              onCarrierDriverEdited();
            }
          })
          .catch(() => {
            alert("S-a produs o eroare!");
          });
      }
    }
  };

  const handleCloseEditCarrierDriverDialog = () => {
    onClose();
    setError(() => ({
      name: false,
      phone: false,
      phoneIsDuplicate: false,
      carrierId: false,
    }));
  };

  useEffect(() => {
    if (carrierDriver) {
      setFormValues({
        name: carrierDriver.name,
        phone: carrierDriver.phone,
        carrierId: carrierDriver.carrier?.id || null,
      });
      setCarrierValue(carrierDriver.carrier || null);
    }
  }, [carrierDriver]);

  const [carrierOptions, setCarrierOptions] = useState<Carrier[]>([]);
  const fetchCarriers = () => {
    carrierRequest.findAll().then((data) => {
      setCarrierOptions(
        data.results.map((carrier: Carrier) => ({
          id: carrier.id,
          name: carrier.name,
          tin: carrier.tin,
          city: carrier.city,
          address: carrier.address,
          phone: carrier.phone,
          email: carrier.email,
          contactPerson: carrier.contactPerson,
          isActive: carrier.isActive,
        }))
      );
    });
  };

  useEffect(() => {
    fetchCarriers();
  }, []);

  const sortedCarrierOptions = carrierOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Modificare șofer</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton
              onClick={handleCloseEditCarrierDriverDialog}
              tabIndex={-1}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Nume"
                error={error.name}
                helperText={error.name && "Câmp obligatoriu!"}
                value={formValues?.name ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    name: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditCarrierDriverDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Telefon"
                error={error.phone || error.phoneIsDuplicate}
                helperText={
                  error.phone
                    ? "Câmp obligatoriu!"
                    : error.phoneIsDuplicate && "Telefon deja existent!"
                }
                value={formValues?.phone ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    phone: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditCarrierDriverDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={carrierValue ?? null}
                onChange={(event, newValue) => {
                  setCarrierValue(newValue);
                  setFormValues((prev: any) => ({
                    ...prev,
                    carrierId: newValue?.id,
                  }));
                }}
                options={sortedCarrierOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      required
                      {...params}
                      size="small"
                      fullWidth
                      label="Transportator"
                      error={error.carrierId}
                      helperText={error.carrierId && "Câmp obligatoriu!"}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit()?.then(
                            handleCloseEditCarrierDriverDialog
                          );
                        }
                      }}
                    />
                  </form>
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()?.then(handleCloseEditCarrierDriverDialog);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseEditCarrierDriverDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
