import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  pdf,
} from "@react-pdf/renderer";
import dateFormat from "dateformat";
import * as React from "react";
import { useSelector } from "react-redux";
import { vatSelector } from "../redux/slice/selectors";
import efeImpexLogo from "../assets/efe_logo_transparent.png";
import { useEffect } from "react";
import { getCarrierCarTrailerDisplayName } from "../functions/getCarrierCarTrailerDisplayName";

Font.registerHyphenationCallback((word) => {
  if (word.length >= 10) {
    const part1 = word.slice(0, word.length / 2);
    const part2 = word.slice(word.length / 2, word.length);

    return [part1, part2];
  }
  return [word];
});

const styles = StyleSheet.create({
  viewer: {
    width: "1536px",
    height: window.innerHeight,
  },
  page: {
    backgroundColor: "white",
    color: "black",
    padding: "20px 50px 30px 50px",
  },
  section: {
    marginBottom: 10,
  },
  smallSection: {
    marginBottom: 5,
  },
  header: {
    padding: "0px 30px 0px 30px",
  },
  bold12: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  bold9: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  bold7: {
    fontSize: 7,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  regular9: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  regular8: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  regular7: {
    fontSize: 7,
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  container: {
    flexDirection: "row",
    borderColor: "#000000",
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    alignItems: "center",
    flexGrow: 1,
    paddingTop: 3,
    paddingBottom: 3,
  },
  content: {
    flexDirection: "row",
    borderColor: "#000000",
    // borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    // alignItems: "center",

    flexGrow: 1,
    paddingTop: 3,
    paddingBottom: 3,
  },
  cell: {
    width: "10%",
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
  smallCell: {
    width: "5%",
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
  largeCell: {
    width: "45%",
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
  rowCell: {
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
});

const appendixStyles = StyleSheet.create({
  viewer: {
    width: "1200px",
    height: window.innerHeight,
  },
  page: {
    backgroundColor: "white",
    color: "black",
    padding: "20px 50px 50px 50px",
  },
  section: {
    marginBottom: 10,
  },
  bold9: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E5E4E2",
    // borderColor: "#000000",
    // borderWidth: 1,
    alignItems: "flex-start",
    textAlign: "left",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E5E4E2",
    borderColor: "#000000",
    borderBottomWidth: 1,
    alignItems: "flex-start",
    textAlign: "left",
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    borderColor: "#000000",
    borderTopWidth: 1,
    alignItems: "flex-start",
    textAlign: "left",
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexGrow: 1,
  },
});

interface Props {
  trip?: Trip;
  trips?: Trip[];
  onBlobSet?: AnyFunction;
}

export default function InvoiceAppendixRoPDF(props: Props) {
  const { trip, trips, onBlobSet } = props;
  const vat = useSelector(vatSelector);

  const sortedTrips = trips?.sort(function (a, b) {
    if (a.id! < b.id!) {
      return -1;
    }
    if (a.id! > b.id!) {
      return 1;
    }
    return 0;
  });

  let shipperFinalTotalKm = 0;
  let shipperFinalTotalValue = 0;
  let shipperFinalTotalVat = 0;
  let appendixShipperFinalTotalKm = 0;
  let appendixShipperFinalTotalValue = 0;
  let appendixShipperFinalTotalVat = 0;
  sortedTrips?.map((trip) => {
    let shipperKm = 0;
    let shipperValue = 0;
    trip?.shipperInvoiceRows &&
      trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
        shipperKm += Number(shipperInvoiceRow.distanceKm);
        const currentShipperValue =
          Number(shipperInvoiceRow.distanceKm) *
          Number(shipperInvoiceRow.value);
        shipperValue += currentShipperValue;
        shipperFinalTotalKm += Number(shipperInvoiceRow.distanceKm);
        shipperFinalTotalValue +=
          Number(shipperInvoiceRow.distanceKm) *
          Number(shipperInvoiceRow.value);
        shipperFinalTotalVat += (currentShipperValue * vat) / 100;
      });
  });

  const maxDateTrip = sortedTrips?.reduce((previous: Trip, current: Trip) => {
    return current.date! > previous.date! ? current : previous;
  });
  const minDateTrip = sortedTrips?.reduce((previous: Trip, current: Trip) => {
    return current.date! < previous.date! ? current : previous;
  });

  const document = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <View style={styles.smallSection}>
                <Text style={styles.bold12}>FACTURA</Text>
              </View>
              <View style={styles.smallSection}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ marginRight: 10 }}>
                    <Text style={styles.bold9}>
                      {trip
                        ? `Serie ${trip?.shipperInvoice?.invoiceSeries?.name?.toUpperCase()}`
                        : sortedTrips
                        ? `Serie ${sortedTrips[0]?.shipperInvoice?.invoiceSeries?.name?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                  <Text style={styles.bold9}>
                    {trip
                      ? `Numar ${trip?.shipperInvoice?.number}`
                      : sortedTrips
                      ? `Numar ${sortedTrips[0]?.shipperInvoice?.number}`
                      : "-"}
                  </Text>
                </div>
              </View>
            </div>
            <div>
              <Image
                src={efeImpexLogo}
                style={{
                  minWidth: 59,
                  maxWidth: 59,
                  minHeight: 34,
                  maxHeight: 34,
                  marginRight: 10,
                }}
              />
            </div>
          </div>
          <View style={styles.smallSection}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <Text style={styles.bold9}>
                    {trip?.date
                      ? `Data ${dateFormat(
                          trip?.shipperInvoice?.date?.toString(),
                          "dd.mm.yyyy"
                        )}`
                      : sortedTrips
                      ? `Data ${dateFormat(
                          sortedTrips[0]?.shipperInvoice?.date?.toString(),
                          "dd.mm.yyyy"
                        )}`
                      : "-"}
                  </Text>
                </div>
                <Text style={styles.bold9}>
                  {trip
                    ? `Scadenta la ${dateFormat(
                        trip?.shipperInvoiceDueDate?.toString(),
                        "dd.mm.yyyy"
                      )}`
                    : trips
                    ? `Scadenta la ${dateFormat(
                        trips[0]?.shipperInvoiceDueDate?.toString(),
                        "dd.mm.yyyy"
                      )}`
                    : "-"}
                </Text>
              </div>
              <Text style={styles.bold9}>- RON -</Text>
            </div>
          </View>
        </View>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <View style={styles.section}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                borderBottomColor: "#000000",
                borderBottomWidth: 0.5,
                paddingLeft: 7,
                paddingBottom: 2,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.regular8}>Furnizor</Text>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.regular8}>Client</Text>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                paddingLeft: 7,
                paddingTop: 5,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.bold9}>EFE IMPEX 72 SRL</Text>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.bold9}>
                  {trip
                    ? `${trip?.shipper?.name?.toUpperCase()}`
                    : sortedTrips
                    ? `${sortedTrips[0]?.shipper?.name?.toUpperCase()}`
                    : "-"}
                </Text>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                paddingLeft: 7,
                paddingTop: 15,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25 }}>
                    <Text style={styles.bold9}>CIF RO5720754</Text>
                  </div>
                  <Text style={styles.regular9}>RC J1994007234407</Text>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25 }}>
                    <Text style={styles.bold9}>
                      {trip
                        ? `CIF ${trip?.shipper?.tin?.toUpperCase()}`
                        : sortedTrips
                        ? `CIF ${sortedTrips[0]?.shipper?.tin?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                  <div>
                    <Text style={styles.regular9}>
                      {trip
                        ? `RC ${trip?.shipper?.businessRegistrationNumber?.toUpperCase()}`
                        : trips
                        ? `RC ${trips[0]?.shipper?.businessRegistrationNumber?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                paddingLeft: 7,
                paddingTop: 7.5,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25 }}>
                    <Text style={styles.regular9}>
                      Capital soc. 110 000 RON
                    </Text>
                  </div>
                  <Text style={styles.regular9}>
                    BUCURESTI sect. 3 str. PARULUI nr. 32 bl. 59 sc. 2 ap. 82
                  </Text>
                  <Text style={styles.regular9}>Telefon 0213242504</Text>
                  <Text style={styles.regular9}>Email OFFICE@EFE.RO</Text>
                  <Text style={styles.regular9}>
                    Banca ING BANK ROMANIA DRISTOR
                  </Text>
                  <Text style={styles.regular9}>
                    IBAN RO75INGB0000999901646039 RON
                  </Text>
                  <Text style={styles.regular9}>SWIFT CODE: INGBROBU</Text>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25, marginBottom: 5 }}>
                    <Text style={styles.regular9}>
                      {trip
                        ? `${trip?.shipper?.city?.name?.toUpperCase()}, ${trip?.shipper?.address?.toUpperCase()}, jud. ${trip?.shipper?.city?.county?.name?.toUpperCase()}`
                        : sortedTrips
                        ? `${sortedTrips[0]?.shipper?.city?.name?.toUpperCase()}, ${sortedTrips[0]?.shipper?.address?.toUpperCase()}, jud. ${sortedTrips[0]?.shipper?.city?.county?.name?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                  <div>
                    <Text style={styles.regular9}>
                      {trip && trip?.shipper?.bank
                        ? `Banca ${trip?.shipper?.bank?.toUpperCase()}`
                        : trips && trips[0]?.shipper?.bank
                        ? `Banca ${trips[0]?.shipper?.bank?.toUpperCase()}`
                        : "Banca -"}
                    </Text>
                    <Text style={styles.regular9}>
                      {trip && trip?.shipper?.iban
                        ? `IBAN ${trip?.shipper?.iban?.toUpperCase()}`
                        : trips && trips[0]?.shipper?.iban
                        ? `IBAN ${trips[0]?.shipper?.iban?.toUpperCase()}`
                        : "IBAN -"}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                borderBottomColor: "#000000",
                borderBottomWidth: 0.5,
                paddingTop: 10,
                marginBottom: 3,
              }}
            ></div>
            <View style={styles.section}>
              <View style={styles.container} wrap={false}>
                <Text style={styles.smallCell}>Nr. crt.</Text>
                <Text style={styles.largeCell}>Denumire produse/servicii</Text>
                <Text style={styles.cell}>UM</Text>
                <Text style={styles.cell}>Cantitate</Text>
                <Text style={styles.cell}>Pret unitar</Text>
                <Text style={styles.cell}>Valoare</Text>
                <Text style={styles.cell}>TVA (19%)</Text>
              </View>

              <View style={styles.content} wrap={false}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "5%",
                      }}
                    >
                      <Text style={styles.rowCell}>1</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "45%",
                      }}
                    >
                      <Text
                        style={styles.rowCell}
                      >{`C/V TRANSPORT MARFA CONFORM ANEXA`}</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "10%",
                      }}
                    >
                      <Text style={styles.rowCell}>KM</Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "10%",
                      }}
                    >
                      <Text style={styles.rowCell}>
                        {/*{Number(shipperFinalTotalKm).toFixed(3)}*/}
                        {Number(1).toFixed(3)}
                      </Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "10%",
                      }}
                    >
                      <Text style={styles.rowCell}>
                        {Number(shipperFinalTotalValue).toFixed(2)}
                      </Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "10%",
                      }}
                    >
                      <Text style={styles.rowCell}>
                        {shipperFinalTotalValue.toFixed(2)}
                      </Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "10%",
                      }}
                    >
                      <Text style={styles.rowCell}>
                        {Number(shipperFinalTotalVat).toFixed(2)}
                      </Text>
                    </div>
                  </div>
                </div>
              </View>
              <View style={styles.content}>
                <Text style={styles.regular7}>
                  Factura valabila fara semnatura si stampila cf art. V, alin(2)
                  din Ordonanta nr.17/2015 si art 319 alin (29) din Legea
                  227/2015 privind Codul fiscal
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#000000",
                  flexGrow: 1,
                  paddingTop: 3,
                  paddingBottom: 3,
                }}
              >
                <div style={{ width: "25%" }}>
                  <Text style={styles.regular7}>Emis de</Text>
                  <Text style={styles.regular7}>TARUS MARICICA</Text>
                  <Text style={styles.regular7}>CI: RX 391641</Text>
                </div>
                <div style={{ width: "55%", paddingLeft: 10 }}>
                  <Text style={styles.regular7}>Date privind expeditia</Text>
                  <Text style={styles.regular7}>Numele delegatului: POSTA</Text>
                  <Text style={styles.regular7}>
                    {`C.I. seria:         nr:                  eliberat de:`}
                  </Text>
                  <Text style={styles.regular7}>Mijlocul de transport:</Text>
                </div>
                <div style={{ width: "10%" }}>
                  <Text style={styles.bold7}>
                    {shipperFinalTotalValue.toFixed(2)}
                  </Text>
                </div>
                <div style={{ width: "10%" }}>
                  <Text style={styles.bold7}>
                    {shipperFinalTotalVat.toFixed(2)}
                  </Text>
                </div>
              </View>
              <div
                style={{
                  flexDirection: "row",
                  flexGrow: 1,
                }}
              >
                <div style={{ width: "70%" }}></div>
                <div
                  style={{
                    width: "10%",
                    borderColor: "#000000",
                    borderTopWidth: 0.5,
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                ></div>
                <div
                  style={{
                    width: "10%",
                    borderColor: "#000000",
                    borderTopWidth: 0.5,
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                >
                  <Text style={styles.bold7}>TOTAL</Text>
                </div>
                <div
                  style={{
                    width: "10%",
                    borderColor: "#000000",
                    borderTopWidth: 0.5,
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                >
                  <Text style={styles.bold7}>
                    {Number(
                      shipperFinalTotalValue + shipperFinalTotalVat
                    ).toFixed(2)}
                  </Text>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottomColor: "#000000",
                  borderBottomWidth: 0.5,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  borderBottomColor: "#000000",
                  borderBottomWidth: 0.5,
                  marginTop: 3,
                }}
              ></div>
            </View>
          </View>
          <div>
            <Text style={styles.regular7}>
              PREZENTA FACTURA TINE LOC DE CONTRACT. NEPLATA LA TERMEN ATRAGE
              PENALITATI IN CUANTUM DE 0.1% PE ZI
            </Text>
          </div>
        </div>
      </Page>
      <Page size="A4" orientation="portrait" style={appendixStyles.page}>
        <div>
          <View style={appendixStyles.section}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "center",
              }}
            >
              <Text style={appendixStyles.bold9}>LISTA&nbsp;COMENZI</Text>
              <Text style={appendixStyles.bold9}>
                {`Comenzi create intre ${dateFormat(
                  minDateTrip?.date?.toString(),
                  "dd/mm/yyyy"
                )} si ${dateFormat(
                  maxDateTrip?.date?.toString(),
                  "dd/mm/yyyy"
                )}`}
              </Text>
            </div>
          </View>
          <View style={{ borderColor: "#000000", borderWidth: 1 }}>
            <View style={appendixStyles.header}>
              <div style={appendixStyles.headerFlex}>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Nr.{"\n"}comanda
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Data
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Referinta{"\n"}beneficiar
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Ruta{"\n"}beneficiar
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Nr.{"\n"}ansamblu
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Sofer
                </Text>
                <div
                  style={{
                    width: "40%",
                    backgroundColor: "#E5E4E2",
                    borderRightColor: "#000000",
                    borderRightWidth: 0,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Text
                      style={{ fontSize: 8, textAlign: "center", padding: 1 }}
                    >
                      Beneficiar
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#E5E4E2",
                        alignItems: "flex-start",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          // flexGrow: 1,
                          borderTopColor: "#000000",
                          borderTopWidth: 1,
                        }}
                      >
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            padding: 1,
                            // flexGrow: 1,
                            borderRightColor: "#000000",
                            borderRightWidth: 1,
                          }}
                        >
                          Denumire
                        </Text>
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            borderRightColor: "#000000",
                            borderRightWidth: 1,
                            padding: 1,
                            // flexGrow: 1,
                          }}
                        >
                          Total KM
                        </Text>
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            borderRightColor: "#000000",
                            borderRightWidth: 1,
                            padding: 1,
                            // flexGrow: 1,
                          }}
                        >
                          Valoare
                        </Text>
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            padding: 1,
                            // flexGrow: 1,
                          }}
                        >
                          Valoare{"\n"}TVA
                        </Text>
                      </div>
                    </View>
                  </View>
                </div>
              </div>
            </View>
            <View style={{ padding: 1 }}>
              <Text style={styles.bold9}>
                {trip
                  ? `${trip?.shipper?.name?.toUpperCase()}`
                  : sortedTrips
                  ? `${sortedTrips[0]?.shipper?.name?.toUpperCase()}`
                  : "-"}
              </Text>
            </View>
            {sortedTrips &&
              sortedTrips.map((trip) => {
                let appendixShipperValue = 0;
                trip?.shipperInvoiceRows &&
                  trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
                    const currentShipperInvoiceRowValue =
                      Number(shipperInvoiceRow.distanceKm) *
                      Number(shipperInvoiceRow.value);
                    appendixShipperValue += currentShipperInvoiceRowValue;
                    appendixShipperFinalTotalKm += Number(
                      shipperInvoiceRow.distanceKm
                    );
                    appendixShipperFinalTotalValue +=
                      Number(shipperInvoiceRow.distanceKm) *
                      Number(shipperInvoiceRow.value);
                    appendixShipperFinalTotalVat +=
                      (currentShipperInvoiceRowValue * vat) / 100;
                  });

                let shipperReferenceId = "-";
                if (trip.shipperReferenceId) {
                  const letters = trip.shipperReferenceId.split("");
                  letters.splice(12, 0, "\n");

                  shipperReferenceId = letters.join("");
                }

                return (
                  <View style={appendixStyles.row} wrap={false}>
                    <div style={appendixStyles.rowFlex}>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {trip?.id ? `${trip.id}` : "-"}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {trip?.date
                          ? `${dateFormat(trip.date, "dd-mm-yyyy")}`
                          : "-"}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {shipperReferenceId}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {trip?.loadingCity &&
                          `${trip.loadingCity.name?.toUpperCase()} (${trip.loadingCity.county?.name?.toUpperCase()})`}
                        {"\n"}-{"\n"}
                        {trip?.unloadingCity &&
                          `${trip.unloadingCity.name?.toUpperCase()} (${trip.unloadingCity.county?.name?.toUpperCase()})`}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {getCarrierCarTrailerDisplayName(
                          trip.carrierCar,
                          trip.carrierTrailer,
                          true
                        )}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {trip?.carrierDriver
                          ? `${trip.carrierDriver.name?.toUpperCase()}`
                          : "-"}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {trip?.shipper
                          ? `${trip.shipper.name?.toUpperCase()}`
                          : "-"}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {trip?.shipperInvoiceRows &&
                          trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
                            return `${shipperInvoiceRow.distanceKm} km x ${shipperInvoiceRow.value};\n`;
                          })}
                      </Text>
                      {/*<Text*/}
                      {/*  style={{    display: "flex",
                      flexDirection: "row",
                      width:  "10%",
                      borderRightColor: "#000000",
                      borderRightWidth: 1,
                      fontSize: 8,
                      padding: 1,}}*/}
                      {/*>{`${shipperKm} x ${Number(shipperValue).toFixed(2)}`}</Text>*/}
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 1,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {Number(appendixShipperValue).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "10%",
                          borderRightColor: "#000000",
                          borderRightWidth: 0,
                          fontSize: 8,
                          padding: 1,
                        }}
                      >
                        {(appendixShipperValue * vat) / 100}
                      </Text>
                    </div>
                  </View>
                );
              })}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                backgroundColor: "#E5E4E2",
                borderTopColor: "#000000",
                borderTopWidth: 1,
                padding: 1,
              }}
            >
              <Text
                style={{
                  width: "20%",
                  fontSize: 8,
                  textAlign: "left",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                Total comenzi: {sortedTrips?.length}
              </Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              ></Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              ></Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              ></Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              ></Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              ></Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {shipperFinalTotalKm}
              </Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {Number(shipperFinalTotalValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  width: "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {Number(shipperFinalTotalVat).toFixed(2)}
              </Text>
            </View>
          </View>
        </div>
      </Page>
    </Document>
  );

  useEffect(() => {
    if (onBlobSet) {
      pdf(document)
        .toBlob()
        .then((blob) => onBlobSet(blob));
    }
  }, [onBlobSet]);

  return <PDFViewer style={styles.viewer}>{document}</PDFViewer>;
}
